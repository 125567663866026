// @flow
import React, {
  useRef, useState, useEffect, useCallback,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Dropdown,
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import { useTranslation } from 'react-i18next';
import AxiosWrapper from 'helper/axios/AxiosInstance';
import type { InternalGame } from 'typeAliases/backendAliases';
import {
  FaSearch,
} from 'react-icons/fa';
import { QUESTIONNAIRE } from 'routes/RoutesStrings';
import Loader from 'atoms/loader/Loader';
import classes from './NavbarForm.module.css';

const GAME_NAME_INPUT_ID = 'gameNameInput';

type Props = {
  collapsedNavbar: boolean | void
}

export default function NavbarForm(props: Props) {
  const { t } = useTranslation();
  const dropdownMenuRef = useRef();
  const [userInput, setUserInput] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownItems, setDropdownItems] = useState();
  // const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    createDropdownItems();
  }, [userInput]);

  // close dropdown if URL changes
  // useEffect(() => history.listen(() => {
  //   setShowDropdown(false);
  // }), [history]);
  useEffect(() => {
    setShowDropdown(false);
  }, [location]);

  function createDropdownItems() {
    let item;
    const trimmedUserInput = userInput.trim();
    if (trimmedUserInput.length < 3) {
      item = <Dropdown.Item>{t('navbar.form.moreInputNeeded')}</Dropdown.Item>;
      setDropdownItems(item);
    } else {
      item = <Dropdown.Item><Loader loading /></Dropdown.Item>;
      setDropdownItems(item);
      getBackendData(trimmedUserInput);
    }
  }

  function createGameItems(gameData: Array<InternalGame>) {
    const items = [];
    if (gameData.length === 0) {
      items.push(<Dropdown.Header className="gameSearchResult">{t('navbar.form.noGamesFound')}</Dropdown.Header>);
      items.push(
        <Dropdown.Header>
          <Button
            className="gameSearchResult"
            as={Link}
            to={QUESTIONNAIRE}
            variant="outline-success"
          >
            {t('create-review.questionnaire.heading1')}

          </Button>
        </Dropdown.Header>,
      );
    } else {
      items.push(gameData.map((game) => createGameItem(game)));
    }
    setDropdownItems(items);
  }

  function createGameItem(gameData) {
    const newElement = (
      <div key={gameData.slug}>
        <Dropdown.Item
          className="gameSearchResult"
          tabIndex="0"
          as={Link}
          // $FlowIgnore
          to={process.env.REACT_APP_REACT_URL_GAMES + gameData.slug}
          onBlur={async (e) => {
            onBlurElements(e);
          }}
        >
          <Row>
            <Col xs="12" md="5">
              <img
                className={classes.gameImage}
                src={gameData.image_set[0].image_url}
                alt={gameData.name}
              />
            </Col>
            <Col xs="12" md="7">
              <div className={classes.gameTextContainer}>
                <p className={classes.gameText}>{gameData.name}</p>
              </div>
            </Col>
          </Row>
        </Dropdown.Item>
        <hr className={classes.hline} />
      </div>
    );
    return newElement;
  }

  async function getBackendData(newUserInput) {
    AxiosWrapper.getAxiosInstance()
    // $FlowIgnore
      .get(`${process.env.REACT_APP_URL_GAMES}?${process.env.REACT_APP_SEARCH_OPTION}${newUserInput}&${process.env.REACT_APP_PAGE_PARAM}1`)
      .then(async (response) => {
        createGameItems(response.data.results);
      })
      .catch(() => {
        const item = <Dropdown.Item className="gameSearchResult">{t('navbar.form.errorLoading')}</Dropdown.Item>;
        setDropdownItems(item);
      });
  }

  function onBlurElements(e) {
    if (!(e.relatedTarget !== null && (e.relatedTarget.classList.contains('gameSearchResult')
            // eslint-disable-next-line no-empty
            || e.relatedTarget.id === GAME_NAME_INPUT_ID))
            && (e.relatedTarget === null && e.target.id === GAME_NAME_INPUT_ID)) {
      // Do nothing
      setShowDropdown(false);
    }
  }

  const handleGameSearchSubmit = useCallback(
    (e) => {
      e.preventDefault();
      // ToDo: send to advanced search with url search parameter
    },
    [],
  );

  return (

    <Dropdown
      show={showDropdown}
      autoClose
    >
      <Form
        role="search"
        inline="true"
        className={props.collapsedNavbar ? '' : classes.navbarFormCollapsed}
        onSubmit={handleGameSearchSubmit}
      >
        <InputGroup>
          <FormControl
            id={GAME_NAME_INPUT_ID}
            autoFocus
            type="text"
            aria-label={t('navbar.form.placeholder')}
            aria-describedby="submitButton"
            placeholder={t('navbar.form.placeholder')}
            className={`mr-sm-2 ${classes.gameNameInput} ${
              props.collapsedNavbar ? '' : classes.collapsedFormInput
            }`}
            onBlur={async (e) => {
              // in order to make the onClick method from the "create review" button happen first
              setTimeout(() => { onBlurElements(e); },
                120);
            }}
            onChange={(e) => {
              e.preventDefault();
              createDropdownItems();
              setUserInput(e.target.value);
              setShowDropdown(true);
            }}
          />
          <Button
            onSubmit={(e) => {
              e.preventDefault();
              createDropdownItems();
              // ToDo: redirect to advanced search
            }}
            type="submit"
            variant="outline-secondary"
            id="submitButton"
            aria-label={t('navbar.form.submitBtnAria')}
          >
            <FaSearch
              aria-label={t('navbar.form.submitBtnAria')}
            />
          </Button>
        </InputGroup>
        <Dropdown.Menu
          renderOnMount
          ref={dropdownMenuRef}
          className={props.collapsedNavbar ? classes.collabsedDropdownMenu : classes.dropdownMenu}
        >
          {dropdownItems}
        </Dropdown.Menu>
      </Form>
    </Dropdown>
  );
}
