// @flow
import Heading3 from 'atoms/heading3/Heading3';
import ImageReference from 'atoms/imageReference/ImageReference';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Image } from 'typeAliases/backendAliases';
import classes from './ImageReferencesSection.module.css';

type Props = {
    imageSets: Array<Image> | void
}

export default function ImageReferencesSection(props: Props) {
  const { imageSets } = props;
  const { t } = useTranslation();

  function createReferences() {
    if (!imageSets) return null;

    return (
      <ol className={classes.referencesList}>
        {imageSets.map((imageSet, index) => (
          <li key={`image-reference-${index + 1}`} id={`image-reference-${index + 1}`}>
            <ImageReference
              gameName={imageSet.game_name}
              developer={imageSet.developer}
              publisher={imageSet.publisher}
              source_url={imageSet.source_url}
              image_type={imageSet.image_type}
            />
          </li>
        ))}
      </ol>
    );
  }

  return (
    <>
      <Heading3 heading={t('referencesSection.heading')} />
      {createReferences()}
    </>
  );
}
