// @flow
import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';

type Props = {
  cardSelected: boolean,
    gameListId: number,
    gameName: string,
    release: string,
    platformNames: string,
    handleChoseGameFromList(number): void,
    showButton: boolean,
}

export default function SmallGameCardPresn(props: Props) {
  const { t } = useTranslation();

  return (
    <Col>
      <Card
        className="text-center"
        style={{ width: '18rem' }}
        border={props.cardSelected ? 'success' : null}
      >
        {/* add class "h-100" to the card to make it same height */}
        <Card.Body>
          <Card.Title>
            {props.gameName}
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {t('gameCards.smallGameCards.releaseLabel')}
            :
            {' '}
            {props.release}
          </Card.Subtitle>
          <Card.Text>
            {props.platformNames.split(',').length === 1
              ? t('gameCards.smallGameCards.platformLabel')
              : t('gameCards.smallGameCards.platformsLabel')}
            :
            {' '}
            {props.platformNames}
          </Card.Text>
          {props.showButton === true
            ? (
              <Button
                size="lg"
                onClick={() => {
                  props.handleChoseGameFromList(props.gameListId);
                }}
                variant={props.cardSelected ? 'success' : 'outline-primary'}
                aria-label={`${t('gameCards.smallGameCards.selectGameBtnARIALabel')} ${props.gameName}`}
              >
                {props.cardSelected ? t('gameCards.smallGameCards.selectGameBtnLabelSelected') : t('gameCards.smallGameCards.selectGameBtnLabel')}
              </Button>
            )
            : undefined}
        </Card.Body>
        <Card.Footer>
          <small className="text-muted" />
        </Card.Footer>
      </Card>
    </Col>
  );
}
