// @flow
import Heading1 from 'atoms/heading1/Heading1';
import { BASE_URL } from 'helper/axios/AxiosInstance';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Link, useLocation } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { HOME_ROUTE } from 'routes/RoutesStrings';
import classes from './NotFound.module.css';

const PAGE_NOT_FOUND_IMG = 'page_not_found.svg';

function NotFound() {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <>
      <Container>
        <Row>
          <Col xs={12} md={6}>
            {(!location.state || !location.state.gameNotFound) && <Heading1 heading={t('errorRoute.NotFoundHeading')} />}
            {(location.state && location.state.gameNotFound) && <Heading1 heading={t('errorRoute.GameNotFoundHeading')} />}
            <p>
              {t('errorRoute.explanation')}
              {' '}
              <Link
                to={HOME_ROUTE}
                href="/"
              >
                {t('errorRoute.homeBtnLbl')}
              </Link>
              .
            </p>
          </Col>
          <Col xs={12} md={6}>
            <img
            // $FlowIgnore
              src={BASE_URL + process.env.REACT_APP_URL_STATIC_IMAGES + PAGE_NOT_FOUND_IMG}
              alt={t('home.heroImageAlt')}
              className={classes.image}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default NotFound;
