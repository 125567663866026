// @flow
import Heading2 from 'atoms/heading2/Heading2';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import React, { useContext, useState, useEffect } from 'react';
import MetricModal from 'atoms/metricModal/MetricModal';
import globalClasses from 'globalCSS/global.module.css';
import type {
  MetricAnswers, CategoryScores, Metric,
  FreeTextAnswers,
} from 'typeAliases/backendAliases';
import type { Node } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaExchangeAlt, FaArrowRight, FaCheck, FaTimes, FaMinus, FaBan,
} from 'react-icons/fa';
import {
  IoAccessibilityOutline,
  IoHandLeftOutline,
  IoEyeOutline,
  IoChatbubblesOutline,
  IoEarOutline,
  IoPersonOutline,
} from 'react-icons/io5';
import { MetricContext } from 'contexts/MetricContext';
import type { MetricContextImplements } from 'contexts/MetricContext';
import { getSortedDisabilityTypeIDs } from 'helper/disability_types/disability_types';
import ScoreDisplay from 'atoms/scoreDisplay/ScoreDisplay';
import FreeTextAnswerModal from 'atoms/freeTextAnswer/FreeTextAnswerModal';
import classes from './MetricDisplay.module.css';

type Props = {
  answers: MetricAnswers | null,
  freeTextAnswers: FreeTextAnswers,
  // showScores: boolean,
  changeRequestAnswers: MetricAnswers | null,
  changeRequestFreeAnswers: FreeTextAnswers | null,
  platformTypeID: number,
  categoryScores: CategoryScores | void,
  filterUnvaluedGeneralMetrics?: boolean,
  gameName: string,
  blockStaleAnswersAlert?: boolean,
}

const TYPE_GENERAL = 'general';
const TYPE_MOTOR = 'motor';
const TYPE_VISION = 'vision';
const TYPE_COGNITIVE = 'cognitive';
const TYPE_HEARING = 'hearing';
const TYPE_SPEECH = 'speech';

function MetricDisplay(props: Props) {
  const { t } = useTranslation();
  const businessData = useContext<$PropertyType<MetricContextImplements, 'businessData'>>(MetricContext);
  const [staleAnswers, setStaleAnswers] = useState(false);
  const [categoryElements, setCategoryElements] = useState();

  useEffect(() => {
    if (businessData !== undefined) {
      setCategoryElements(createCategories());
    }
  }, [businessData]);

  function getTypeIcons(disabilityType: string) {
    if (disabilityType === TYPE_GENERAL) {
      return <IoAccessibilityOutline />;
    }
    if (disabilityType === TYPE_MOTOR) {
      return <IoHandLeftOutline />;
    }
    if (disabilityType === TYPE_VISION) {
      return <IoEyeOutline />;
    }
    if (disabilityType === TYPE_COGNITIVE) {
      return <IoPersonOutline />;
    }
    if (disabilityType === TYPE_HEARING) {
      return <IoEarOutline />;
    }
    if (disabilityType === TYPE_SPEECH) {
      return <IoChatbubblesOutline />;
    }
    return <IoAccessibilityOutline />;
  }

  function filterUnvaluedGeneralMetrics(metrics) {
    const TYPE_GENERAL_ID = parseInt(process.env.REACT_APP_TYPE_GENEREAL_ID, 10);
    const UNVALUED_DIFF_ID = parseInt(process.env.REACT_APP_DIFFICULTY_UNVALUED_ID, 10);
    const newMetrics = JSON.parse(JSON.stringify(metrics));
    const generalMetrics = metrics[TYPE_GENERAL_ID];
    const filteredGeneralMetrics = generalMetrics.filter(
      (metric) => metric.metric_difficulty !== UNVALUED_DIFF_ID,
    );
    newMetrics[TYPE_GENERAL_ID] = filteredGeneralMetrics;
    return newMetrics;
  }

  function createCategories(): Array<Node> | null {
    const unfilteredMetrics = businessData.metricsByPlatform[props.platformTypeID];
    let metricsByDisabilty;
    if (props.filterUnvaluedGeneralMetrics) {
      metricsByDisabilty = filterUnvaluedGeneralMetrics(unfilteredMetrics);
    } else {
      metricsByDisabilty = unfilteredMetrics;
    }
    const disabilityIDs = getSortedDisabilityTypeIDs(businessData.disability_types);
    if (disabilityIDs.length === 0) return null;
    return disabilityIDs.map((disabilityID, index) => {
      const metrics = metricsByDisabilty[parseInt(disabilityID, 10)];
      metrics.sort((a, b) => a.sort_id - b.sort_id);
      const halfLength = Math.ceil(metrics.length / 2);
      const firstHalf = metrics.slice(0, halfLength);
      const secondHalf = metrics.slice(halfLength);
      let categoryScore = null;
      if (props.categoryScores) {
        categoryScore = props.categoryScores[
          businessData.disability_types[disabilityID].internal_name];
      }
      return (
        <div key={disabilityID}>
          {index > 0 ? <hr className={classes.hrLine} /> : null}
          <div className={classes.heading}>
            <Heading2
              heading={(
                <>
                  <div className={classes.icon}>
                    {getTypeIcons(
                      businessData.disability_types[parseInt(disabilityID, 10)].internal_name,
                    )}
                  </div>
                  <>
                    {businessData.disability_types[parseInt(disabilityID, 10)]
                      .disability_type_i18n_set.name}
                  </>
                </>
              )}
              score={(
                <ScoreDisplay
                  totalScore={categoryScore}
                  scale={1}
                  showTitle={false}
                  marginTop="0em"
                />
              )}
            />
          </div>
          <Row className={classes.rowMarginTop}>
            <Col md="12" lg="6">
              <ul
                className={
                  `${globalClasses.noListStyle} ${classes.localListStyle}`
                }
              >
                {firstHalf.length > 0 ? createMetricEntries(firstHalf) : null}
              </ul>
            </Col>
            <Col md="12" lg="6">
              <ul
                className={
                  `${globalClasses.noListStyle} ${classes.localListStyle}`
                }
              >
                {secondHalf.length > 0 ? createMetricEntries(secondHalf) : null}
              </ul>
            </Col>
          </Row>
        </div>
      );
    });
  }

  function ignoreMetric(metricAnswer, metricChangeReqAnswer, freeAnswer): boolean {
    if (metricAnswer === null && metricChangeReqAnswer === null && freeAnswer === undefined) {
      return true;
    }
    return false;
  }

  function createAnswerString(answers: string | null,
    changeRequestAnswers: string | null): string | Node {
    let answerString;
    if (changeRequestAnswers) {
      let modAnswers = answers;
      if (answers === null) modAnswers = t('metricDisplay.noAnswer');
      answerString = (
        <>
          {modAnswers}
          {' '}
          <FaArrowRight />
          {' '}
          {changeRequestAnswers}
        </>
      );
    } else {
      answerString = answers;
    }
    return answerString;
  }

  function getTotalAchievedScore(selectedMetricAnswers, businessDataInst, metric): number | void {
    if (selectedMetricAnswers && selectedMetricAnswers.length > 0) {
      let totalAchievedScore = 0;
      let fittingAnswerFound = false;
      selectedMetricAnswers.forEach((givenAnswer) => {
        businessDataInst.answer_sets[metric.answer_type].answers.every((possibleAnswer) => {
          if (possibleAnswer.id === givenAnswer) {
            fittingAnswerFound = true;
            if (!possibleAnswer.scored) {
              totalAchievedScore = -1;
              return false; // break the every loop
            }
            totalAchievedScore += possibleAnswer.score_fraction;
          }
          return true;
        });
      });
      if (!fittingAnswerFound) totalAchievedScore = -2;
      return totalAchievedScore;
    }
    return undefined;
  }

  function isNumberBiggerAndSmallerThen(nrToTest, upper, bottom) {
    if (!nrToTest) return false;
    return nrToTest > bottom && nrToTest < upper;
  }

  function createMetricEntries(categoryMetrics) {
    return categoryMetrics.map((metric: Metric) => {
      const metricIDStr = metric.id.toString();
      const selectedMetricAnswers = (props.answers
          && props.answers[metricIDStr]
          && props.answers[metricIDStr])
        ? props.answers[metricIDStr] : null;
      const changeRequestAnswerIDs = (props.changeRequestAnswers
        && props.changeRequestAnswers[metricIDStr]
        && props.changeRequestAnswers[metricIDStr])
        ? props.changeRequestAnswers[metricIDStr] : null;
      const answerString = getAnswerStrings(
        metric, selectedMetricAnswers, businessData, t, setStaleAnswers,
      );
      const achievedScore = getTotalAchievedScore(selectedMetricAnswers, businessData, metric);
      const chaneRequestAnswerString = getAnswerStrings(
        metric, changeRequestAnswerIDs, businessData, t, setStaleAnswers,
      );
      const oldfreeAnswerString = props.freeTextAnswers[metric.id.toString()];
      const changeReqFreeAnswerString = props.changeRequestFreeAnswers
        ? props.changeRequestFreeAnswers[metric.id.toString()] : null;
      const freeAnswerChanged = changeReqFreeAnswerString !== undefined
      && changeReqFreeAnswerString !== null;
      const freeAnswerString = changeReqFreeAnswerString ?? oldfreeAnswerString;
      const answerElements = createAnswerString(answerString, chaneRequestAnswerString);
      const showChangedIcon = chaneRequestAnswerString ?? changeReqFreeAnswerString;
      if (ignoreMetric(answerString, chaneRequestAnswerString, freeAnswerString)) return null;
      return (
        <div key={metric.id}>
          {selectedMetricAnswers !== undefined ? (
            <li>
              <Row>
                <div className={`${classes.answerDiv}`}>
                  <Col xs="7" md="7">
                    <div className={classes.metricModalDiv}>
                      <MetricModal
                        greyedOut={false}
                        paragraphText={metric.metric_i18n_set.name}
                        metricDescription={
                        metric.metric_i18n_set.description
                      }
                        topMargin={classes.metricEntry}
                        explanationList={metric.metric_elaboration_set}
                        gameName={props.gameName}
                      />
                    </div>
                  </Col>
                  <Col xs="5" xl="4">
                    <div className={`${classes.metricEntry} ${chaneRequestAnswerString ? classes.markAnswer : ''}`}>
                      {showChangedIcon && (
                        <Badge className={classes.badgeMarginRight} bg="success">
                          {' '}
                          <FaExchangeAlt />
                          {' '}
                        </Badge>
                      )}
                      <div className={classes.answerDiv}>
                        <div className={classes.answerIconDiv}>
                          {achievedScore === -2
                            && <FaBan className={globalClasses.greyIcon} />}

                          {achievedScore === -1
                            && <FaBan className={globalClasses.greyIcon} />}

                          {achievedScore === 0
                            && <FaTimes className={globalClasses.redIcon} />}

                          {achievedScore === 1
                            && <FaCheck className={globalClasses.greenIcon} />}

                          {isNumberBiggerAndSmallerThen(achievedScore, 1, 0)
                            && <FaMinus className={globalClasses.yellowIcon} />}
                        </div>
                        <div className={classes.answerStringDiv}>
                          {answerElements}
                        </div>
                        {freeAnswerString
                        && (
                        <div className={classes.answerTextDiv}>
                          <FreeTextAnswerModal
                            changed={freeAnswerChanged}
                            freeText={freeAnswerString}
                          />
                        </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </div>
              </Row>
            </li>
          ) : null}
        </div>
      );
    });
  }

  return (
    <>
      {categoryElements}
      <br />
      {(staleAnswers && !props.blockStaleAnswersAlert)
      && (
      <Alert variant="primary">
        <Alert.Heading>{t('gamePage.staleAnswerAlertHeading')}</Alert.Heading>
        <hr />
        <p>
          {t('gamePage.staleAnswerAlertContent')}
        </p>
      </Alert>
      )}
    </>
  );
}

export function getAnswerStrings(
  metric: Metric,
  selectedMetricAnswers: Array<number> | null,
  businessData,
  t,
  setStaleAnswers?: (boolean) => void,
): string | null {
  if (selectedMetricAnswers === null) return null;
  if (selectedMetricAnswers.length === 0) return t('metricDisplay.noAnswer');
  let validAnswerFoundForMetric = false;
  let answerString = businessData.answer_sets[metric.answer_type].answers.map((answer) => {
    if (selectedMetricAnswers.indexOf(answer.id) !== -1) {
      validAnswerFoundForMetric = true;
      return answer.answer_i18n_set.answer_text;
    }
    return undefined;
  })
    .filter((notUndefined) => notUndefined !== undefined)
    .join(', ');
  if (!validAnswerFoundForMetric) {
    if (setStaleAnswers) setStaleAnswers(true);
    answerString = t('metricDisplay.staleAnswer');
  }
  return answerString;
}

MetricDisplay.defaultProps = {
  filterUnvaluedGeneralMetrics: false,
  blockStaleAnswersAlert: false,
};

export default MetricDisplay;
