// @flow
import React from 'react';
import type { Image as ImageType } from 'typeAliases/backendAliases';
import Figure from 'react-bootstrap/Figure';
import ImageReference from 'atoms/imageReference/ImageReference';
import classes from './Image.module.css';

type Props = {
    imageData: ImageType | void,
}

function Image(props: Props) {
  function createImage() {
    return props.imageData
      ? (
        <Figure.Image
          className={classes.explanationImage}
          src={props.imageData.image_url}
          alt={props.imageData.game_name}
          thumbnail
        />
      )
      : null;
  }

  function createCaption() {
    if (!props.imageData) return null;

    const imageReference = (
      <ImageReference
        gameName={props.imageData.game_name}
        publisher={props.imageData.publisher}
        developer={props.imageData.developer}
        source_url={props.imageData.source_url}
        image_type={props.imageData.image_type}
        font_size="1.0em"
      />
    );
    return (
      <div className={classes.figureCaptionDiv}>
        {imageReference}
      </div>
    );
  }

  return (
    <Figure className={classes.image}>
      {createImage()}
      {createCaption()}
    </Figure>
  );
}

export default Image;
