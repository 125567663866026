export const FEEDBACK_ROUTE = '/feedback';
export const HOME_ROUTE = '/';
export const CREATE_REVIEW = '/create-review';
export const GAME = 'game';
export const PLATFORM = 'platform';
export const CHANGE_REQUEST = `${CREATE_REVIEW}/${GAME}/:gameName/${PLATFORM}/:platformName`;
export const QUESTIONNAIRE = `${CREATE_REVIEW}/questionnaire`;
export const REVIEW_STATUS = '/review-status';
export const AUTHENTICATE_REVIEW = '/authenticate';
export const GAMES_ROOT = '/games';
export const GAMES = '/games/:gameName';
export const YOUR_REVIEW = '/your-review';
export const NOT_FOUND = '/page-not-found';
export const SEARCH = '/search/';

export const IMPRESSUM = '/impressum/';
export const PRIVACY_POLICY = '/privacy-policy/';
export const DATENSCHUTZERKLÄRUNG = '/datenschutzerklaerung/';
