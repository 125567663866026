import './App.css';
import {
  BrowserRouter, Routes, Route,
} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import React, { Suspense } from 'react';
import ThemeComponent from 'theme/ThemeComponent';
import MetricProviderComp from 'contexts/MetricContext';
import Footer from 'components/footer/Footer';
import * as ackeeTracker from 'ackee-tracker';
import { BASE_URL } from 'helper/axios/AxiosInstance';
// import Routes from './routes/Routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'theme/bootstrapTheme.scss';
import {
  FEEDBACK_ROUTE,
  HOME_ROUTE,
  QUESTIONNAIRE,
  REVIEW_STATUS,
  AUTHENTICATE_REVIEW,
  CHANGE_REQUEST,
  GAMES,
  YOUR_REVIEW,
  IMPRESSUM,
  PRIVACY_POLICY,
  DATENSCHUTZERKLÄRUNG,
  SEARCH,
} from 'routes/RoutesStrings';

import Feedback from 'routes/feedback/Feedback';
import Home from 'routes/home/Home';
import Questionnaire from 'routes/create-review/questionnaire/Questionnaire';
import ReviewStatus from 'routes/reviewStatus/ReviewStatus';
import AuthenticateReview from 'routes/authenticateReview/AuthenticateReview';
import UpdateReview from 'routes/create-review/updateReview/UpdateReview';
import Game from 'routes/game/Game';
import YourReview from 'routes/yourReview/YourReview';
import Impressum from 'routes/impressum/Impressum';
import PrivacyPolicy from 'routes/privacyPolicy/PrivacyPolicy';
import NotFound from 'routes/errorRoute/NotFound';
import Search from 'routes/search/Search';
import Ackee from 'ackee/Ackee';
import SiteNavbar from './components/siteNavbar/SiteNavbar';

const ACKEE_INSTANCE = ackeeTracker.create(`${BASE_URL}${process.env.REACT_APP_ACKEE_SUBDOMAIN}`);

function App() {
  // const location = useLocation();
  // Send Ackee event when user Loads page
  // useEffect(() => {
  // console.log('record url');
  // const ackeeAttributes = ackeeTracker.attributes(false);
  // const { stop } = ACKEE_INSTANCE
  //   .record(process.env.REACT_APP_ACKEE_DOMAIN_ID, ackeeAttributes);
  // return () => {
  //   stop();
  // };
  // }, [ackeeTracker]);

  return (
    <Suspense fallback="...is loading">
      <ThemeComponent value="light">
        <MetricProviderComp>
          <BrowserRouter>
            <Ackee ackeeInstance={ACKEE_INSTANCE} />
            <div className="minHeightScreen">
              <SiteNavbar />
              <Container>
                <Routes>
                  <Route path={HOME_ROUTE} exact element={<Home />} />
                  <Route path={FEEDBACK_ROUTE} element={<Feedback />} />
                  <Route path={GAMES} element={<Game />} key="games" />
                  <Route path={CHANGE_REQUEST} element={<UpdateReview />} />
                  <Route path={QUESTIONNAIRE} element={<Questionnaire />} />
                  <Route path={REVIEW_STATUS} element={<ReviewStatus />} />
                  <Route path={AUTHENTICATE_REVIEW} element={<AuthenticateReview />} />
                  <Route path={YOUR_REVIEW} element={<YourReview />} />
                  <Route path={IMPRESSUM} element={<Impressum />} />
                  <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
                  <Route path={DATENSCHUTZERKLÄRUNG} element={<PrivacyPolicy />} />
                  <Route path={SEARCH} element={<Search />} key="ordering" />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Container>
            </div>
            <Footer />
          </BrowserRouter>
        </MetricProviderComp>
      </ThemeComponent>
    </Suspense>
  );
}

export default App;
