// @flow
import React from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import type { PlatformFormImplements } from './PlatformForm';

const SELECT_PLATFORM_LABEL = 'create-review.questionnaire.chooseGame.selectPlatformLabel';
const PLACEHOLDER_DEFAULT_SELECT = 'create-review.questionnaire.chooseGame.platformDefaultSelect';

type Props = {
    selectDisabled: $PropertyType<PlatformFormImplements, 'selectDisabled'>,
    createPlatformOptions: $PropertyType<PlatformFormImplements, 'createPlatformOptions'>,
    handleSelect: $PropertyType<PlatformFormImplements, 'handleSelect'>,
    selectRef: $PropertyType<PlatformFormImplements, 'selectRef'>,
  }

export default function PlatformFormPresn(props: Props) {
  const { t } = useTranslation();

  return (
    <Form.Group className="mb-3" controlId="formPlatformSelect">
      <Form.Label>{t(SELECT_PLATFORM_LABEL)}</Form.Label>
      <Form.Select
      // $FlowIgnore
        ref={props.selectRef}
        size="lg"
        defaultValue="-1"
        disabled={props.selectDisabled}
        onChange={props.handleSelect}
      >
        <option
          value="-1"
          hidden
          disabled
        >
          {t(PLACEHOLDER_DEFAULT_SELECT)}
        </option>
        {props.createPlatformOptions()}
      </Form.Select>
    </Form.Group>
  );
}
