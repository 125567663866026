// @flow
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import classes from './SearchPhraseForm.module.css';

type Props = {
    setSearchPhrase(newPhrase: string): void,
    getGames(searchPhrase: string, curPage: number): void,
    setCurPage(page: number): void,
}

export default function SearchPhraseForm(props: Props) {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [unsubmittedPhrase, setUnsubmittedPhrase] = useState<string>('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      props.setSearchPhrase(unsubmittedPhrase);
      props.setCurPage(1);
      props.getGames(unsubmittedPhrase, 1);
      setValidated(false);
    }
  };

  return (
    <Form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
      noValidate
      validated={validated}
    >
      <Row className={classes.searchFormRow}>
        <Col
          xs="12"
          md="5"
          className={`${classes.searchInputCol}`}
        >
          <Form.Group className="mb-3">
            <Form.Label>{t('search.searchPhraseLbl')}</Form.Label>
            <Form.Control
              className={classes.searchTextInput}
              size="lg"
              type="text"
              minLength="3"
              required
              placeholder={t('search.searchPhrasePlaceholder')}
              value={unsubmittedPhrase}
              onKeyPress={() => 'event.keyCode!=13'} // prevent enter to reload page in safari
              onKeyUp={(e) => {
                e.preventDefault();
                setUnsubmittedPhrase(e.target.value);
                if (e.key === 'Enter') {
                  props.setSearchPhrase(e.target.value);
                  props.setCurPage(1);
                  props.getGames(e.target.value, 1);
                }
              }}
              onChange={(e) => {
                e.preventDefault();
                setUnsubmittedPhrase(e.target.value);
              }}
            />
            <Form.Control.Feedback
              type="invalid"
              className={classes.invalidInputFeedback}
            >
              {t('search.searchPhraseToShort')}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs="12" md="1" className={`${classes.searchButtonCol}`}>
          <Button
            className={classes.searchButton}
            size="lg"
            type="submit"
          >
            {t('search.searchBtnLabel')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
