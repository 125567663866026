// @flow
import React, {
  useEffect, useRef, useState, useCallback, useContext,
} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import { useTranslation } from 'react-i18next';
import type { AnswerMetricImplements } from 'components/AnswerMetrics/AnswerMetrics';
import type { ReviewData, Metric } from 'typeAliases/backendAliases';
import { MetricContext } from 'contexts/MetricContext';
import type { MetricContextImplements } from 'contexts/MetricContext';
import { getSortedDisabilityTypeIDs } from 'helper/disability_types/disability_types';
import classes from './MetricNavBar.module.css';
import NavBarSubItem from './NavBarSubItem';

const ID = 'id';
const HTML_ATTRIBUTE_METRIC_ID = 'metricid';

type Props = {
  initialRevision: $PropertyType<ReviewData, 'initial_revision'>,
  metricAnsers: $PropertyType<AnswerMetricImplements, 'metricAnswers'>,
  reviewAccrdionItemEventKey: string,
  notApplicableMetricIDs: $PropertyType<AnswerMetricImplements, 'notApplicableMetricIDs'>,
  metricsByCategory: $PropertyType<AnswerMetricImplements, 'metricsByCategory'>,
  changeMetricTab: $PropertyType<AnswerMetricImplements, 'changeMetricTab'>,
  consumeChangeMetricTab: $PropertyType<AnswerMetricImplements, 'consumeChangeMetricTab'>,
  freeTextAnswers: $PropertyType<AnswerMetricImplements, 'freeTextAnswers'>,
}

export default function MetricNavBar(props: Props) {
  const accordionRef = useRef<HTMLElement | null>(null);
  const [initialExpantionDone, setInitialExpantionDone] = useState(false);
  const { t } = useTranslation();
  const metricContext = useContext<$PropertyType<MetricContextImplements, 'businessData'>>(MetricContext);

  // as soon as the first item was added to the DOM open the first entry
  const firstAccordionItemRef = useCallback((node) => {
    if (node !== null && initialExpantionDone === false) {
      setInitialExpantionDone(true);
      if (accordionRef.current === null) { // when comming back from review page this is needed
        accordionRef.current = node.parentElement.parentElement.parentElement;
      }
      const selectedItem = openAccorionAtIndexReturnListItem(accordionRef, 0);
      if (selectedItem) {
        selectedItem.click();
      }
    }
  }, [initialExpantionDone]);

  function selectListEntry(currSelectedElem: HTMLElement | void,
    forward = true): HTMLElement | void {
    if (doesSilbingExist(currSelectedElem, forward)) {
      return getSibling(currSelectedElem, forward);
    }
    if (currSelectedElem === undefined) return undefined;
    const currSelAccror = (((
      currSelectedElem.parentElement?.parentElement?.parentElement?.parentElement)));
    // $FlowIgnore
    if (doesSilbingExist(currSelAccror, forward)) {
      const accordionGroup = (currSelAccror?.parentElement);
      // $FlowIgnore
      const selectedIndex = Array.prototype.indexOf.call(
        (accordionGroup?.children),
        currSelAccror,
      );
      const toBeSelectedIndex = forward ? selectedIndex + 1 : selectedIndex - 1;
      return openAccorionAtIndexReturnListItem(accordionRef, toBeSelectedIndex, forward);
    }
    return undefined;
  }

  const selectUntilApplicableMetric = useCallback(
    (forward = true, changeMetricTab) => {
      (async () => {
        if (changeMetricTab === null) return;
        let currentlySelectedMetricID;
        let newlySelectedAccordionListItem = getActiveListElement();
        do {
          newlySelectedAccordionListItem = selectListEntry(newlySelectedAccordionListItem, forward);
          if (newlySelectedAccordionListItem) {
            currentlySelectedMetricID = newlySelectedAccordionListItem.getAttribute(
              HTML_ATTRIBUTE_METRIC_ID,
            );
          } else {
            currentlySelectedMetricID = null;
          }
        } while (
          currentlySelectedMetricID !== null
          && props.notApplicableMetricIDs.has(parseInt(currentlySelectedMetricID, 10))
        );
        if (newlySelectedAccordionListItem) {
          newlySelectedAccordionListItem.click();
        }
        props.consumeChangeMetricTab();
      })();
    },
    [selectListEntry, props.notApplicableMetricIDs],
  );

  useEffect(() => {
    if (props.changeMetricTab !== null && props.changeMetricTab.next !== undefined) {
      if (props.changeMetricTab.next === true) {
        selectUntilApplicableMetric(true, props.changeMetricTab);
      } else {
        selectUntilApplicableMetric(false, props.changeMetricTab);
      }
    }
  }, [props.changeMetricTab,
    selectUntilApplicableMetric]);

  function openAccorionAtIndexReturnListItem(accordionReference,
    index, forward = true): HTMLElement | void {
    if (accordionReference.current?.children === undefined) return undefined;
    const accordionItem = (accordionReference.current.children)[index];
    accordionItem.getElementsByTagName('h2')[0].children[0].click();
    const metricListLength = accordionItem.getElementsByTagName('div')[0].children[0].children[0]
      .children.length;
    const listEntryIndex = forward ? 0 : metricListLength - 1;
    return accordionItem
      .getElementsByTagName('div')[0]
      .children[0].children[0].children[listEntryIndex];
  }

  function doesSilbingExist(activeElement: HTMLElement | void, forward = true) {
    if (!activeElement) return false;
    const doesExist = activeElement
      && ((forward && activeElement.nextElementSibling !== null)
        || (!forward && activeElement.previousElementSibling !== null));
    return doesExist;
  }

  function getSibling(currElem: HTMLElement | void, forward = true): HTMLElement | void {
    if (!currElem) return undefined;
    if (forward && currElem.nextElementSibling !== undefined) {
      // $FlowIgnore
      return currElem.nextElementSibling;
    }
    // $FlowIgnore
    return currElem.previousElementSibling;
  }

  function getActiveListElement(): HTMLElement | void {
    return document.getElementsByClassName('list-group-item active')[0];
  }

  function createAccordionItems() {
    if (metricContext === undefined) return undefined;
    const { metricsByCategory } = props;
    const disabilityIDs = getSortedDisabilityTypeIDs(metricContext.disability_types);
    if (disabilityIDs.length !== 0) {
      const navBarHTML = disabilityIDs.map(
        (disabiltyID, index) => {
          if (Object.keys(metricsByCategory).length === 0) return null;
          const metricList: Array<Metric> = metricsByCategory[parseInt(disabiltyID, 10)];
          metricList.sort((a, b) => a.sort_id - b.sort_id);
          const listItems = createListItems(metricList);
          const firstItem = index === 0;
          const disabilityName = metricContext.disability_types[parseInt(disabiltyID, 10)]
            .disability_type_i18n_set.name;
          return createAcordionItem(
            disabilityName,
            index,
            disabilityName,
            listItems,
            firstItem,
          );
        },
      );

      const acordionLength = navBarHTML.length;
      const reviewItem = createReviewAccordionItem(acordionLength);
      navBarHTML.push(reviewItem);
      return navBarHTML;
    }
    return null;
  }

  function createAcordionItem(
    key,
    eventkey,
    header,
    bodyListItems,
    firstItem = false,
  ) {
    return (
      <Accordion.Item key={key} eventKey={eventkey}>
        <Accordion.Header>{header}</Accordion.Header>
        <Accordion.Body
          ref={firstItem ? firstAccordionItemRef : null}
          className={classes.noPadding}
        >
          <ListGroup>{bodyListItems}</ListGroup>
        </Accordion.Body>
      </Accordion.Item>
    );
  }

  function createReviewAccordionItem(currentAccoridonLength) {
    const header = t(
      'create-review.questionnaire.answerMetrics.AccordionItemReview',
    );
    const listGroupItem = (
      <ListGroup.Item
        variant="default"
        key="0"
        action
        eventKey={props.reviewAccrdionItemEventKey}
      >
        {t(
          'create-review.questionnaire.answerMetrics.AccordionItemReviewListItem',
        )}
      </ListGroup.Item>
    );
    return createAcordionItem(
      currentAccoridonLength,
      props.reviewAccrdionItemEventKey,
      header,
      listGroupItem,
      false,
    );
  }

  function createListItems(metricList: Array<Metric>) {
    return metricList.map((metric) => (
      <NavBarSubItem
        key={metric[ID]}
        metricID={metric[ID]}
        metricName={metric.metric_i18n_set.name}
        initialRevision={props.initialRevision}
        metricAnsers={props.metricAnsers}
        notApplicableMetricIDs={props.notApplicableMetricIDs}
        freeTextAnswer={props.freeTextAnswers[metric[ID].toString()]}
      />
    ));
  }

  return (
    <nav aria-label={t('create-review.questionnaire.answerMetrics.navBar.aria-label')}>
      <Accordion
        defaultActiveKey="#0"
        ref={accordionRef}
        className={classes.bigMarginBottom}
      >
        {' '}
        {createAccordionItems()}
        {' '}
      </Accordion>
    </nav>
  );
}
