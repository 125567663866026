// @flow

import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { DATENSCHUTZERKLÄRUNG, IMPRESSUM } from 'routes/RoutesStrings';
import classes from './Footer.module.css';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <nav aria-labelledby={t('footer.aria-label')} className={classes.footerDiv}>
      <Container>
        <ul className={classes.footerList}>
          <li>
            <NavLink
              tabIndex="0"
              to={IMPRESSUM}
            >
              {t('footer.impressumLbl')}
            </NavLink>
          </li>
          <li>
            <NavLink
              tabIndex="0"
              to={DATENSCHUTZERKLÄRUNG}
            >
              {t('footer.privacyPolicy')}
            </NavLink>
          </li>
        </ul>
      </Container>
    </nav>
  );
}
