// @flow
import React, { useContext, useState } from 'react';
import lngs from 'i18n/languages';
import { useTranslation } from 'react-i18next';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { MetricContext } from 'contexts/MetricContext';
import i18next from 'i18n/i18n';
import classes from './LanguagePicker.module.css';

function LanguagePicker() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const metricContext = useContext(MetricContext);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <NavDropdown
        align="end"
        aria-label={t('navbar.languagepicker.menuButton')}
        id="dropdown-basic-button"
        title={i18n.language ? i18n.language.slice(0, 2) : ''}
        className={`${classes.dropdownMenuBtn} justify-content-end`}
        style={{ width: '100%' }}
        variant="secondary"
        menuVariant="dark"
        show={isOpen}
        onKeyPress={() => setIsOpen(!isOpen)}
        onClick={() => setIsOpen(!isOpen)}
      >
        {Object.keys(lngs).map((lng) => (
          <NavDropdown.Item
            key={lng}
            onClick={() => {
              i18n.changeLanguage(lng);
              metricContext.fetchMetricData(i18next.language);
            }}
            onKeyPress={() => {
              i18n.changeLanguage(lng);
              metricContext.fetchMetricData(i18next.language);
            }}
            className={`${
              i18n.language === lng ? classes.selectedLanguage : ''
            } ${classes.dropdownItem}`}
          >
            {lngs[lng].nativeName}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    </>
  );
}

export default LanguagePicker;
