// @flow
import React, { useState, useEffect } from 'react';
import AxiosWrapper from 'helper/axios/AxiosInstance';
import { useTranslation } from 'react-i18next';
import type { QuestionaireImplements } from 'routes/create-review/questionnaire/Questionnaire';
import type { ExternalGame, InternalGame } from 'typeAliases/backendAliases';
import GameChooserPresn from './GameChooserPresn';
import QuestionnaireStates from '../../consts';

const INTERNAL_PLATFORM_ID_KEY = 'internal_platform_id';

export type GameChooserImplements = {
  handleGameSearchSubmit(): void,
  setGameName(string): void,
  loadingGames: boolean,
  gameCardInfos: Array<ExternalGame>,
  gamesFound: boolean | void,
  chosenGameListId: number | void,
  setChosenGameListId(number | void): void,
  backendAlert: boolean,
  manyGamesFound: boolean,
  setChosenPlatformID(number | void): void,
  chosenPlatformID: number | void,
  submitBtnDisabled: boolean,
  handleStartReviewSubmit(any): void,
  loadingStartReview: boolean,
  handleChoseGameFromList(number): void,
  backendCreateReviewAlert: boolean,
  createReviewAltertContent: Array<string>,
  gameName: string,
}

type Props = {
  setReviewData: $PropertyType<QuestionaireImplements, 'setReviewData'>,
  setQuestionnaireState: $PropertyType<QuestionaireImplements, 'setQuestionnaireState'>,
  preselectedGame: InternalGame | void
}

export default function GameChooser(props: Props) {
  const [gameName, setGameName] = useState<string>(props.preselectedGame ? props.preselectedGame.name : '');
  const [loadingGames, setLoadingGames] = useState<boolean>(false);
  const [loadingStartReview, setLoadingStartReview] = useState<boolean>(false);
  const [gameCardInfos, setGameCardInfos] = useState<Array<ExternalGame>>([]);
  const [gamesFound, setGamesFound] = useState<boolean | void>();
  const [backendAlert, setBackendAlert] = useState<boolean>(false);
  const [backendCreateReviewAlert, setBackendCreateReviewAlert] = useState<boolean>(false);
  const [manyGamesFound, setManyGamesFound] = useState<boolean>(false);
  const [chosenGameListId, setChosenGameListId] = useState<number | void>();
  const [chosenPlatformID, setChosenPlatformID] = useState<number | void>();
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState<boolean>(true);
  const [createReviewAltertContent, setCreateReviewAltertContent] = useState<Array<string>>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (chosenPlatformID !== undefined
      && parseInt(chosenPlatformID, 10) >= 0
      && loadingStartReview === false) {
      setSubmitBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
    }
  }, [chosenPlatformID, loadingStartReview]);

  const handleGameSearchSubmit = () => {
    setGameCardInfos([]);
    setChosenPlatformID();
    setChosenGameListId();

    setLoadingGames(true);
    setBackendAlert(false);
    setManyGamesFound(false);
    setGamesFound();

    AxiosWrapper.getAxiosInstance()
      // $FlowIgnore
      .get(`${process.env.REACT_APP_EXTERNAL_GAMES}?name=${gameName.trim()}`)
      .then((response) => {
        setLoadingGames(false);
        setManyGamesFound(false);
        setGamesFound(true);

        const games = [];
        if (response.data.length === 0) {
          setGamesFound(false);
        }
        if (response.data.length >= 10) {
          setManyGamesFound(true);
        }

        response.data.forEach((gameData) => {
          const externalGame = gameData;
          games.push(externalGame);
        });
        setGameCardInfos(games);
      })
      .catch(() => {
        setLoadingGames(false);
        setBackendAlert(true);
        setGamesFound(false);
      });
  };

  const handleStartReviewSubmit = (event) => {
    event.preventDefault();
    if (chosenGameListId === undefined) return;

    const data = {};
    data[INTERNAL_PLATFORM_ID_KEY] = parseInt(chosenPlatformID, 10);

    setSubmitBtnDisabled(true);
    setLoadingStartReview(true);
    setBackendCreateReviewAlert(false);

    AxiosWrapper.getAxiosInstance()
      // $FlowIgnore
      .post(`${process.env.REACT_APP_INITIAL_REVIEW_URL + gameCardInfos[chosenGameListId].igdb_id}/`,
        data)
      .then((response) => {
        setSubmitBtnDisabled(false);
        setLoadingStartReview(false);
        props.setReviewData(response.data);
        props.setQuestionnaireState(QuestionnaireStates.ANSWER_METRICS);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400 || error.response.status === 404) {
            setCreateReviewAltertContent(
              [Object.keys(error.response.data)[0],
                error.response.data[Object.keys(error.response.data)[0]]],
            );
          } else {
            setCreateReviewAltertContent([t('errors.generalErrorHeader'), t('errors.generalErrorContent')]);
          }
        } else {
          setCreateReviewAltertContent([t('errors.generalErrorHeader'), t('errors.generalErrorContent')]);
        }
        setSubmitBtnDisabled(false);
        setLoadingStartReview(false);
        setBackendCreateReviewAlert(true);
      });
  };

  const handleChoseGameFromList = (newSelectedGameListID) => {
    setChosenGameListId(newSelectedGameListID);
    setBackendCreateReviewAlert(false);
  };

  return (
    <GameChooserPresn
      handleGameSearchSubmit={handleGameSearchSubmit}
      setGameName={setGameName}
      loadingGames={loadingGames}
      gameCardInfos={gameCardInfos}
      gamesFound={gamesFound}
      chosenGameListId={chosenGameListId}
      setChosenGameListId={setChosenGameListId}
      backendAlert={backendAlert}
      manyGamesFound={manyGamesFound}
      setChosenPlatformID={setChosenPlatformID}
      submitBtnDisabled={submitBtnDisabled}
      handleStartReviewSubmit={handleStartReviewSubmit}
      loadingStartReview={loadingStartReview}
      handleChoseGameFromList={handleChoseGameFromList}
      backendCreateReviewAlert={backendCreateReviewAlert}
      createReviewAltertContent={createReviewAltertContent}
      preselectedGame={props.preselectedGame}
      gameName={gameName}
    />
  );
}
