// @flow
import React, {
  useState, useEffect, useCallback, useContext,
} from 'react';
import AxiosWrapper from 'helper/axios/AxiosInstance';
import { useTranslation } from 'react-i18next';
import { encodeAuthentication } from 'helper/backend/backend';
import useInterval from 'hooks/UseInterval';
import { useNavigate } from 'react-router-dom';
import { REVIEW_STATUS } from 'routes/RoutesStrings';
import type {
  ReviewData, MetricAnswers, Metric,
} from 'typeAliases/backendAliases';
import type { MetricContextImplements } from 'contexts/MetricContext';
import { MetricContext } from 'contexts/MetricContext';
import { PlatformTypeContext } from 'contexts/PlatformTypeContext';
import { getSortedDisabilityTypeIDs } from 'helper/disability_types/disability_types';
import AnswerMetricConsts from './consts/States';
import AnswerMetricsPresn from './AnswerMetricsPresn';

const AUTOMATIC_SAVE_INTERVAL_MIN = 1;
const CHECK_IF_SAVE_INTERVAL = 10000;

const STATUS = 'status';

const SUBMIT_VALUE = 'submitted';
const NOT_SUBMIT_VALUE = 'unsubmitted';

const REVIEW_ACCORDION_ITEM_EVENT_KEY = 'reviewAccordionKey';

export type AnswerMetricImplements = {
  // states
  // metricData: Array<MetricData>,
  backendErrorMsg: String | null,
  metricAnswers: MetricAnswers,
  notApplicableMetricIDs: Set<number>,
  metricsByCategory: {} | $PropertyType<MetricContextImplements, 'metricsByDisablity'>,
  nextMetricRequestedBy: number | null,
  prevMetricRequestedBy: number | null,
  freeTextAnswers: {[metricID: string]: string},
  updateFreeAnswerText(metricID: string, freeText: string): void,

  changeMetricTab: {'next': boolean} | null,

  potentialNotApplicableMetrics: Array<Metric>,
  savingState: boolean,
  lastSavedTime: Date | null,
  lastSaved: Array<number>, // saves time diff since last save in an array to ensure re-render
  savingError: boolean,
  optionalMetrics: Array<Metric>,
  progress: number,
  currState: string,
  submitBtnLoading: boolean,

  // functions
  requestChangeMetricTab(boolean): void,
  consumeChangeMetricTab(): void,
  saveReview(): null,
  saveMetricAnswer(number, Array<number>): Set<number>,
  handleReviewBtnClick(): void,
  handleBackToQuestionBtnClick(): void,
  handleSubmitBtnClick(): void,
  setSavingError(boolean): void,
  didUserEnterData(): boolean,

  // consts
  reviewAccrdionItemEventKey: string,
};

type Props = {
  reviewData: ReviewData,
  reviewAnswers: MetricAnswers | void
}

export function AnswerMetrics(props: Props) {
  const [backendErrorMsg, setBackendErrorMsg] = useState<$PropertyType<AnswerMetricImplements, 'backendErrorMsg'>>(null);
  const [metricAnswers, setMetricAnswers] = useState<$PropertyType<AnswerMetricImplements, 'metricAnswers'>>({});
  const [answeredOptionMets, setAnsweredOptionMets] = useState<Set<number>>(new Set());
  // if the depending metic got answered in a way that those metrics do not need to get answered
  const [notApplicableMetricIDs, setNotApplicableMetricIDs] = useState<$PropertyType<AnswerMetricImplements, 'notApplicableMetricIDs'>>(
    new Set(),
  );
  const [potentialNotApplicableMetrics, setPotentialNotApplicableMetrics] = useState<$PropertyType<AnswerMetricImplements, 'potentialNotApplicableMetrics'>>([]);
  const [savingState, setSavingState] = useState<$PropertyType<AnswerMetricImplements, 'savingState'>>(false);
  const [lastSavedTime, setLastSavedTime] = useState<$PropertyType<AnswerMetricImplements, 'lastSavedTime'>>(null);
  const [lastSaved, setLastSaved] = useState<$PropertyType<AnswerMetricImplements, 'lastSaved'>>([]);
  const [savingError, setSavingError] = useState<$PropertyType<AnswerMetricImplements, 'savingError'>>(false);
  const [changeMetricTab, setChangeMetricTab] = useState<$PropertyType<AnswerMetricImplements, 'changeMetricTab'>>(null);
  const [optionalMetrics, setOptionalMetrics] = useState<$PropertyType<AnswerMetricImplements, 'optionalMetrics'>>([]);
  const [progress, setProgress] = useState<$PropertyType<AnswerMetricImplements, 'progress'>>(0);
  const [currState, setCurrState] = useState<$PropertyType<AnswerMetricImplements, 'currState'>>(AnswerMetricConsts.ANSWER_METRICS);
  const [submitBtnLoading, setSubmitBtnLoading] = useState<$PropertyType<AnswerMetricImplements, 'submitBtnLoading'>>(false);
  const [metricsByCategory, setMetricsByCategory] = useState<{} | $PropertyType<MetricContextImplements, 'metricsByDisablity'>>({});
  const [freeTextAnswers, setFreeTextAnswers] = useState<$PropertyType<AnswerMetricImplements, 'freeTextAnswers'>>({});

  const { t } = useTranslation();
  const navigate = useNavigate();
  const reviewedPlatform = useContext(PlatformTypeContext);
  const metricContext = useContext<$PropertyType<MetricContextImplements, 'businessData'>>(MetricContext);

  useEffect(() => {
    if (metricContext) {
      const metricsByCategoryTemp = metricContext
        .uniqueMetricsByDisabiltyAndPlatform[reviewedPlatform];
      setMetricsByCategory(metricsByCategoryTemp);
    }
  }, [metricContext]);

  const _getTimeLastSaved = useCallback(
    () => {
      if (lastSavedTime) {
        const currentTime = new Date();
        const difference = Math.abs(currentTime - lastSavedTime);
        return difference / (1000 * 60);
      }
      return null;
    },
    [lastSavedTime],
  );

  const createBackendData = useCallback(
    (currentAnswers, submit = false) => {
      const backendData = {};
      const answers = [];
      // $FlowIgnore
      Object.keys(currentAnswers).forEach((metricID: number) => {
        answers.push({
          metric: parseInt(metricID, 10),
          answers: currentAnswers[metricID.toString()],
        });
      });
      backendData.answers = answers;
      backendData.internal_platform_id = props.reviewData.reviewed_platform.id;
      backendData.previous_review_id = props.reviewData.initial_revision
        ? null
        : props.reviewData.previous_revision_id;
      backendData.review_status = submit ? SUBMIT_VALUE : NOT_SUBMIT_VALUE;
      backendData.free_text_answers = freeTextAnswers;
      return backendData;
    },
    [props.reviewData, freeTextAnswers],
  );

  const saveToBackend = useCallback(
    (submit = false) => {
      setSavingError(false);
      setSavingState(true);
      if (submit) setSubmitBtnLoading(true);
      const revID = props.reviewData.revision_id;
      const accessToken = props.reviewData.access_token;
      const authString = encodeAuthentication(revID, accessToken);
      const data = createBackendData(metricAnswers, submit);
      AxiosWrapper.getAxiosInstance(authString)
        .put(process.env.REACT_APP_URL_UPDATE_REVISION, data)
        .then(async (response) => {
          setSavingState(false);
          setLastSaved([0]);
          setLastSavedTime(new Date());
          if (submit) {
            navigate(REVIEW_STATUS, {
              state: {
                reviewID: props.reviewData.revision_id,
                status: response.data[STATUS],
                game: props.reviewData.reviewed_game,
                platform: props.reviewData.reviewed_platform.name,
                initialReview: props.reviewData.initial_revision,
              },
            });
            // await new Promise((resolve) => setTimeout(resolve, 500));
            // setSubmitBtnLoading(false);
          }
        })
        .catch((error) => {
          setSavingState(false);
          setSavingError(true);
          setSubmitBtnLoading(false);
          let errorMsg = t('create-review.questionnaire.answerMetrics.couldNotSaveErrorContent');
          if (error.response !== undefined) {
            const errorKey = Object.keys(error.response.data);
            if (errorKey) {
              errorMsg = error.response.data[errorKey];
            }
          }
          setBackendErrorMsg(errorMsg);
          window.scrollTo(0, 0);
        });
    },
    [props.reviewData, createBackendData, navigate, metricAnswers, t],
  );

  const isMetricDataLoaded = useCallback(
    () => {
      if (Object.keys(metricsByCategory).length > 0) return true;
      return false;
    },
    [metricsByCategory],
  );

  useInterval(() => {
    const timeDiff = _getTimeLastSaved();
    if (timeDiff) {
      setLastSaved([parseInt(timeDiff, 10)]);
    }
  }, CHECK_IF_SAVE_INTERVAL);

  useEffect(() => {
    const differenceMinutes = _getTimeLastSaved();
    if (differenceMinutes) {
      if (differenceMinutes > AUTOMATIC_SAVE_INTERVAL_MIN) {
        saveToBackend();
      }
    } else if (Object.keys(metricAnswers).length > 0) {
      saveToBackend();
    }
  }, [metricAnswers, _getTimeLastSaved, saveToBackend]);

  useEffect(() => {
    if (isMetricDataLoaded()) {
      _setAllPotentialNotApplicableANDOptionalMetrics();
    }
  }, [metricsByCategory, isMetricDataLoaded]);

  const saveMetricAnswer = useCallback(
    (metricID: number,
      newAnswers: Array<number>,
      modifiedNotApplicableMetricIDs = null) => {
      _updateMetricAnswerList(metricID, newAnswers);
      const changedNotApplicableMetricIDs = _updateNotApplicableMetricIDs(
        metricID, modifiedNotApplicableMetricIDs,
      );
      _updateAnsweredOptionalMetrics(metricID, newAnswers);
      return changedNotApplicableMetricIDs;
    },
    [metricAnswers,
      props.reviewData.previous_revision_answers,
      setMetricAnswers,
      notApplicableMetricIDs,
      potentialNotApplicableMetrics,
      metricAnswers,
    ],
  );

  const reconstructStateFromSave = useCallback((newNotApplicableMetrics) => {
    if (props.reviewData.free_text_answers) {
      setFreeTextAnswers(props.reviewData.free_text_answers);
    }
    if (props.reviewAnswers !== undefined) {
      setMetricAnswers(props.reviewAnswers);
      const disabilityIDs = getSortedDisabilityTypeIDs(metricContext.disability_types);
      disabilityIDs.forEach(
        (disabiltyID) => {
          // if (Object.keys(metricsByCategory).length === 0) return null;
          const metricList: Array<Metric> = metricsByCategory[parseInt(disabiltyID, 10)];
          metricList.sort((a, b) => a.sort_id - b.sort_id);
          let changedNotApplicableMetrics = newNotApplicableMetrics;
          metricList.forEach((metric) => {
            // $FlowIgnore
            if (props.reviewAnswers[metric.id]) {
              changedNotApplicableMetrics = saveMetricAnswer(metric.id,
                props.reviewAnswers[metric.id],
                changedNotApplicableMetrics);
            }
          });
        },
      );
    }
  });

  useEffect(() => {
    if (isMetricDataLoaded()
    ) {
      const newNotApplicableMetrics = _setAllNotApplicableMetrics(potentialNotApplicableMetrics);
      reconstructStateFromSave(newNotApplicableMetrics);
    }
  }, [potentialNotApplicableMetrics]);

  useEffect(() => {
    if (metricsByCategory !== undefined) {
      const nrOfNotApplicableMetrics = notApplicableMetricIDs !== undefined
        ? notApplicableMetricIDs.size : 0;
      const nrOfOptionalMetrics = optionalMetrics.length;
      const totalMetrics = Object.keys(metricsByCategory)
        .map((category) => metricsByCategory[parseInt(category, 10)].length)
        .reduce((a, b) => a + b, 0);
      const nrOfNeededMetrics = totalMetrics - nrOfNotApplicableMetrics - nrOfOptionalMetrics;
      const nrOfAnsweredMetrics = Object.keys(metricAnswers).length;
      const nrOfAnsweredOptionalMetrics = answeredOptionMets.size;
      setProgress(((nrOfAnsweredMetrics - nrOfAnsweredOptionalMetrics) / nrOfNeededMetrics) * 100);
    }
  }, [metricsByCategory, metricAnswers, notApplicableMetricIDs, optionalMetrics.length]);

  const saveReview = useCallback(
    () => {
      saveToBackend();
      return null;
    },
    [props.reviewData, createBackendData, navigate, metricAnswers, t],
  );

  function _isMetricOptional(metricID) {
    if (metricContext.metricsByID[metricID].mandatory === false
    && metricContext.metricsByID[metricID].depending_metric_answer_set.length === 0) {
      return true;
    }
    return false;
  }

  function _updateAnsweredOptionalMetrics(metricID, newAnswers) {
    if (newAnswers.length !== 0 && _isMetricOptional(metricID)) {
      const newAnsweredOptionMets = new Set(answeredOptionMets);
      newAnsweredOptionMets.add(metricID);
      setAnsweredOptionMets(answeredOptionMets.add(metricID));
    } else if (answeredOptionMets.has(metricID)) {
      const newAnsweredOptionMets = new Set(answeredOptionMets);
      newAnsweredOptionMets.delete(metricID);
      setAnsweredOptionMets(newAnsweredOptionMets);
    }
  }

  function _updateMetricAnswerList(metricID: number, newAnswers: Array<number>) {
    const metricIdStr = metricID.toString();
    const currentAnswers = metricAnswers;
    const prevRefAnswersOrNull = props.reviewData.previous_revision_answers
      ? props.reviewData.previous_revision_answers : {};
    const prevRefAnswers = prevRefAnswersOrNull[metricIdStr] !== undefined
      ? prevRefAnswersOrNull[metricIdStr] : [];
    const answersSame = _areAnswersSame(prevRefAnswers, newAnswers);
    if (newAnswers.length === 0 && prevRefAnswers.length === 0) {
      delete currentAnswers[metricIdStr];
    } else if (answersSame) {
      delete currentAnswers[metricIdStr];
    } else if (newAnswers.length > 0) {
      currentAnswers[metricIdStr] = newAnswers;
    } else { // props.reviewData.initial_revision
      delete currentAnswers[metricIdStr];
    }
    const answersCopy = JSON.parse(JSON.stringify(currentAnswers));
    setMetricAnswers(answersCopy);
  }

  function _updateNotApplicableMetricIDs(changeMetricID, modifiedNotApplicalbeMetrics = null) {
    const notApplicalbeMetrics = modifiedNotApplicalbeMetrics || new Set(notApplicableMetricIDs);
    potentialNotApplicableMetrics.map((metric: Metric) => {
      metric.depending_metric_answer_set.map(
        (dependingMetricRelation) => {
          if (dependingMetricRelation.depending_metric === changeMetricID) {
            _decideIfMetricIsNotApplicable(notApplicalbeMetrics, metric);
          }
          return undefined;
        },
      );
      return undefined;
    });
    setNotApplicableMetricIDs(notApplicalbeMetrics);
    return notApplicalbeMetrics;
  }

  function _setAllNotApplicableMetrics(potentialNotApplicableMetricList) {
    const notApplicableMetrics = new Set(notApplicableMetricIDs);
    potentialNotApplicableMetricList.forEach((metric: Metric) => {
      _decideIfMetricIsNotApplicable(notApplicableMetrics, metric);
    });
    setNotApplicableMetricIDs(notApplicableMetrics);
    return notApplicableMetrics;
  }

  function _decideIfMetricIsNotApplicable(notApplicalbeMetrics, metricToDecide) {
    let dependingAnswerFound = false;
    metricToDecide.depending_metric_answer_set.forEach((dependingRelation) => {
      const dependingMetricID = dependingRelation.depending_metric.toString();
      dependingRelation.answers.forEach((answerID) => {
        const prevRevAnswers = props.reviewData.previous_revision_answers;
        if (
          (metricAnswers[dependingMetricID]
            && metricAnswers[dependingMetricID].indexOf(answerID) !== -1)
          || (!metricAnswers[dependingMetricID]
            && prevRevAnswers
            && prevRevAnswers[dependingMetricID]
            && prevRevAnswers[dependingMetricID].indexOf(answerID) !== -1)
        ) {
          dependingAnswerFound = true;
        }
      });
    });
    if (dependingAnswerFound) {
      notApplicalbeMetrics.delete(metricToDecide.id);
    } else {
      notApplicalbeMetrics.add(metricToDecide.id);
      saveMetricAnswer(metricToDecide.id, [], notApplicalbeMetrics);
    }
  }

  function _setAllPotentialNotApplicableANDOptionalMetrics() {
    const newPotentialNotApplicableMetrics = [];
    const newOptionalMetrics = [];
    Object.keys(metricsByCategory).forEach((categoryID) => {
      metricsByCategory[parseInt(categoryID, 10)].forEach((metric) => {
        if (metric.depending_metric_answer_set.length > 0) {
          newPotentialNotApplicableMetrics.push(metric);
        } else if (!metric.mandatory) {
          newOptionalMetrics.push(metric);
        }
      });
    });
    setOptionalMetrics(newOptionalMetrics);
    setPotentialNotApplicableMetrics(newPotentialNotApplicableMetrics);
  }

  function _areAnswersSame(answers1: Array<number>, answers2: Array<number>): boolean {
    if (answers1 === undefined && answers2 === undefined) return true;
    if ((answers1 === undefined && answers2 !== undefined)
      || (answers1 !== undefined && answers2 === undefined)) return false;
    if (answers1.length !== answers2.length) return false;
    return answers1.every((value1) => {
      let value1Found = false;
      answers2.forEach((value2) => {
        if (value1 === value2) value1Found = true;
      });
      return value1Found;
    });
  }

  const updateFreeAnswerText = useCallback(
    (metricID: string, freeText: string) => {
      const freeTextAnswersCopy = JSON.parse(JSON.stringify(freeTextAnswers));
      const prevFreeAnswers = props.reviewData.previous_revision_free_answers;
      if (prevFreeAnswers && ((!(metricID in prevFreeAnswers) && freeText === '') || (prevFreeAnswers && prevFreeAnswers[metricID] === freeText))
      && metricID in freeTextAnswers) {
        delete freeTextAnswersCopy[metricID];
      } else {
        freeTextAnswersCopy[metricID] = freeText;
      }
      setFreeTextAnswers(freeTextAnswersCopy);
    }, [freeTextAnswers],
  );

  const handleReviewBtnClick = useCallback(
    () => {
      setCurrState(AnswerMetricConsts.REVIEW);
    },
    [setCurrState, AnswerMetricConsts.REVIEW, metricAnswers],
  );

  const handleBackToQuestionBtnClick = useCallback(
    () => {
      setCurrState(AnswerMetricConsts.ANSWER_METRICS);
    },
    [setCurrState, AnswerMetricConsts.ANSWER_METRICS],
  );

  // -> test by submitting a completed review
  const handleSubmitBtnClick = useCallback(
    () => {
      saveToBackend(true);
    },
    [props.reviewData, createBackendData, navigate, metricAnswers, t],
  );

  const requestChangeMetricTab = useCallback(
    (next: boolean) => {
      setChangeMetricTab({ next });
    },
    [setChangeMetricTab],
  );

  const consumeChangeMetricTab = useCallback(
    () => {
      setChangeMetricTab(null);
    },
    [setChangeMetricTab],
  );

  const didUserEnterData = useCallback(
    () => {
      if (Object.keys(metricAnswers).length > 0 && !submitBtnLoading) {
        return true;
      }
      return false;
    },
    [metricAnswers, submitBtnLoading],
  );

  return (
    <>
      <AnswerMetricsPresn
        reviewData={props.reviewData}
        saveReview={saveReview}
        savingState={savingState}
        lastSaved={lastSaved}
        savingError={savingError}
        setSavingError={setSavingError}
        backendErrorMsg={backendErrorMsg}
        changeMetricTab={changeMetricTab}
        requestChangeMetricTab={requestChangeMetricTab}
        consumeChangeMetricTab={consumeChangeMetricTab}
        saveMetricAnswer={saveMetricAnswer}
        metricAnswers={metricAnswers}
        reviewAccrdionItemEventKey={REVIEW_ACCORDION_ITEM_EVENT_KEY}
        notApplicableMetricIDs={notApplicableMetricIDs}
        optionalMetrics={optionalMetrics}
        progress={progress}
        currState={currState}
        handleReviewBtnClick={handleReviewBtnClick}
        handleBackToQuestionBtnClick={handleBackToQuestionBtnClick}
        handleSubmitBtnClick={handleSubmitBtnClick}
        metricsByCategory={metricsByCategory}
        submitBtnLoading={submitBtnLoading}
        potentialNotApplicableMetrics={potentialNotApplicableMetrics}
        reviewedPlatform={reviewedPlatform}
        didUserEnterData={didUserEnterData}
        updateFreeAnswerText={updateFreeAnswerText}
        freeTextAnswers={freeTextAnswers}
      />
    </>
  );
}
