// @flow
import React, { useCallback, useEffect, useState } from 'react';
import type { ButtonStates, HandleMetricChoiceBtnClick } from 'typeAliases/frontendAliases';
import type { MetricContextImplements } from 'contexts/MetricContext';
import Col from 'react-bootstrap/Col';
import AnswerButton from 'atoms/answerButton/AnswerButton';
import { FaTimes } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import type { SearchImplements } from 'routes/search/Search';
import type { Node } from 'react';
import { PossibleAnswerKeys } from 'components/filterTabs/ExtraFilters';
import classes from './ActiveFilterButtons.module.css';

type Props = {
    metricContext: $PropertyType<MetricContextImplements, 'businessData'>,
    appliedFilters: ButtonStates,
    changeFilterValue: HandleMetricChoiceBtnClick,
    updateExtraFilterState: $PropertyType<SearchImplements, 'updateExtraFilterState'>,
    removeAllFilters(): void,
    resetSearchPhrase(): void,
    searchPhrase: string,
    extraFilterStates: $PropertyType<SearchImplements, 'extraFilterStates'>,
}

const REMOVE_ALL_BTN_KEY = 'remove_all_filters';
const REMOVE_SEARCH_PHRASE = 'remove_search_phrase';

export default function ActivefilterButtons(props: Props) {
  const { t } = useTranslation();
  const [activeButtons, setActiveButtons] = useState();

  const addSearchPhraseFilterButton = useCallback(
    () => {
      if (props.searchPhrase.length > 0) {
        return (
          <Button
            key={REMOVE_SEARCH_PHRASE}
            variant="primary"
            className={classes.searchPhrasesButton}
            onClick={props.resetSearchPhrase}
          >
            {`${t('search.removeSearchPhrase')} ${props.searchPhrase} `}
            <FaTimes />
          </Button>
        );
      }
      return undefined;
    },
    [props.searchPhrase],
  );

  const handleRemoveExtraFilter = useCallback(
    (event) => {
      const filterName = event.currentTarget.getAttribute('filtername');
      props.updateExtraFilterState([filterName], [], true);
    },
    [props.extraFilterStates],
  );

  const addExtraFilterButtons = useCallback(
    (): Array<Node | void> => {
      if (!props.extraFilterStates) return [];
      return Object.entries(props.extraFilterStates)
        .map(([filterName, filterState]) => {
          if (filterState === undefined) return undefined;
          if (typeof filterState === 'object') {
            // $FlowIgnore
            const filterStates = Array.from(filterState);
            if (filterStates.length === 0) return undefined;
            const selectedFiltersString = filterStates.map((answerID) => {
              let answerName;
              if (props.metricContext[PossibleAnswerKeys[filterName]] === undefined) {
                return answerID;
              }
              props.metricContext[PossibleAnswerKeys[filterName]].forEach((element) => {
                if (element.id === answerID) answerName = element.name;
              });
              return answerName;
            }).join(', ');
            return (
              <Button
                key={filterName}
                variant="primary"
                className={classes.searchPhrasesButton}
                onClick={handleRemoveExtraFilter}
                filtername={filterName}
              >
                {`${t(`search.filterNames.${filterName}`)}: ${selectedFiltersString} `}
                <FaTimes />
              </Button>
            );
          }
          return (
            <Button
              key={filterName}
              variant="primary"
              className={classes.searchPhrasesButton}
              onClick={handleRemoveExtraFilter}
              filtername={filterName}
            >
              {/* $FlowIgnore */}
              {`${t(`search.filterNames.${filterName}`)}: ${filterState} `}
              <FaTimes />
            </Button>
          );
        }).filter(Boolean);
    },
    [props.extraFilterStates],
  );

  const createActiveFilerButtons = useCallback(
    () => {
      const activeFilterButtons = Object.entries(props.appliedFilters)
        .map(([metricID, answerIDs: Set]) => {
          const metric = props.metricContext.metricsByID[parseInt(metricID, 10)];
          const metricChoices = props.metricContext.answer_sets[metric.answer_type].answers;
          return metricChoices.map((metricChoice) => {
            // $FlowIgnore
            if (!answerIDs.has(metricChoice.id)) return '';
            return (
              <AnswerButton
                key={metricChoice.id}
                buttonStates={props.appliedFilters}
                handleButtonClick={props.changeFilterValue}
                metricChoice={metricChoice}
                metric={metric}
                isActiveFilterButton
                buttonLabel={(
                  <>
                    {`${metric.metric_i18n_set.name}: ${metricChoice.answer_i18n_set.answer_text} `}
                    <FaTimes />
                  </>
            )}
              />
            );
          });
        });
      const extraSearchButtons = addExtraFilterButtons();
      const searchPhraseButton = addSearchPhraseFilterButton();
      if (searchPhraseButton) activeFilterButtons.push(searchPhraseButton);
      if (extraSearchButtons.length > 0) activeFilterButtons.push(extraSearchButtons);
      if (activeFilterButtons.length === 0) return activeFilterButtons;
      activeFilterButtons.push(
        <Button
          key={REMOVE_ALL_BTN_KEY}
          variant="outline-primary"
          className={classes.removeAllFiltersBtn}
          onClick={props.removeAllFilters}
        >
          {t('search.removeFiltersBtn')}
          {' '}
          <FaTimes />
        </Button>,
      );
      return activeFilterButtons;
    },
    [props.appliedFilters, props.searchPhrase, props.extraFilterStates],
  );

  useEffect(() => {
    setActiveButtons(createActiveFilerButtons());
  }, [props.appliedFilters, props.searchPhrase, props.extraFilterStates]);

  return (
    <Col>
      {activeButtons}
    </Col>
  );
}
