// @flow
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const usePrompt = (when: boolean, message: string) => {
  // const navigate = useNavigate();

  // const self = useRef(null);
  const location = useLocation();

  const onWindowOrTabClose = (event) => {
    let evt = event;
    if (!when) {
      return;
    }

    if (typeof evt === 'undefined') {
      evt = window.evt;
    }

    if (evt) {
      evt.returnValue = message;
    }
    // eslint-disable-next-line consistent-return
    return message;
  };

  useEffect(() => {
    // if (when) {
    //   self.current = history.block(message);
    // } else {
    //   self.current = null;
    // }

    window.addEventListener('beforeunload', onWindowOrTabClose);

    return () => {
      // if (self.current) {
      //   self.current();
      //   self.current = null;
      // }

      window.removeEventListener('beforeunload', onWindowOrTabClose);
    };
  }, [message, when, location]);
};
