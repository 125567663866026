// @flow
import React, { useState, useEffect } from 'react';
import SmallGameCardPresn from './SmallGameCardPresn';

type Props = {
    chosenGameListId: number | void,
    gameListId: number,
    gameName: string,
    handleChoseGameFromList(number): void,
    platformNames: string,
    release: string,
    showButton: boolean,
  };

export default function SmallGameCard(props: Props) {
  const [cardSelected, setCardSelected] = useState(false);

  useEffect(() => {
    if (
      props.gameListId !== undefined
      && props.chosenGameListId !== undefined
      && props.chosenGameListId === props.gameListId
    ) {
      setCardSelected(true);
    } else {
      setCardSelected(false);
    }
  }, [props.gameListId, props.chosenGameListId]);

  return (
    <>
      <SmallGameCardPresn
        gameName={props.gameName}
        release={props.release}
        platformNames={props.platformNames}
        gameListId={props.gameListId}
        cardSelected={cardSelected}
        handleChoseGameFromList={props.handleChoseGameFromList}
        showButton={props.showButton}
      />
    </>
  );
}
