// @flow
import React, { useContext } from 'react';
import type { Node } from 'react';
import Tab from 'react-bootstrap/Tab';
import Heading2 from 'atoms/heading2/Heading2';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import type { AnswerMetricImplements } from 'components/AnswerMetrics/AnswerMetrics';
import type { ReviewData } from 'typeAliases/backendAliases';
import type { MetricContextImplements } from 'contexts/MetricContext';
import { MetricContext } from 'contexts/MetricContext';
import MetricTabPresn from './MetricTabPresn';
import classes from './MetricTab.module.css';

type Props = {
  reviewData: ReviewData,
  metricsByCategory: $PropertyType<AnswerMetricImplements, 'metricsByCategory'>,
  requestChangeMetricTab: $PropertyType<AnswerMetricImplements, 'requestChangeMetricTab'>,
  saveMetricAnswer: $PropertyType<AnswerMetricImplements, 'saveMetricAnswer'>,
  metricAnswers: $PropertyType<AnswerMetricImplements, 'metricAnswers'>,
  reviewAccrdionItemEventKey: $PropertyType<AnswerMetricImplements, 'reviewAccrdionItemEventKey'>,
  notApplicableMetricIDs: $PropertyType<AnswerMetricImplements, 'notApplicableMetricIDs'>,
  progress: $PropertyType<AnswerMetricImplements, 'progress'>,
  handleReviewBtnClick: $PropertyType<AnswerMetricImplements, 'handleReviewBtnClick'>,
  activeMetricID: string | void,
  optionalMetrics: $PropertyType<AnswerMetricImplements, 'optionalMetrics'>,
  updateFreeAnswerText: $PropertyType<AnswerMetricImplements, 'updateFreeAnswerText'>,
  freeTextAnswers: $PropertyType<AnswerMetricImplements, 'freeTextAnswers'>,
}

export default function MetricTab(props: Props) {
  const { t } = useTranslation();
  const metricContext = useContext<$PropertyType<MetricContextImplements, 'businessData'>>(MetricContext);

  function createTabs(metrics: $PropertyType<AnswerMetricImplements, 'metricsByCategory'>): Array<Node> | null {
    if (metrics === undefined) return null;
    const metricTabs: Array<Node> = [];
    const metricID = parseInt(props.activeMetricID, 10);
    if (props.activeMetricID === 'reviewAccordionKey') {
      metricTabs.push(createReviewTab());
    } else if (props.activeMetricID && !Number.isNaN(props.activeMetricID)) {
      const activeMetric = metricContext.metricsByID[metricID];
      metricTabs.push(
        <Tab.Pane key={props.activeMetricID} eventKey={props.activeMetricID}>
          <MetricTabPresn
            metric={activeMetric}
            reviewData={props.reviewData}
            requestChangeMetricTab={props.requestChangeMetricTab}
            metricAnswers={props.metricAnswers[props.activeMetricID]
              ? props.metricAnswers[props.activeMetricID] : []}
            saveMetricAnswer={props.saveMetricAnswer}
            notApplicable={props.notApplicableMetricIDs.has(metricID)}
            prevRevAnswers={props.reviewData.previous_revision_answers
            // $FlowIgnore
          && props.reviewData.previous_revision_answers[props.activeMetricID]
              ? props.reviewData.previous_revision_answers[props.activeMetricID]
              : []}
            gameName={props.reviewData.reviewed_game.name}
            updateFreeAnswerText={props.updateFreeAnswerText}
            freeTextAnswers={props.freeTextAnswers}
          />
        </Tab.Pane>,
      );
    }
    return metricTabs;
  }

  function getAlertIfNeeded(): Node | null {
    if (props.reviewData.initial_revision && props.progress !== 100) {
      return createAlert(t('create-review.questionnaire.answerMetrics.notAllQuestionsAnswered'));
    } if (!props.reviewData.initial_revision
      && (Object.keys(props.metricAnswers).length === 0
      && Object.keys(props.freeTextAnswers).length === 0)) {
      return createAlert(t('create-review.questionnaire.answerMetrics.revNeedsToChangeAnswers'));
    }
    const missingMetrics = _getUnansweredMetricsNames();
    if (missingMetrics !== null) {
      return createAlert(`${t('create-review.questionnaire.answerMetrics.followingMetricsNotAnswered')}: ${missingMetrics}`);
    }
    return null;
  }

  function _getUnansweredMetricsNames() {
    const missingMetricNames = [];
    Object.keys(props.metricsByCategory).forEach((categoryID) => {
      props.metricsByCategory[parseInt(categoryID, 10)].forEach((metric) => {
        if (!props.metricAnswers[metric.id.toString()]
        && ((props.reviewData.previous_revision_answers
        && !props.reviewData.previous_revision_answers[metric.id.toString()])
           || !props.reviewData.previous_revision_answers)
        && !props.notApplicableMetricIDs.has(metric.id)
        && props.optionalMetrics.every((optionalMetric) => {
          if (optionalMetric.id === metric.id) return false;
          return true;
        })
        ) {
          missingMetricNames.push(metric.metric_i18n_set.name);
        }
      });
    });
    if (missingMetricNames.length === 0) return null;
    return missingMetricNames.join(', ');
  }

  // function getMissingMetricNames(): string | null {
  //   const missingMetricNames = [];
  //   props.potentialNotApplicableMetrics.forEach((metric) => {
  //     const metricIDStr = metric.id.toString();
  //     if (!(props.metricAnswers[metricIDStr]
  //         || props.notApplicableMetricIDs.has(metric.id)
  //         || (props.reviewData.previous_revision_answers
  //           && props.reviewData.previous_revision_answers[metricIDStr]))) {
  //       missingMetricNames.push(metric.metric_i18n_set.name);
  //     }
  //   });
  //   if (missingMetricNames.length === 0) return null;
  //   return missingMetricNames.join(',');
  // }

  function createReviewTab(): Node {
    return (
      <Tab.Pane
        key={props.reviewAccrdionItemEventKey}
        eventKey={`${props.reviewAccrdionItemEventKey}`}
      >
        <Heading2
          heading={t(
            'create-review.questionnaire.answerMetrics.AccordionItemReviewListItem',
          )}
          subheading={t(
            'create-review.questionnaire.answerMetrics.AccordionItemReview',
          )}
        />
        {getAlertIfNeeded()}
        <Button
          size="lg"
          className={classes.bigMargin}
          disabled={getAlertIfNeeded() !== null}
          onClick={() => props.handleReviewBtnClick()}
        >
          {t('create-review.questionnaire.answerMetrics.reviewButtonLabel')}
        </Button>
      </Tab.Pane>
    );
  }

  function createAlert(message) {
    return (
      <Alert variant="warning">
        {message}
      </Alert>
    );
  }

  return (
    <Tab.Content>
      {createTabs(props.metricsByCategory)}
    </Tab.Content>
  );
}
