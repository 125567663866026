import React from 'react';
import Heading1 from 'atoms/heading1/Heading1';
import Email from 'atoms/email/Email';
import Heading2 from 'atoms/heading2/Heading2';
import Telephone from 'atoms/telephone/Telephone';
import Address from 'atoms/address/Address';

export default function ImpressumDE() {
  return (
    <>
      <Heading1 heading="Impressum" />
      <Heading2 heading="Angaben gem&auml;&szlig; &sect; 5 TMG" />

      <Address />

      <Heading2 heading="Kontakt" />

      <p>
        Telefon:
        {' '}
        <Telephone />
        <br />
        E-Mail:
        {' '}
        <Email />

      </p>

      <Heading2 heading="Redaktionell verantwortlich" />
      {/* <strong>Redaktionell verantwortlich</strong> */}

      <Address />

      <p>
        Quelle:
        {' '}
        <a
          href="https://www.e-recht24.de"
        >
          eRecht24
        </a>

      </p>

    </>
  );
}
