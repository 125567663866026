// @flow
import React from 'react';
import classes from './Subheading.module.css';

type Props = {
    subheading: string,
}

export default function Subheading(props: Props) {
  return (
    <>
      {props.subheading.length > 0 ? <div className={classes.subeading} role="doc-subtitle">{props.subheading}</div> : null}
    </>
  );
}

Subheading.defaultProps = {
  subheading: '',
};
