const themes = {
  // dark: {
  // },
  light: {
    // lightBlue: '#007bff',
    // primaryColor: '277, 30%,',
    // normalLightness: '25%',
    // darkLightness: '18%',
    // primaryColor: '46, 33, 54',
    // warningColor: '220,53,69',
    // successColor: '25, 135, 84',
    // warningColor: '354, 70%,',
    // successColor: '152, 69%,',
    // borderDim: '0 0 0 0.25rem',
    // borderOpacity: '50%',
    // borderOpacityLight: '25%',
    // 'bs-primary': '#2e2136',
  },
};

export default themes;
