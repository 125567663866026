// @flow
import React, { } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import PrivacyPolicyEN from './language/PrivacyPolicy_en';
import PrivacyPolicyDE from './language/PrivacyPolicy_de';

const DEFAULT_LANG = process.env.REACT_APP_DEFAULT_LANG;
const GERMAN_LANG = process.env.REACT_APP_GERMAN_LANG;
const NAME = process.env.REACT_APP_WEBSITE_NAME;

export default function PrivacyPolicy() {
  // needed!! This ensures, that translations get switched when languages gets chagned
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();

  function getLanguage() {
    return i18n.language
        || (typeof window !== 'undefined' && window.localStorage.i18nextLng)
        || DEFAULT_LANG;
  }

  function renderPrivacyPolicyLang() {
    if (getLanguage() === GERMAN_LANG) {
      // $FlowIgnore
      return <PrivacyPolicyDE name={NAME} />;
    }
    // $FlowIgnore
    return <PrivacyPolicyEN name={NAME} />;
  }

  return (
    <>
      {renderPrivacyPolicyLang()}
    </>
  );
}
