// @flow
import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import globalClasses from 'globalCSS/global.module.css';
import type { ReviewData } from 'typeAliases/backendAliases';
import type { AnswerMetricImplements } from 'components/AnswerMetrics/AnswerMetrics';
import MetricNavBar from '../metricNavBar/MetricNavBar';
import MetricTab from '../metricTab/MetricTab';
import AnswerProgressBar from '../progressBar/AnswerProgressBar';
import classes from './MetricForm.module.css';

type Props = {
  initialRevision: boolean,
  reviewData: ReviewData,
  changeMetricTab: $PropertyType<AnswerMetricImplements, 'changeMetricTab'>,
  requestChangeMetricTab: $PropertyType<AnswerMetricImplements, 'requestChangeMetricTab'>,
  consumeChangeMetricTab: $PropertyType<AnswerMetricImplements, 'consumeChangeMetricTab'>,
  saveMetricAnswer: $PropertyType<AnswerMetricImplements, 'saveMetricAnswer'>,
  metricAnswers: $PropertyType<AnswerMetricImplements, 'metricAnswers'>,
  reviewAccrdionItemEventKey: $PropertyType<AnswerMetricImplements, 'reviewAccrdionItemEventKey'>,
  notApplicableMetricIDs: $PropertyType<AnswerMetricImplements, 'notApplicableMetricIDs'>,
  progress: $PropertyType<AnswerMetricImplements, 'progress'>,
  metricsByCategory: $PropertyType<AnswerMetricImplements, 'metricsByCategory'>,
  handleReviewBtnClick: $PropertyType<AnswerMetricImplements, 'handleReviewBtnClick'>,
  potentialNotApplicableMetrics: $PropertyType<AnswerMetricImplements, 'potentialNotApplicableMetrics'>,
  optionalMetrics: $PropertyType<AnswerMetricImplements, 'optionalMetrics'>,
  updateFreeAnswerText: $PropertyType<AnswerMetricImplements, 'updateFreeAnswerText'>,
  freeTextAnswers: $PropertyType<AnswerMetricImplements, 'freeTextAnswers'>,
}

export default function MetricForm(props: Props) {
  const [activeMetricID, setActiveMetricID] = useState<string | void>();

  return (
    <>
      <Tab.Container activeKey={activeMetricID} onSelect={(k) => setActiveMetricID(k)} id="list-group-tabs-example">
        <Row className={classes.marginTop}>
          <Col sm={12} md={5} lg={4}>
            <MetricNavBar
              initialRevision={props.initialRevision}
              changeMetricTab={props.changeMetricTab}
              consumeChangeMetricTab={props.consumeChangeMetricTab}
              metricAnsers={props.metricAnswers}
              reviewAccrdionItemEventKey={props.reviewAccrdionItemEventKey}
              notApplicableMetricIDs={props.notApplicableMetricIDs}
              metricsByCategory={props.metricsByCategory}
              freeTextAnswers={props.freeTextAnswers}
            />
          </Col>
          <Col sm={12} md={7} lg={8} className={globalClasses.xxlBottomMargin}>
            <AnswerProgressBar
              initialRevision={props.initialRevision}
              progress={props.progress}
            />
            <MetricTab
              optionalMetrics={props.optionalMetrics}
              activeMetricID={activeMetricID}
              reviewData={props.reviewData}
              metricAnswers={props.metricAnswers}
              requestChangeMetricTab={props.requestChangeMetricTab}
              saveMetricAnswer={props.saveMetricAnswer}
              reviewAccrdionItemEventKey={props.reviewAccrdionItemEventKey}
              notApplicableMetricIDs={props.notApplicableMetricIDs}
              progress={props.progress}
              handleReviewBtnClick={props.handleReviewBtnClick}
              potentialNotApplicableMetrics={props.potentialNotApplicableMetrics}
              metricsByCategory={props.metricsByCategory}
              updateFreeAnswerText={props.updateFreeAnswerText}
              freeTextAnswers={props.freeTextAnswers}
            />
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
}
