// @flow
import React from 'react';
import Heading1 from 'atoms/heading1/Heading1';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle } from 'react-icons/fa';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import type { FeedbackImplements } from 'routes/feedback/Feedback';
import classes from './Success.module.css';

type Props = {
  email: $PropertyType<FeedbackImplements, 'email'>,
}

export default function Success(props: Props) {
  const { t } = useTranslation();
  return (
    <div>
      <Row>
        <Col xs="auto">
          <FaCheckCircle className={classes.checkCircle} />
        </Col>
        <Col xs={6}>
          <Heading1 heading={t('feedback.success.heading1')} />
          {props.email
            ? (
              <p>
                {t('feedback.success.description')}
                <a href={`mailto: ${props.email}`}>{props.email}</a>
                .
              </p>
            ) : (
              <p>
                {t('feedback.success.noEmailDescription')}
              </p>
            )}
        </Col>
      </Row>
    </div>
  );
}
