// @flow
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Loader from 'atoms/loader/Loader';
import SmallGameCard from 'atoms/smallGameCard/SmallGameCard';
import type { GameChooserImplements } from 'routes/create-review/questionnaire/components/GameChooser/GameChooser';
import type { Node } from 'react';
import { getPlatformsString } from 'helper/platform_names';
import type { InternalGame } from 'typeAliases/backendAliases';
import classes from './GameForm.module.css';

export const TRANS_NO_GAME_FOUND = 'create-review.questionnaire.chooseGame.noGamesFound';
export const TRANS_GAME_SEARCH_ARIA_LABEL = 'create-review.questionnaire.chooseGame.form.gameSearchFormAriaLabel';
export const TRANS_GAME_SELECT_ARIA_LABEL = 'create-review.questionnaire.chooseGame.form.gameChooseFomrArialLabel';
export const TRANS_BACKEND_ALERT_HEADING = 'create-review.questionnaire.chooseGame.backendAlertHeading';
export const TRANS_BACKEND_ALERT_CONTENT = 'create-review.questionnaire.chooseGame.backendAlertContent';
export const TRANS_RESULTS = 'create-review.questionnaire.chooseGame.resultsPar';
export const TRANS_MANY_GAMES_FOUND = 'create-review.questionnaire.chooseGame.manyGamesFound';

type Props = {
  handleGameSearchSubmit: $PropertyType<GameChooserImplements, 'handleGameSearchSubmit'>,
  handleChoseGameFromList: $PropertyType<GameChooserImplements, 'handleChoseGameFromList'>,
  setGameName: $PropertyType<GameChooserImplements, 'setGameName'>,
  loadingGames: $PropertyType<GameChooserImplements, 'loadingGames'>,
  gameCardInfos: $PropertyType<GameChooserImplements, 'gameCardInfos'>,
  gamesFound: $PropertyType<GameChooserImplements, 'gamesFound'>,
  chosenGameListId: $PropertyType<GameChooserImplements, 'chosenGameListId'>,
  setChosenGameListId: $PropertyType<GameChooserImplements, 'setChosenGameListId'>,
  backendAlert: $PropertyType<GameChooserImplements, 'backendAlert'>,
  manyGamesFound: $PropertyType<GameChooserImplements, 'manyGamesFound'>,
  preselectedGame: InternalGame | void,
  gameName: $PropertyType<GameChooserImplements, 'gameName'>,
}

export function GameForm(props: Props) {
  const { t } = useTranslation();
  const [inputInvalid, setInputInvalid] = useState(false);
  const inputRef = useRef<Node | void>();

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    const form = inputRef.current;
    // $FlowIgnore
    if (form.checkValidity() === false) {
      setInputInvalid(true);
    } else {
      setInputInvalid(false);
      props.handleGameSearchSubmit();
    }
  };

  useEffect(() => {
    if (props.preselectedGame) {
      // props.setGameName(props.preselectedGame.name);
      handleSubmit();
    }
  }, []);

  function createGameInfoCards(gameCardInfos) {
    if (!gameCardInfos) return null;
    const infos = gameCardInfos.map((game, index) => (
      <SmallGameCard
        key={game.name + game.igdb_id}
        showButton
        gameName={game.name}
        release={game.first_release_date}
        platformNames={getPlatformsString(game.platforms)}
        gameListId={index}
        setChosenGameListId={props.setChosenGameListId}
        chosenGameListId={props.chosenGameListId}
        handleChoseGameFromList={props.handleChoseGameFromList}
      />
    ));
    return infos;
  }

  return (
    <div>
      {/* Searchfield and submit button */}
      <Form
        aria-label={t(TRANS_GAME_SEARCH_ARIA_LABEL)}
        onSubmit={(e) => handleSubmit(e)}
      >
        <Row className="mb-3">
          <Col xs="12" md="6">
            {/* Searchfield */}
            <Form.Group as={Col} className="mb-3" controlId="formGameName">
              <Form.Label>
                {t(
                  'create-review.questionnaire.chooseGame.form.gameNameInput.label',
                )}
              </Form.Label>
              <Form.Control
                ref={inputRef}
                name={t(
                  'create-review.questionnaire.chooseGame.form.gameNameInput.label',
                )}
                size="lg"
                type="text"
                required
                value={props.gameName}
                isInvalid={inputInvalid}
                onKeyPress={() => 'event.keyCode!=13'} // prevent enter to reload page in safari
                onKeyUp={(e) => {
                  e.preventDefault();
                  props.setGameName(e.target.value);
                  if (e.key === 'Enter') {
                    handleSubmit(e);
                  }
                }}
                onChange={(e) => { // for automated testing to trigger the value change
                  e.preventDefault();
                  props.setGameName(e.target.value);
                }}
                placeholder={t(
                  'create-review.questionnaire.chooseGame.form.gameNameInput.placeholder',
                )}
              />

              <Form.Control.Feedback type="invalid">
                {t('create-review.questionnaire.chooseGame.form.gameNameInput.noInput')}
              </Form.Control.Feedback>
              <Form.Text className="text-muted">
                {t(
                  'create-review.questionnaire.chooseGame.form.gameNameInput.note',
                )}
              </Form.Text>
            </Form.Group>

          </Col>
          <Col xs="12" md="6">

            {/* Submit btn */}
            <Form.Group as={Col} className="mb-3">
              <Form.Label>&nbsp;&nbsp;</Form.Label>
              <Button
                type="submit"
                onSubmit={(e) => handleSubmit(e)}
                aria-label={t('create-review.questionnaire.chooseGame.form.submitBtnAriaText')}
                size="lg"
                variant="primary"
                className={classes.gameSearchButton}
              >
                {t('create-review.questionnaire.chooseGame.form.submit_btn_text')}
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>

      {props.gamesFound}
      {props.gamesFound === true && (
      <p>
        {t(TRANS_RESULTS)}
        :
      </p>
      )}
      {props.gamesFound === false && <p>{t(TRANS_NO_GAME_FOUND)}</p>}

      {/* Loader */}
      <Loader loading={props.loadingGames} />

      {/* Alert */}
      <Alert show={props.backendAlert} variant="warning">
        <Alert.Heading>{t(TRANS_BACKEND_ALERT_HEADING)}</Alert.Heading>
        <p>{t(TRANS_BACKEND_ALERT_CONTENT)}</p>
      </Alert>

      {/* Found Games */}
      {/* <Form aria-label={t(TRANS_GAME_SELECT_ARIA_LABEL)}> */}
      <Row xs={1} md={2} lg={3} xl={3} xxl={4} className="g-4">
        {createGameInfoCards(props.gameCardInfos)}
      </Row>
      {/* </Form> */}
      {props.manyGamesFound && <p>{t(TRANS_MANY_GAMES_FOUND)}</p>}
    </div>
  );
}
