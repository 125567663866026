// @flow
import React, {
  useEffect, createRef, useState, useCallback,
} from 'react';
import SiteNavbarPresn from './SiteNavbarPresn';

export default function SiteNavbar() {
  const [collapsed, setCollapsed] = useState();
  const ref = createRef<{|current: any, |} | void>();

  useEffect(() => {
    window.addEventListener('resize', syncCollapsedNavbar);
    return function cleanup() {
      window.removeEventListener('resize', syncCollapsedNavbar);
    };
  });

  function syncCollapsedNavbar() {
    setCollapsed(isNavbarCollapsed());
  }

  function isNavbarCollapsed() {
    if (
      window.getComputedStyle(ref.current).getPropertyValue('display')
      === 'none'
    ) {
      return true;
    }
    return false;
  }

  const childRendered = useCallback(
    () => {
      syncCollapsedNavbar();
    },
    [ref],
  );

  return (
    <SiteNavbarPresn
      reference={ref}
      collapsedNavbar={collapsed}
      childRendered={childRendered}
    />
  );
}
