// @flow
import React from 'react';
import classes from './SocialShareSiteButton.module.css';

type Props = {
    href: string,
    icon: any,
    label: string,
}

export default function SocialShareSiteButton(props: Props) {
  return (
    <a
      href={props.href}
      target="_blank"
      rel="noreferrer noopener"
      className={classes.link}
    >
      <span className="visually-hidden">{props.label}</span>
      {props.icon}
    </a>
  );
}
