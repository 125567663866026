import React from 'react';
import Heading1 from 'atoms/heading1/Heading1';
import Email from 'atoms/email/Email';
import Address from 'atoms/address/Address';
import Telephone from 'atoms/telephone/Telephone';
import Heading2 from 'atoms/heading2/Heading2';

export default function ImpressumEN() {
  return (
    <>
      <Heading1 heading="Impressum – Legal Notice" />
      <Heading2 heading="Information pursuant to Sect. 5 German Telemedia Act (TMG)" />

      <Address />

      <Heading2 heading="Contact" />
      <p>
        Phone:
        {' '}
        <Telephone />
        <br />
        E-mail:
        {' '}
        <Email />

      </p>

      <Heading2 heading="Person responsible for editorial" />
      <Address />

      <p>
        Source:
        {' '}
        <a
          href="https://www.e-recht24.de"
        >
          eRecht24
        </a>

      </p>

    </>
  );
}
