// @flow
import Heading1 from 'atoms/heading1/Heading1';
import React, { useState, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { Alert, Col } from 'react-bootstrap';
import { encodeAuthentication } from 'helper/backend/backend';
import AxiosWrapper from 'helper/axios/AxiosInstance';
import type { ReviewData } from 'typeAliases/backendAliases';
import { useNavigate } from 'react-router-dom';
import { QUESTIONNAIRE, REVIEW_STATUS } from 'routes/RoutesStrings';

const PASSWORD_LENGTH = 9;
const UNFINISHED_STATUS = 'unfinished';

export default function YourReview() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [reviewID, setReviewID] = useState();
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState();

  const requestReviewData = useCallback(
    () => {
      const authHeader = encodeAuthentication(reviewID, password);
      AxiosWrapper.getAxiosInstance(authHeader)
        .get(process.env.REACT_APP_URL_UPDATE_REVISION)
        .then(async (response) => {
          const reviewData: ReviewData = response.data;
          if (reviewData.status.internal_name === UNFINISHED_STATUS) {
            navigate(QUESTIONNAIRE, {
              state: {
                reviewData,
              },
            });
          } else {
            navigate(REVIEW_STATUS, {
              state: {
                reviewID: reviewData.revision_id,
                status: reviewData.status,
                game: reviewData.reviewed_game,
                platform: reviewData.reviewed_platform.name,
                initialReview: reviewData.initial_revision,
              },
            });
          }
        })
        .catch((error) => {
          let newErrorMsg = t('yourReview.defaultError');
          if (error.response !== undefined) {
            const errorKey = Object.keys(error.response.data);
            if (errorKey) {
              newErrorMsg = error.response.data[errorKey];
            }
          }
          setErrorMsg(newErrorMsg);
        });
    },
    [reviewID, password],
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorMsg(null);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    requestReviewData();
  };

  return (
    <>
      <Heading1 heading={t('yourReview.heading1')} />
      <p>{t('yourReview.description')}</p>

      <Alert
        show={!!errorMsg}
        variant="warning"
        onClose={() => setErrorMsg(null)}
        dismissible
      >
        <Alert.Heading>
          {t('yourReview.errorHeader')}
        </Alert.Heading>
        <p>{errorMsg}</p>
      </Alert>

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} xs="12" md="4" xl="3" controlId="validationCustom01">
            <Form.Label>{t('yourReview.reviewIDLabel')}</Form.Label>
            <Form.Control
              required
              type="number"
              placeholder="ID"
              defaultValue=""
              size="lg"
              onChange={(e) => setReviewID(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">{t('yourReview.invalidID')}</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} xs="12" md="4" xl="3" controlId="validationCustom02">
            <Form.Label>{t('yourReview.passwordLabel')}</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder={t('yourReview.passwordPlaceholder')}
              minLength={PASSWORD_LENGTH}
              maxLength={PASSWORD_LENGTH}
              size="lg"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">{t('yourReview.invalidPassword')}</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Button type="submit" size="lg">{t('yourReview.submitBtn')}</Button>
      </Form>

    </>
  );
}
