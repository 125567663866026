// @flow
import React from 'react';
import Subheading from 'atoms/subheading/Subheading';

type Props = {
    heading: string,
    subheading?: string,
  };

function Heading3(props: Props) {
  return (
    <h3>
      {props.heading}
      <Subheading subheading={props.subheading} />
    </h3>
  );
}

Heading3.defaultProps = {
  subheading: '',
};

export default Heading3;
