// @flow
import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import type { InternalGame } from 'typeAliases/backendAliases';
import type { Node } from 'react';
import ClickableBigGameCard from 'atoms/clickableBigGameCard/ClickableBigGameCard';
import classes from './Carousel.module.css';

type Props = {
  games: Array<InternalGame> | void
}

const Carousel = (props: Props) => {
  const { games } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(0);
  const [showImages, setShowImages] = useState(1);
  const carouselContainer = useRef<?Node>();
  const carouselContent = useRef();

  // Handle screen resizing
  useEffect(() => {
    setShowNr(window.innerWidth);

    function handleResize() {
      setShowNr(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Set the length to match current children from props
  useEffect(() => {
    if (games) {
      setLength(games.length);
    }
  }, [games]);

  const next = useCallback(
    () => {
      if (length && currentIndex < (length - showImages)) {
        scrollToPosition(currentIndex + 1, carouselContainer.current);
      }
    },
    [currentIndex, setCurrentIndex, length, showImages],
  );

  const prev = () => {
    if (currentIndex > 0) {
      scrollToPosition(currentIndex - 1, carouselContainer.current);
    }
  };

  function scrollToPosition(newIndex, element) {
    setCurrentIndex(newIndex);
    const itemWidhtPerc = 1 / (length - showImages);
    const newScrollPerc = newIndex * itemWidhtPerc;
    // $FlowIgnore
    const width = element.scrollWidth - element.clientWidth;
    const newScrollPx = newScrollPerc * width;
    // $FlowIgnore
    const dinstance = newScrollPx - element.scrollLeft;
    window.requestAnimationFrame(
      // $FlowIgnore
      (timestamp) => { scrollAnimation(timestamp, dinstance, element.scrollLeft, newScrollPx); },
    );
  }

  let startTime;
  const ANIMATION_TIME = 200;

  function scrollAnimation(timestamp, dinstance, from, to) {
    if (startTime === undefined) { startTime = timestamp; }
    const elapsed = timestamp - startTime;

    const progress = elapsed / ANIMATION_TIME;
    const newPos = from + dinstance * progress;
    // $FlowIgnore
    carouselContainer.current.scrollLeft = newPos;

    if (elapsed < ANIMATION_TIME) {
      requestAnimationFrame(
        (newTimestamp) => { scrollAnimation(newTimestamp, dinstance, from, to); },
      );
    } else {
      // $FlowIgnore
      carouselContainer.current.scrollLeft = from + dinstance;
    }
  }

  const handleFocus = useCallback(
    (e) => {
      // $FlowIgnore
      const itemIndex = Array.prototype.indexOf.call(
        // $FlowIgnore
        e.target.parentElement.parentElement.childNodes, e.target.parentElement,
      );
      if ((itemIndex) > currentIndex + (showImages - 1)) {
        next();
      } else if (itemIndex < currentIndex) {
        prev();
      }
    },
    [currentIndex, showImages],
  );

  const runOnScroll = useCallback(
    (event) => {
      const pos = event.target.scrollLeft;
      const width = event.target.scrollWidth - event.target.clientWidth;
      const posPerc = pos / width;
      const itemWidhtPerc = 1 / (length - showImages);
      const currItem = Math.round(posPerc / itemWidhtPerc);
      setCurrentIndex(currItem);
    },
    [length, showImages],
  );

  useEffect(() => {
    if (carouselContainer.current === undefined) return undefined;
    // $FlowIgnore
    carouselContainer.current.addEventListener(
      'scroll',
      (e) => runOnScroll(e),
      { passive: true },
    );
    return () => {
      window.removeEventListener('scroll', runOnScroll);
    };
  }, [runOnScroll]);

  function setShowNr(screenWidth: number) {
    if (screenWidth <= 576) {
      setShowImages(1);
    } else if (screenWidth <= 992) {
      setShowImages(2);
    } else if (screenWidth <= 1400) {
      setShowImages(3);
    } else {
      setShowImages(3);
    }
  }

  function getImageClass(showNrImages: number) {
    if (showNrImages === 1) {
      return classes.show1;
    } if (showNrImages === 2) {
      return classes.show2;
    } if (showNrImages === 3) {
      return classes.show3;
    }
    return classes.show4;
  }

  function createCarouselItems(gamesList: Array<InternalGame> | void) {
    if (!gamesList) return null;
    return gamesList.map((game, index) => (
      <ClickableBigGameCard
        key={game.slug}
        game={game}
        handleFocus={handleFocus}
        index={index}
        addMargin
      />
    ));
  }

  return (
    <div className={classes.carouselContainer}>
      <div className={classes.carouselWrapper}>
        {/* You can alwas change the content of the button to other things */}
        {
                    currentIndex > 0
                    && (
                    <button type="button" onClick={prev} className={classes.leftArrow}>
                      &lt;
                    </button>
                    )
                }
        <div
          className={classes.carouselContentWrapper}
          // $FlowIgnore
          ref={carouselContainer}
        >
          <div
            ref={carouselContent}
            className={`${classes.carouselContent} ${getImageClass(showImages)}`}
            // style={{ transform: `translateX(-${currentIndex * (100 / showImages)}%)` }}
          >
            {createCarouselItems(games)}
          </div>
        </div>
        {/* You can alwas change the content of the button to other things */}
        {
                    currentIndex < (length - showImages)
                    && (
                    <button type="button" onClick={next} className={classes.rightArrow}>
                      &gt;
                    </button>
                    )
                }
      </div>
    </div>
  );
};

export default Carousel;
