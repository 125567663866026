// @flow
import React from 'react';
import Subheading from 'atoms/subheading/Subheading';
import type { Node } from 'react';
import classes from './Heading4.module.css';

type Props = {
    heading: string,
    subheading?: string,
    score?: Node,
  };

function Heading4(props: Props) {
  return (
    <h4>
      {props.heading}
      <Subheading subheading={props.subheading} />
      <div className={classes.scoreDiv}>
        {props.score}
      </div>
    </h4>
  );
}

Heading4.defaultProps = {
  subheading: '',
  score: null,
};

export default Heading4;
