// @flow
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as ackeeTracker from 'ackee-tracker';

type Props = {
    ackeeInstance: Object
  }

export default function Ackee(props: Props) {
  const location = useLocation();
  const ackeeAttributes = ackeeTracker.attributes(false);

  let stopAckeeRecord;

  // send Ackee events, when subdomain changed
  useEffect(() => {
    if (!location) return undefined;
    // const unlisten = history.listen(() => {
    if (stopAckeeRecord) {
      stopAckeeRecord();
    }
    const { stop } = props.ackeeInstance
      .record(process.env.REACT_APP_ACKEE_DOMAIN_ID, ackeeAttributes);
    stopAckeeRecord = stop;
    // });
    return () => {
      // unlisten();
      if (stopAckeeRecord) {
        stopAckeeRecord();
      }
    };
  }, [location, ackeeTracker]);
  return (
    <>

    </>
  );
}
