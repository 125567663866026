// @flow
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';
import {
  FaInfoCircle,
  FaQuestionCircle,
  FaChevronCircleRight,
  FaCheckCircle,
  FaTimesCircle,
} from 'react-icons/fa';

import { useTranslation } from 'react-i18next';
import type { MetricExplanation } from 'typeAliases/backendAliases';
import Image from 'atoms/image/Image';
import classes from './MetricModal.module.css';

const ICON_COLOR_MUST = 'text-success';
const ICON_COLOR_MUST_NOT = 'text-danger';
const ICON_COLOR_INFO = 'text-primary';
const ICON_COLOR_EXAMPLE = 'text-warning';

export const EXPLANATION_TYPE = {
  MUST: 'must',
  MUST_NOT: 'must_not',
  INFO: 'info',
  EXAMPLE: 'example',
};

type Props = {
  marginInEM: number, // not required
  paragraphText: string,
  explanationList: Array<MetricExplanation>,
  greyedOut: boolean,
  metricDescription: string,
  gameName: string,
};

export default function MetricModal(props: Props) {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function buildURL(url, gameName) {
    return url + gameName;
  }

  function createElaborationElements(explanations: Array<MetricExplanation>) {
    explanations.sort((a, b) => a.elaboration_type.id - b.elaboration_type.id);
    if (!explanations) return null;
    return explanations.map((explanation: MetricExplanation) => {
      const iconClassNames = `${classes.explanationIcon} ${classes.floatLeft} ${classes.verticalCenter}`;
      let iconElement = (
        <FaCheckCircle className={`${iconClassNames} ${ICON_COLOR_MUST}`} />
      ); // default
      let image;
      if (explanation.elaboration_type.name === EXPLANATION_TYPE.MUST) {
        iconElement = (
          <FaCheckCircle
            className={`${iconClassNames} ${ICON_COLOR_MUST}`}
            aria-label={t('metricModal.altTextIconMust')}
          />
        );
      } else if (explanation.elaboration_type.name === EXPLANATION_TYPE.MUST_NOT) {
        iconElement = (
          <FaTimesCircle
            className={`${iconClassNames} ${ICON_COLOR_MUST_NOT}`}
            aria-label={t('metricModal.altTextIconMustNot')}
          />
        );
      } else if (explanation.elaboration_type.name === EXPLANATION_TYPE.INFO) {
        iconElement = (
          <FaInfoCircle
            className={`${iconClassNames} ${ICON_COLOR_INFO}`}
            aria-label={t('metricModal.altTExtIconInfo')}
          />
        );
      } else if (explanation.elaboration_type.name === EXPLANATION_TYPE.EXAMPLE) {
        image = (
          <Image
            imageData={explanation.image_set[0]}
            whiteText={false}
          />
        );
        iconElement = (
          <FaChevronCircleRight
            className={`${iconClassNames} ${ICON_COLOR_EXAMPLE}`}
            aria-label={t('metricModal.altTextIconExample')}
          />
        );
      }
      return (
        <li key={explanation.metric_elaboration_i18n_set.text} className={classes.noListStyle}>
          <div className={`${classes.flex} ${classes.verticalCenter}`}>
            {iconElement}
            <p
              className={`${classes.textAlingLeft} ${classes.explanationParagraph} ${classes.lineBreakAfter}`}
            >
              {explanation.metric_elaboration_i18n_set.text}
              {explanation.metric_elaboration_i18n_set.url
            && (
            <>
              <a href={buildURL(explanation.metric_elaboration_i18n_set.url.url, props.gameName)} target="_blank" rel="noreferrer">
                {/* $FlowIgnore */}
                {explanation.metric_elaboration_i18n_set.url.display_name}
              </a>
            </>
            )}
            </p>

          </div>
          <div className={classes.imageReference}>
            {image}
          </div>
        </li>
      );
    });
  }

  return (
    <div
      className={`${classes.flex} ${classes.verticalCenter} ${
        props.greyedOut ? classes.greyOverlay : ''
      }`}
    >
      <div className={`${classes.floatLeft} ${classes.verticalCenter} `}>
        <Button
          variant="link"
          aria-label={t('metricModal.activateBtnAriaLabel')}
          onClick={handleShow}
        >
          <FaQuestionCircle as="Button" className={classes.modalOpenIcon} />
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t('metricModal.heading')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>{props.metricDescription}</h5>
            <ul className={classes.noPadding}>
              {createElaborationElements(props.explanationList)}
              {' '}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t('metricModal.closeButton')}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <p
        style={{
          marginTop: `${props.marginInEM}em`,
          marginBottom: `${props.marginInEM}em`,
        }}
        className={`${classes.textAlingLeft}`}
      >
        <button
          className={`${classes.textButton}`}
          type="button"
          onClick={handleShow}
          onKeyPress={handleShow}
        >
          {props.paragraphText}
        </button>
      </p>
    </div>
  );
}

MetricModal.defaultProps = {
  marginInEM: 0.5,
};
