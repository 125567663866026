// @flow
import React from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import classes from './FreeTextAnswerInput.module.css';

type Props = {
    freeText: string,
    setFreeText(text: string): void,
}

export default function FreeTextAnswerInput(props: Props) {
  const { t } = useTranslation();
  return (
    <>
      <FloatingLabel label={t('freeTextModal.title')}>
        <Form.Control
          as="textarea"
          className={classes.textarea}
          maxLength="290"
          value={props.freeText}
          onKeyPress={() => 'event.keyCode!=13'} // prevent enter to reload page in safari
          onChange={(e) => {
            e.preventDefault();
            props.setFreeText(e.target.value);
          }}
        />
      </FloatingLabel>
    </>
  );
}
