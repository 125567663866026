// @flow
import React, { useState, useEffect } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import type { AnswerMetricImplements } from 'components/AnswerMetrics/AnswerMetrics';
import type { ReviewData } from 'typeAliases/backendAliases';
import { FaExchangeAlt, FaBan, FaCheck } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import classes from './MetricNavBar.module.css';

type Props = {
    metricID: number,
    metricName: string,
    initialRevision: $PropertyType<ReviewData, 'initial_revision'>,
    metricAnsers: $PropertyType<AnswerMetricImplements, 'metricAnswers'>,
    notApplicableMetricIDs: $PropertyType<AnswerMetricImplements, 'notApplicableMetricIDs'>,
    freeTextAnswer: string,
}

export default function NavBarSubItem(props: Props) {
  const [itemVariant, setItemVariant] = useState<string>('default');
  const [itemIcon, setItemIcon] = useState(null);
  const [itemName, setItemName] = useState<string>(props.metricName);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.notApplicableMetricIDs.has(props.metricID)) {
      setItemVariant('secondary');
      setItemIcon(<FaBan className={classes.faCheckIcon} />);
      setItemName(`${t('create-review.questionnaire.answerMetrics.navBar.notApplicableName')}. ${props.metricName}`);
    } else if (props.metricID in props.metricAnsers || props.freeTextAnswer) {
      if (props.initialRevision) {
        if (props.metricID in props.metricAnsers) {
          setItemVariant('success');
          setItemIcon(<FaCheck className={classes.faCheckIcon} />);
          setItemName(`${t('create-review.questionnaire.answerMetrics.navBar.answeredName')}. ${props.metricName}`);
        }
      } else {
        setItemVariant('primary');
        setItemIcon(<FaExchangeAlt className={classes.faCheckIcon} />);
        setItemName(`${t('create-review.questionnaire.answerMetrics.navBar.changedName')}. ${props.metricName}`);
      }
    } else {
      setItemVariant('default');
      setItemIcon(null);
      setItemName(props.metricName);
    }
  }, [props.notApplicableMetricIDs, props.metricAnsers, props.freeTextAnswer]);

  return (
    <ListGroup.Item
      variant={itemVariant}
      key={props.metricID}
      action
      metricid={props.metricID}
      eventKey={props.metricID}
      className={classes.ListGroupItemPadding}
      name={itemName}
    >
      {itemIcon}
      {props.metricName}
    </ListGroup.Item>
  );
}
