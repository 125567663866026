// @flow
import Heading1 from 'atoms/heading1/Heading1';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import globalClasses from 'globalCSS/global.module.css';
import type { ReviewData } from 'typeAliases/backendAliases';
import type { AnswerMetricImplements } from 'components/AnswerMetrics/AnswerMetrics';
import ReviewInfo from '../reviewInfo/ReviewInfo';
import classes from './ReviewTopComponent.module.css';

type Props = {
  reviewData: ReviewData,
  lastSaved: $PropertyType<AnswerMetricImplements, 'lastSaved'>,
  savingState: $PropertyType<AnswerMetricImplements, 'savingState'>,
  saveReview: $PropertyType<AnswerMetricImplements, 'saveReview'>,
  showSaveButton: boolean,
}

export default function ReviewTopComponent(props: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Heading1
        heading={t('create-review.questionnaire.heading1')}
        subheading={t('create-review.questionnaire.answerMetrics.subheading')}
      />

      <Row>
        <Col sm={12} md={9}>
          <ReviewInfo reviewData={props.reviewData} />
        </Col>
        {props.showSaveButton ? (
          <Col sm={12} md={3}>
            <Row className={globalClasses.textAlignRight}>
              {props.lastSaved !== undefined && props.lastSaved[0] !== undefined ? (
                <p className={globalClasses.floatRight}>
                  {`${t(
                    'create-review.questionnaire.answerMetrics.lastSavedLabel',
                  )
                  } ${
                    props.lastSaved[0]
                  } ${
                    t(
                      'create-review.questionnaire.answerMetrics.lastSavedLabelUnit',
                    )}`}
                </p>
              ) : null}
            </Row>
            <Button
              variant="secondary"
              size="lg"
              onClick={props.saveReview}
              className={`${globalClasses.floatRight} ${classes.saveButton}`}
            >
              {props.savingState
                ? `${t(
                  'create-review.questionnaire.answerMetrics.saveBtnSavingLabel',
                )}...`
                : t('create-review.questionnaire.answerMetrics.saveBtnLabel')}
            </Button>
          </Col>
        ) : null}
      </Row>
    </>
  );
}
