// @flow
import Heading1 from 'atoms/heading1/Heading1';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import type { Image as ImageData } from 'typeAliases/backendAliases';
import Image from 'atoms/image/Image';
import ScoreDisplay from 'atoms/scoreDisplay/ScoreDisplay';
import BluredBackground from 'atoms/bluredBackground/BluredBackground';
import Heading4 from 'atoms/heading4/Heading4';
import classes from './GameHeader.module.css';

type Props = {
    title: string,
    totalScore: number | null,
    release: string,
    imageData: ImageData | void,
}

export default function GameHeader(props: Props) {
  const { t } = useTranslation();

  return (
    <>

      <BluredBackground
        backgroundImageURL={props.imageData ? props.imageData.image_url : undefined}
        height={10.5}
      />

      <Row className={`${classes.goInForground} ${classes.pagePaddingTop}`}>
        <Col xs="12" md="5" lg="6" xl="7">
          <Row>
            <Col xs="7" md="12" className={classes.headingCol}>
              <div className={classes.heading}>
                <Heading1
                  heading={props.title}
                  marginBottom="0.3em"
                />
                <Heading4 heading={`${t('gamePage.releaseLabel')}: ${props.release}`} />
              </div>
            </Col>
            <Col xs="5" md="12">
              <ScoreDisplay
                totalScore={props.totalScore}
                scale={1.6}
                showTitle
              />
            </Col>
          </Row>
        </Col>
        <Col xs="12" md="7" lg="6" xl="5">
          <Image
            imageData={props.imageData}
          />
        </Col>
      </Row>
    </>
  );
}
