// @flow
import React, { useState, useEffect, useCallback } from 'react';
import type { SearchImplements } from 'routes/search/Search';
import 'externalScripts/doubleValueSlider';

type Props = {
    min: number,
    max: number,
    name: string,
    minName: string,
    maxName: string,
    minValue: number,
    maxValue: number,
    isDate: boolean,
    extraFilterStates: $PropertyType<SearchImplements, 'extraFilterStates'>,
    updateExtraFilterStates: $PropertyType<SearchImplements, 'updateExtraFilterState'>,
}

const FIRST_DAY = '-01-01';
const LAST_DAY = '-12-31';

export default function RangeSlider(props: Props) {
  const [selector, setSelector] = useState();
  const EVENT_NAME = `${props.name}-range-changed`;

  const handleSlide = useCallback(
    (event) => {
      const filterNameArray: Array<string> = [];
      const filterValueArray: Array<string> = [];
      addValueIfNotDefaultValue(filterNameArray, filterValueArray,
        props.minName, event.detail.minRangeValue, FIRST_DAY);
      addValueIfNotDefaultValue(filterNameArray, filterValueArray,
        props.maxName, event.detail.maxRangeValue, LAST_DAY);
      // $FlowIgnore
      props.updateExtraFilterStates(filterNameArray, filterValueArray);
    },
    [props.extraFilterStates],
  );

  function addValueIfNotDefaultValue(filterNameArray: Array<string>,
    filterValueArray: Array<string>, name: string, value: string | number, monthDay: string): void {
    filterNameArray.push(name);
    if (parseInt(value, 10) === parseInt(props.min, 10)
    || parseInt(value, 10) === parseInt(props.max, 10)) {
      filterValueArray.push('');
    } else if (props.isDate) {
      filterValueArray.push(value + monthDay);
    } else if (!Number.isNaN(value)) {
      const valueSet = new Set();
      valueSet.add(value);
      // $FlowIgnore
      filterValueArray.push(valueSet);
    }
  }

  useEffect(() => {
    const initialMinValue = props.minValue ?? props.min;
    const initialMaxValue = props.maxValue ?? props.max;
    setSelector(<range-selector
      id={props.name}
      min-range={props.min}
      max-range={props.max}
      min-label="Minimum"
      max-label="Maximum"
      min-value={initialMinValue}
      max-value={initialMaxValue}
      event-name-to-emit-on-change={EVENT_NAME}
            // $FlowIgnore
      slider-color={getComputedStyle(document.documentElement).getPropertyValue('--bs-primary')}
    />);
  }, [props.extraFilterStates]);

  useEffect(() => {
    window.addEventListener(EVENT_NAME, handleSlide, true);
    return () => {
      window.removeEventListener(EVENT_NAME, handleSlide, true);
    };
  }, [props.extraFilterStates]);

  return (
    <div>
      {selector}
    </div>
  );
}
