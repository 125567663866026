// @flow
import React from 'react';
import Subheading from 'atoms/subheading/Subheading';
import classes from './Heading1.module.css';

type Props = {
  heading: string,
  subheading?: string,
  marginBottom?: string,
};

function Heading1(props: Props) {
  return (
    <h1 className={classes.h1} style={{ marginBottom: props.marginBottom }}>
      {props.heading}
      <Subheading subheading={props.subheading} />
    </h1>
  );
}

Heading1.defaultProps = {
  subheading: '',
  marginBottom: '1em',
};

export default Heading1;
