// @flow
import Heading2 from 'atoms/heading2/Heading2';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { InternalGameReview } from 'typeAliases/backendAliases';
import type { Node } from 'react';
import MetricDisplay from 'components/metricDisplay/MetricDisplay';
import CustomTabs from 'atoms/tabs/CustomTabs';
import classes from './GameReview.module.css';
import GameButtonBar from './components/GameButtonBar';

type Props = {
    game: InternalGameReview
}

export default function GameReview(props: Props) {
  const { t } = useTranslation();
  const [initialTab, setInitialTab] = useState<void | string>();
  const [tabContent, setTabContent] = useState<void | Array<Node>>();
  const [tabHeaders, setTabHeaders] = useState<void | Array<string>>();

  function getTabHeaders(): void | Array<string> {
    const platforms = props.game.game_metric_answer_set;
    if (platforms === undefined) return undefined;
    return platforms.map((platform) => platform.name);
  }

  function getTabContent(): void | Array<Node> {
    const platforms = props.game.game_metric_answer_set;
    if (platforms === undefined) return undefined;
    return platforms.map((platform) => (
      <>
        <GameButtonBar
          internalGame={props.game}
          platformSlug={platform.slug}
        />
        {/*  remove filterUnvaluedGeneralMetrics to not filter unvalued General metrics */}
        <MetricDisplay
          freeTextAnswers={platform.free_text_answers}
          answers={platform.answers}
          changeRequestAnswers={null}
          changeRequestFreeAnswers={null}
          platformTypeID={platform.platform_type}
          categoryScores={platform.category_scores}
          filterUnvaluedGeneralMetrics
          gameName={props.game.name}
        />
        <br />
      </>
    ));
  }

  useEffect(() => {
    setTabContent(getTabContent());
    setTabHeaders(getTabHeaders());
  }, []);

  useEffect(() => {
    if (!tabHeaders) return;
    setInitialTab(tabHeaders[0]);
  }, [tabHeaders]);

  return (
    <>
      <div className={classes.heading}>
        <Heading2 heading={t('gamePage.reviewHeading')} />
      </div>
      <CustomTabs
        tabHeaders={tabHeaders}
        initialTab={initialTab}
        tabContent={tabContent}
      />
    </>
  );
}
