// @flow
import MetricDisplay from 'components/metricDisplay/MetricDisplay';
import React, { useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import globalClasses from 'globalCSS/global.module.css';
import type { AnswerMetricImplements } from 'components/AnswerMetrics/AnswerMetrics';
import type { FreeTextAnswers } from 'typeAliases/backendAliases';
import classes from './ReviewAnswersPage.module.css';

type Props = {
  handleBackToQuestionBtnClick: $PropertyType<AnswerMetricImplements, 'handleBackToQuestionBtnClick'>,
  handleSubmitBtnClick: $PropertyType<AnswerMetricImplements, 'handleSubmitBtnClick'>,
  isInitialReview: boolean,
  submitBtnLoading: $PropertyType<AnswerMetricImplements, 'submitBtnLoading'>,
  newAnswers: $PropertyType<AnswerMetricImplements, 'metricAnswers'>,
  oldAnswers: void | $PropertyType<AnswerMetricImplements, 'metricAnswers'>,
  oldFreeAnswers: FreeTextAnswers,
  newFreeAnswers: FreeTextAnswers,
  notApplicableMetricIDs: $PropertyType<AnswerMetricImplements, 'notApplicableMetricIDs'>,
  reviewedPlatform: number,
  gameName: string,
}

export default function ReviewAnswersPage(props: Props) {
  const { t } = useTranslation();

  const getAnswers = useCallback(
    () => {
      if (props.isInitialReview) {
        return props.newAnswers;
      }
      if (props.oldAnswers !== undefined) {
        return props.oldAnswers;
      }
      return props.newAnswers;
    },
    [props.newAnswers],
  );

  const getFreeAnswers = useCallback(
    () => {
      if (props.isInitialReview) {
        return props.newFreeAnswers;
      }
      if (props.oldFreeAnswers !== undefined) {
        return props.oldFreeAnswers;
      }
      return props.newFreeAnswers;
    },
    [props.newFreeAnswers],
  );

  function getChangeRequestAnswers() {
    if (props.isInitialReview) {
      return null;
    }
    if (props.oldAnswers) {
      const { newAnswers } = props;
      const addedAnswers = addAnswersForNewUnapplicable(props.oldAnswers,
        props.notApplicableMetricIDs);
      const modifiedAnswers = { ...newAnswers, ...addedAnswers };
      return modifiedAnswers;
    }
    return null;
  }

  function getChangeRequestFreeAnswers() {
    if (props.isInitialReview) {
      return null;
    }
    if (props.oldFreeAnswers) {
      const { newFreeAnswers } = props;
      return newFreeAnswers;
    }
    return null;
  }

  function addAnswersForNewUnapplicable(oldAnswers: $PropertyType<AnswerMetricImplements, 'metricAnswers'>,
    notApplicableMetricIDs: Set<number>) {
    const addMetrics = {};
    notApplicableMetricIDs.forEach((metricID) => {
      const metricIDStr = metricID.toString();
      if (oldAnswers[metricIDStr]) addMetrics[metricIDStr] = [];
    });
    return addMetrics;
  }

  return (
    <>
      <MetricDisplay
        answers={getAnswers()}
        freeTextAnswers={getFreeAnswers()}
        changeRequestFreeAnswers={getChangeRequestFreeAnswers()}
        changeRequestAnswers={getChangeRequestAnswers()}
        platformTypeID={props.reviewedPlatform}
        categoryScores={undefined}
        gameName={props.gameName}
        blockStaleAnswersAlert
      />
      <div className={`${classes.buttonMarginTop} ${globalClasses.xxlBottomMargin}`}>
        <Button
          className={globalClasses.buttonMarginRight}
          size="lg"
          variant="secondary"
          onClick={() => props.handleBackToQuestionBtnClick()}
        >
          {t('create-review.questionnaire.answerMetrics.backButton')}
        </Button>
        <Button
          className={globalClasses.buttonMarginRight}
          size="lg"
          variant="primary"
          onClick={() => props.handleSubmitBtnClick()}
          disabled={props.submitBtnLoading}
        >
          {t('create-review.questionnaire.answerMetrics.submitButton')}
        </Button>
      </div>
    </>
  );
}
