import Heading1 from 'atoms/heading1/Heading1';
import Heading3 from 'atoms/heading3/Heading3';
import SmallGameCard from 'atoms/smallGameCard/SmallGameCard';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { AUTHENTICATE_REVIEW, GAMES_ROOT } from 'routes/RoutesStrings';
import {
  FaSmileBeam,
} from 'react-icons/fa';
import StatusBanner from './statusBanner/StatusBanner';
import classes from './ReviewStatus.module.css';

const PROP_REVIEW_ID = 'reviewID';
const PROP_STATUS = 'status';
const PROP_GAME = 'game';
const PROP_REVIEWED_PLATFORM = 'platform';
// const PROP_EXPIRES_IN = "expiresIn";
const PROP_INITIAL_REVIEW = 'initialReview';
const STATUS_APPROVED = 'approved';
const INTERNAL_NAME_KEY = 'internal_name';

const GAME_NAME = 'name';
const RELEASE_DATE = 'release_date';

export default function ReviewStatus() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const SUBHEADING = `${t('reviewStatus.ReviewIdLabel')
  }: ${
    location.state ? location.state[PROP_REVIEW_ID] : ''}`;

  useEffect(() => {
    // if no review id was passed as prop, then this route was navigated to manually by the user
    if (location.state === undefined) {
      navigate(AUTHENTICATE_REVIEW);
    }
  }, [navigate, location.state]);

  function createHeading() {
    if (location.state) {
      if (location.state[PROP_INITIAL_REVIEW]) {
        return (
          <Heading1
            heading={t('reviewStatus.intialReviewHeading')}
            subheading={SUBHEADING}
          />
        );
      }
      return (
        <Heading1
          heading={t('reviewStatus.changeRequestHeading')}
          subheading={SUBHEADING}
        />
      );
    }
    return null;
  }

  return (
    <div>
      {createHeading()}

      <div className={classes.thanksContainer}>
        <div className={classes.smilyDiv}>
          <FaSmileBeam className={classes.smily} />
        </div>
        <div className={classes.thanksDiv}>
          <Heading3 heading={t('reviewStatus.thankYouHeading')} />
          <p>
            {t('reviewStatus.thankYouNote')}
          </p>
        </div>

      </div>

      <div className={classes.headerTopMargin}>
        <Heading3
          className={classes.headerTopMargin}
          heading={`${t('reviewStatus.statusLabel')}:`}
        />
        {location.state ? (
          <StatusBanner status={location.state[PROP_STATUS]} />
        ) : undefined}
      </div>

      <div className={classes.headerTopMargin}>
        <Heading3 heading={`${t('reviewStatus.reviewedGameLabel')}:`} />
        {location.state && location.state[PROP_STATUS][INTERNAL_NAME_KEY] === STATUS_APPROVED ? (
          <NavLink
            tabIndex="0"
            to={`${GAMES_ROOT}/${location.state[PROP_GAME].slug}`}
            className={classes.recentGameLink}
          >
            <SmallGameCard
              gameName={location.state[PROP_GAME][GAME_NAME]}
              release={location.state[PROP_GAME][RELEASE_DATE]}
              platformNames={location.state[PROP_REVIEWED_PLATFORM]}
              cardSelected={false}
            />
          </NavLink>
        ) : undefined }
        {location.state && location.state[PROP_STATUS][INTERNAL_NAME_KEY] !== STATUS_APPROVED ? (
          <SmallGameCard
            gameName={location.state[PROP_GAME][GAME_NAME]}
            release={location.state[PROP_GAME][RELEASE_DATE]}
            platformNames={location.state[PROP_REVIEWED_PLATFORM]}
            cardSelected={false}
          />
        ) : undefined}

      </div>

      {/* For the expires in section, which I decided not to show for now */}
      {/* <div>
        {location.state ? (
          location.state[PROP_EXPIRES_IN] ?
          <p> {t("reviewStatus.expiresInLabel")+": "} location.state[PROP_EXPIRES_IN] </p>
          : undefined
        ): undefined}
      </div> */}
    </div>
  );
}
