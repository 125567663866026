// @flow
import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import type { GameChooserImplements } from 'routes/create-review/questionnaire/components/GameChooser/GameChooser';
import type { ShortPlatform } from 'typeAliases/backendAliases';
import type { Node } from 'react';
import PlatformFormPresn from './PlatformFormPresn';

export type PlatformFormImplements = {
  selectDisabled: boolean,
      createPlatformOptions(): Array<Node> | null,
      handleSelect(any): void,
      selectRef: Node | void,
}

type Props = {
  gameCardInfos: $PropertyType<GameChooserImplements, 'gameCardInfos'>,
  chosenGameListId: $PropertyType<GameChooserImplements, 'chosenGameListId'>,
  setChosenPlatformID: $PropertyType<GameChooserImplements, 'setChosenPlatformID'>,
}

export default function PlatformForm(props: Props) {
  const [selectDisabled, setSelectDisabled] = useState<boolean>(true);
  const [platforms, setPlatforms] = useState<Array<ShortPlatform> | void>();
  const selectRef = useRef<Node | void>();

  const getGamePlatforms = useCallback(() => {
    if (
      props.chosenGameListId !== undefined
      && props.gameCardInfos.length > 0
    ) {
      return props.gameCardInfos[props.chosenGameListId].platforms;
    }
    return [];
  }, [props.chosenGameListId, props.gameCardInfos]);

  useEffect(() => {
    setPlatforms(getGamePlatforms());
    if (
      props.chosenGameListId !== undefined
      && props.gameCardInfos.length > 0
    ) {
      setSelectDisabled(false);
    } else {
      setSelectDisabled(true);
    }
  }, [setPlatforms, props.chosenGameListId, getGamePlatforms, props.gameCardInfos]);

  const handleSelect = useCallback(
    (event) => {
      props.setChosenPlatformID(event.target.value);
    },
    [props.setChosenPlatformID],
  );

  const createPlatformOptions = useCallback(
    () => {
      if (!platforms) return null;
      const infos = platforms.map((platform) => (
        <option key={platform.id} value={platform.id}>
          {platform.name}
        </option>
      ));
      return infos;
    },
    [platforms],
  );

  return (
    <PlatformFormPresn
      selectDisabled={selectDisabled}
      createPlatformOptions={createPlatformOptions}
      handleSelect={handleSelect}
      // $FlowIgnore
      selectRef={selectRef}
    />
  );
}
