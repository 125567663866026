// @flow
import React, { useState, useEffect } from 'react';
import { AnswerMetrics } from 'components/AnswerMetrics/AnswerMetrics';
import type { ReviewData, MetricAnswers } from 'typeAliases/backendAliases';
import { useLocation } from 'react-router-dom';
import PlatformTypeProviderComp from 'contexts/PlatformTypeContext';
import QuestionnaireStates from './consts';
import GameChooser from './components/GameChooser/GameChooser';

export type QuestionaireImplements = {
  setReviewData(ReviewData): void,
  setQuestionnaireState(string): void
}

export default function Questionnaire() {
  const [questionnaireState, setQuestionnaireState] = useState<string>(
    QuestionnaireStates.CHOOSE_GAME,
  );
  const [reviewData, setReviewData] = useState<ReviewData | null>(null);
  const location = useLocation();
  const [savedRevAnswers, setSavedRevAnswers] = useState<MetricAnswers | void>();

  useEffect(() => {
    if (location.state !== null && location.state.reviewData !== undefined) {
      setReviewData(location.state.reviewData);
      setSavedRevAnswers(location.state.reviewData.revision_answers);
      setQuestionnaireState(QuestionnaireStates.ANSWER_METRICS);
    }
  }, []);

  return (
    <div role="main">
      { ((location.state === null
        || location.state.reviewData === undefined)
            && questionnaireState === QuestionnaireStates.CHOOSE_GAME

      ) && (
        <GameChooser
          setQuestionnaireState={setQuestionnaireState}
          setReviewData={setReviewData}
          preselectedGame={location.state && location.state.preselectedGame}
        />
      )}
      {
        (questionnaireState === QuestionnaireStates.ANSWER_METRICS && reviewData !== null)
        // $FlowIgnore
      && (
      <PlatformTypeProviderComp platformTypeID={reviewData.reviewed_platform.platform_type}>
        <AnswerMetrics
          setQuestionnaireState={setQuestionnaireState}
          reviewData={reviewData}
          reviewAnswers={savedRevAnswers}
        />
      </PlatformTypeProviderComp>
      )
}
      {questionnaireState === QuestionnaireStates.REVIEW && true}
    </div>
  );
}
