// @flow
import React, { } from 'react';
import type { Node } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import type { AnswerMetricImplements } from 'components/AnswerMetrics/AnswerMetrics';
import classes from './AnswerProgressBar.module.css';

type Props = {
    initialRevision: boolean,
    progress: $PropertyType<AnswerMetricImplements, 'progress'>,
}

export default function AnswerProgressBar(props: Props) {
  function getProgressInstance(): Node | null {
    if (props.initialRevision) {
      const roundedProgress = Math.round(props.progress);
      return (
        <ProgressBar
          className={classes.marginBottom}
          now={roundedProgress}
          label={`${roundedProgress}%`}
          variant="success"

        />
      );
    }
    return null;
  }

  return (
    <>
      {getProgressInstance()}
    </>
  );
}
