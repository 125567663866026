// @flow
import React, { useEffect, useState, useCallback } from 'react';
import AxiosWrapper from 'helper/axios/AxiosInstance';
import type { InternalGameReview, Platform } from 'typeAliases/backendAliases';
import { useTranslation } from 'react-i18next';
import Heading1 from 'atoms/heading1/Heading1';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import globalClasses from 'globalCSS/global.module.css';
import BigGameCard from 'atoms/bigGameCard/BigGameCard';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useParams } from 'react-router-dom';
import { QUESTIONNAIRE } from 'routes/RoutesStrings';
import { Form } from 'react-bootstrap';
import classes from './UpdateReview.module.css';

const INTERNAL_PLATFORM_ID_KEY = 'internal_platform_id';

// test with hollow-knight and mac
export default function UpdateReview() {
  const { gameName, platformName } = useParams();
  const platformSlug = platformName;
  const [internalGame, setInternalGame] = useState<InternalGameReview | void>();
  const [errorMsg, setErrorMsg] = useState<string | void>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    AxiosWrapper.getAxiosInstance()
      .get(
        // $FlowIgnore
        `${process.env.REACT_APP_URL_GAMES}${gameName}`,
      )
      .then((response) => {
        const tempInternalGame = response.data;
        setInternalGame(tempInternalGame);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setErrorMsg(t('create-review.updateReview.noGameFoundErrorContent'));
        } else {
          setErrorMsg(t('create-review.updateReview.unknownErrorContent'));
        }
      });
  }, []);

  const handleStartBtnClicked = useCallback(
    (e) => {
      e.preventDefault();
      if (internalGame === undefined) return;

      const data = {};
      const platformID = getInternalPlatformIdBySlug(platformSlug, internalGame.platforms);
      // ToDo: If playform == undefined -> redirect to search
      data[INTERNAL_PLATFORM_ID_KEY] = platformID;
      AxiosWrapper.getAxiosInstance()
        .post(
          // $FlowIgnore
          `${process.env.REACT_APP_URL_UPDATE_REVISION}${
            internalGame.id}/`, data,
        )
        .then((response) => {
          const reviewData = response.data;
          navigate(QUESTIONNAIRE, {
            state: {
              reviewData,
            },
          });
        })
        .catch(() => {
          setErrorMsg(t('create-review.updateReview.unknownErrorContent'));
        });
    },
    [platformSlug, internalGame],
  );

  function getInternalPlatformIdBySlug(slug: string, platforms: Array<Platform>): number | void {
    let foundID;
    platforms.forEach((platform) => {
      if (platform.slug === slug) {
        foundID = platform.id;
      }
      return undefined;
    });
    return foundID;
  }

  function getPlatformName(gamePlatformSlug: string) {
    let locPlatformName;
    internalGame?.platforms.forEach((platform) => {
      if (platform.slug === gamePlatformSlug) {
        locPlatformName = platform.name;
      }
    });
    return locPlatformName;
  }

  return (
    <div className={globalClasses.xxlBottomMargin}>
      <Heading1 heading={t('create-review.updateReview.heading1')} />

      <Alert show={errorMsg !== undefined} variant="warning">
        <Alert.Heading>{t('create-review.updateReview.errorHeader')}</Alert.Heading>
        <p>{errorMsg}</p>
      </Alert>

      <p>{t('create-review.updateReview.explanation')}</p>

      <Row>
        <Col xs="12" md="8" lg="6" xl="5">
          {internalGame ? <BigGameCard internalGame={internalGame} /> : null}
        </Col>
      </Row>

      <br />
      <p>
        {t('create-review.updateReview.chosenPlatform')}
        :
        {' '}
        <strong>{getPlatformName(platformSlug)}</strong>
      </p>

      <p className={classes.continueParagraphMargin}>{t('create-review.updateReview.continueParagraph')}</p>

      <Form>
        <Button
          onClick={handleStartBtnClicked}
          type="submit"
          size="lg"
          id="startReviewBtn"
          disabled={errorMsg !== undefined || internalGame === undefined}
          variant="primary"
        >
          {t('create-review.updateReview.startBnLabel')}
        </Button>
      </Form>
    </div>
  );
}
