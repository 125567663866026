import React from 'react';
import classes from './Telephone.module.css';

export default function Telephone() {
  return (
    <>
      <span>+49</span>
      <span className={classes.d0}>test</span>
      <span> eins fün</span>
      <span className={classes.d0}>content</span>
      <span>f sieben dr</span>
      <span className={classes.d0}>alpha</span>
      <span>ei</span>
      <span className={classes.d0}>dog</span>
      <span>ach</span>
      <span className={classes.d0}>games</span>
      <span>t neun vie</span>
      <span className={classes.d0}>easy</span>
      <span>r ein</span>
      <span className={classes.d0}>nice</span>
      <span>s fü</span>
      <span className={classes.d0}>nice</span>
      <span>nf neun </span>
      <span className={classes.d0}>nice</span>
      <span>ach</span>
      <span className={classes.d0}>nice</span>
      <span>t</span>
      <span className={classes.d0}>nice</span>
    </>
  );
}
