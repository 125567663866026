// @flow
import React from 'react';
import type { Metric, MetricChoice } from 'typeAliases/backendAliases';
import type { MetricContextImplements } from 'contexts/MetricContext';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import type { ButtonStates, HandleMetricChoiceBtnClick } from 'typeAliases/frontendAliases';
import AnswerButton from 'atoms/answerButton/AnswerButton';
import classes from './Filter.module.css';

type Props = {
    metric: Metric,
    metricContext: $PropertyType<MetricContextImplements, 'businessData'>,
    appliedFilters: ButtonStates,
    changeFilterValue: HandleMetricChoiceBtnClick,
}

export default function MetricFilter(props: Props) {
  function createInputs(metric: Metric) {
    const anserTypeID = metric.answer_type;
    const possibleAnswers = props.metricContext.answer_sets[anserTypeID].answers;
    return (
      <>
        {/* <ButtonGroup aria-label="Basic example"> */}
        {getAnswerButtons(possibleAnswers)}
        {/* </ButtonGroup> */}
      </>
    );
  }

  function getAnswerButtons(possibleAnswers: Array<MetricChoice>) {
    return possibleAnswers.map((answer: MetricChoice) => (
      <AnswerButton
        key={answer.id}
        buttonStates={props.appliedFilters}
        metric={props.metric}
        metricChoice={answer}
        handleButtonClick={props.changeFilterValue}
      />
      // <Button
      //   className={classes.answerButton}
      //   key={answer.id}
      //   // size="lg"
      //   variant={updateButtonVariant()}
      //   answerid={answer.id}
      //   onClick={handleClick}
      // >
      //   {answer.answer_i18n_set.answer_text}
      // </Button>
    ));
  }

  // if (buttonGroupRef.current !== null) {
  //   buttonGroupRef.current.children[buttonListIndex].setAttribute(
  //     'class',
  //     `btn ${variant}`,
  //   );
  // }

  return (
    <Col xs="10" className={classes.column}>
      <div key={props.metric.id}>
        <Form.Label className={classes.label}>
          {`${props.metric.metric_i18n_set.name}`}
        </Form.Label>
        {createInputs(props.metric)}
      </div>
    </Col>
  );
}
