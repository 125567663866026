// @flow
import React, { useState, useEffect, useCallback } from 'react';
import type { Node } from 'react';
import { useTranslation } from 'react-i18next';
import type { MetricContextImplements } from 'contexts/MetricContext';
import CustomTabs from 'atoms/tabs/CustomTabs';
import type { Metric } from 'typeAliases/backendAliases';
import MetricFilter from 'atoms/filter/MetricFilter';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import globalClasses from 'globalCSS/global.module.css';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import type { ButtonStates, HandleMetricChoiceBtnClick } from 'typeAliases/frontendAliases';
import type { SearchImplements } from 'routes/search/Search';
import classes from './FilterTabs.module.css';
import ExtraFilters from './ExtraFilters';

type Props = {
    metricContext: $PropertyType<MetricContextImplements, 'businessData'>,
    appliedFilters: ButtonStates,
    changeFilterValue: HandleMetricChoiceBtnClick,
    updateGameSearch(): void,
    updateExtraFilterState: $PropertyType<SearchImplements, 'updateExtraFilterState'>,
    extraFilterStates: $PropertyType<SearchImplements, 'extraFilterStates'>,
}

export default function FilterTabs(props: Props) {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [initialTab, setInitialTab] = useState<void | string>();
  const [tabContent, setTabContent] = useState<void | Array<Array<Node>>>();
  const [tabHeaders, setTabHeader] = useState<void | Array<string>>(
  );

  useEffect(() => {
    if (!tabHeaders) return;
    setInitialTab(tabHeaders[0]);
  }, [tabHeaders]);

  const getMetricToTabContent = useCallback(
    (metric: Metric): Node => (
      <MetricFilter
        key={metric.id}
        metric={metric}
        metricContext={props.metricContext}
        appliedFilters={props.appliedFilters}
        changeFilterValue={props.changeFilterValue}
      />
    ),
    [props.appliedFilters],
  );

  const getExtraFilterToTabContent = useCallback(
    (): Node => (
      <ExtraFilters
        extraFilterStates={props.extraFilterStates}
        updateExtraFilterStates={props.updateExtraFilterState}
        metricContext={props.metricContext}
      />
    ),
    [props.appliedFilters, props.extraFilterStates],
  );

  const getMetricsByDisaToTabsContent = useCallback(
    (disaID: number, addExtraFilters: boolean): Array<Node> => {
      const disaTabsContent = [];
      const metricsByDisa = props.metricContext
        .metricsByDisabilty[disaID].sort((a, b) => a.sort_id - b.sort_id);
      const halfLength = Math.ceil(metricsByDisa.length / 2);
      const firstHalf = metricsByDisa.slice(0, halfLength);
      const secondHalf = metricsByDisa.slice(halfLength);
      const tabContentForDisa = (
        <Row className={classes.tabContentRow} key={disaID}>
          <Col xs="12" sm="6" md="6">
            <ul
              className={
                  `${globalClasses.noListStyle} ${classes.localListStyle}`
                }
            >
              {addExtraFilters && getExtraFilterToTabContent()}
              {firstHalf.length > 0
                ? firstHalf.map((metric) => getMetricToTabContent(metric)) : null}
            </ul>
          </Col>
          <Col xs="12" sm="6" md="6">
            <ul
              className={
                  `${globalClasses.noListStyle} ${classes.localListStyle}`
                }
            >
              {secondHalf.length > 0
                ? secondHalf.map((metric) => getMetricToTabContent(metric)) : null}
            </ul>
          </Col>
        </Row>
      );
      disaTabsContent.push(tabContentForDisa);
      return disaTabsContent;
    },
    [props.appliedFilters, props.extraFilterStates],
  );

  const setTabHeadersAndSetTabsContent = useCallback(
    (): void => {
      const disabilityTypes = props.metricContext.disability_types;
      const locTabsContent = [];
      const headers = Object.keys(disabilityTypes)
        .sort((a, b) => disabilityTypes[
          parseInt(a, 10)].sort_id - disabilityTypes[parseInt(b, 10)].sort_id)
        .map((disabilityID, index) => {
          locTabsContent.push(
            getMetricsByDisaToTabsContent(parseInt(disabilityID, 10), index === 0),
          );
          return disabilityTypes[parseInt(disabilityID, 10)]
            .disability_type_i18n_set.name;
        });
      setTabContent(locTabsContent);
      setTabHeader(headers);
    },
    [props.appliedFilters, props.extraFilterStates],
  );

  useEffect(() => {
    setTabHeadersAndSetTabsContent();
  }, [props.appliedFilters, props.extraFilterStates]);

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        className={`${classes.filterButton} me-2`}
      >
        {t('search.filterHeading')}
      </Button>

      <Offcanvas
        className={classes.offcanvas}
        show={show}
        onHide={handleClose}
        placement="bottom"
        onExited={props.updateGameSearch}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {t('search.filterHeading')}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col>
              <CustomTabs
                initialTab={initialTab}
                tabContent={tabContent}
                tabHeaders={tabHeaders}
              />
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
