// @flow
import Heading4 from 'atoms/heading4/Heading4';
import SocialShareSiteButton from 'atoms/socialShareSiteButton/SocialShareSiteButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaFacebookF, FaTwitter, FaLinkedinIn, FaRedditAlien,
} from 'react-icons/fa';
import classes from './SocialShareSiteButtonBar.module.css';

type Props = {
  gameName: string,
  gameSlug: string
}

const URL = 'https://unconditional-gaming.com/games/';

export default function SocialShareSiteButtonBar(props: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Heading4 heading={t('socialSharSiteButtonBar.heading')} />
      <ul className={classes.listStyle}>
        <li>
          <SocialShareSiteButton
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(URL + props.gameSlug)}`}
            icon={<FaLinkedinIn role="img" aria-label={`${t('socialSharSiteButtonBar.ariaLabel')}Linkedin`} />}
            label={`${t('socialSharSiteButtonBar.ariaLabel')}Linkedin`}
          />
        </li>
        <li>
          <SocialShareSiteButton
            href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(URL + props.gameSlug)}&text=${encodeURIComponent(t('metaPageContent.reviewDescription') + props.gameName)}`}
            icon={<FaTwitter role="img" aria-label={`${t('socialSharSiteButtonBar.ariaLabel')}Twitter`} />}
            label={`${t('socialSharSiteButtonBar.ariaLabel')}Twitter`}
          />
        </li>
        <li>
          <SocialShareSiteButton
            href={`http://www.reddit.com/submit?url=${encodeURIComponent(URL + props.gameSlug)}&title=${encodeURIComponent(t('metaPageContent.reviewDescription') + props.gameName)}`}
            icon={<FaRedditAlien role="img" aria-label={`${t('socialSharSiteButtonBar.ariaLabel')}Reddit`} />}
            label={`${t('socialSharSiteButtonBar.ariaLabel')}Reddit`}
          />
        </li>
        <li>
          <SocialShareSiteButton
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(URL + props.gameSlug)}`}
            icon={<FaFacebookF role="img" aria-label={`${t('socialSharSiteButtonBar.ariaLabel')}Facebook`} />}
            label={`${t('socialSharSiteButtonBar.ariaLabel')}Facebook`}
          />
        </li>
      </ul>
    </>
  );
}
