// @flow
import React, { useState, useEffect } from 'react';
import Heading1 from 'atoms/heading1/Heading1';
import { useTranslation } from 'react-i18next';
import { setDescription, setTitle } from 'helper/metaInfo';
import FeedbackForm from './components/feedbackForm/FeedbackForm';
import Success from './success/Success';

export type FeedbackImplements = {
  email: string,
  setEmail(string): void,
  setFormSuccess(boolean): void
}

function Feedback() {
  const [formSuccess, setFormSuccess] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    setTitle(`${t('metaPageContent.shortTitle')}${t('feedback.heading1')}`);
    setDescription(`${t('metaPageContent.defaultContent')}`);
  }, []);

  return (
    <div>
      { !formSuccess
        ? (
          <div role="main">
            <Heading1 heading={t('feedback.heading1')} />
            <p>{t('feedback.description')}</p>
            <FeedbackForm
              setFormSuccess={setFormSuccess}
              email={email}
              setEmail={setEmail}
            />
          </div>
        )
        : <Success email={email} />}
    </div>
  );
}

export default Feedback;
