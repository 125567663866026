/* eslint-disable no-param-reassign */
// @flow
import React, { useState, useEffect } from 'react';
import type { BusinessData, Metric, ReleaseDateSpan } from 'typeAliases/backendAliases';
import type { Node } from 'react';
import AxiosWrapper from 'helper/axios/AxiosInstance';

export const MetricContext = React.createContext({});
export const MetricProvider = MetricContext.Provider;

export type MetricContextImplements = {
    metricsByDisablity: {[disabilityTypeID: number]: Array<Metric>},
    businessData: {'metricsByPlatform': {[platformTypeKey: number]: $PropertyType<MetricContextImplements, 'metricsByDisablity'>},
                  'uniqueMetricsByDisabiltyAndPlatform': {[platformTypeKey: number]: $PropertyType<MetricContextImplements, 'metricsByDisablity'>},
                  'metricsByDisabilty': $PropertyType<MetricContextImplements, 'metricsByDisablity'>,
                  'metricsByID': {[metricID: number]: Metric},
                  'platform_types': $PropertyType<BusinessData, 'platform_types'>,
                  'disability_types': $PropertyType<BusinessData, 'disability_types'>,
                  'answer_sets': $PropertyType<BusinessData, 'answer_sets'>,
                  'fetchMetricData': (language?: string) => void,
                  'ordering_fields': $PropertyType<BusinessData, 'ordering_fields'>,
                  'release_date_span': ReleaseDateSpan,
                  'used_platforms': $PropertyType<BusinessData, 'used_platforms'>,
                  'used_genres': $PropertyType<BusinessData, 'used_genres'>,
  }
}

type Props = {
  children: Node
}

export default function ProviderComponent(props: Props) {
  const [businessData, setBusinessData] = useState<$PropertyType<MetricContextImplements, 'businessData'> | void>();

  useEffect(() => {
    fetchMetricData();
  }, []);

  function fetchMetricData(language?: string) {
    AxiosWrapper.getAxiosInstance(undefined, language)
      .get(
        // $FlowIgnore
        process.env.REACT_APP_URL_METRIC_DATA
        ,
      )
      .then((response) => {
        const metricDataByPlatformType = createCopysByPlatformTypes(response.data);
        metricDataByPlatformType.fetchMetricData = fetchMetricData;
        setBusinessData(metricDataByPlatformType);
      })
      .catch(() => {
      });
  }

  function addMetricToSortedDisabiltyObject(metricsByDis, platformTypeID, disaType, metric) {
    if (metricsByDis[platformTypeID] === undefined) {
      metricsByDis[platformTypeID] = {};
      metricsByDis[platformTypeID][disaType] = [metric];
    } else if (metricsByDis[platformTypeID][disaType] === undefined) {
      metricsByDis[platformTypeID][disaType] = [metric];
    } else {
      metricsByDis[platformTypeID][disaType].push(metric);
    }
  }

  function addMetricToMetricsByDisability(metricsByDis, disaTypeIDs, metric) {
    disaTypeIDs.forEach((disaTypeID) => {
      if (metricsByDis[disaTypeID] === undefined) {
        metricsByDis[disaTypeID] = [];
      }
      metricsByDis[disaTypeID].push(metric);
    });
  }

  function createCopysByPlatformTypes(
    businessDataPar: BusinessData,
  ): $PropertyType<MetricContextImplements, 'businessData'> {
    const newBusinessData: $PropertyType<MetricContextImplements, 'businessData'> = {};
    newBusinessData.platform_types = businessDataPar.platform_types;
    newBusinessData.disability_types = businessDataPar.disability_types;
    newBusinessData.answer_sets = businessDataPar.answer_sets;
    newBusinessData.metricsByID = businessDataPar.metrics;
    newBusinessData.ordering_fields = businessDataPar.ordering_fields;
    newBusinessData.release_date_span = businessDataPar.release_date_span;
    newBusinessData.used_platforms = businessDataPar.used_platforms;
    newBusinessData.used_genres = businessDataPar.used_genres;
    const metricsByPlatform = {};
    const uniqueMetricsByDisabiltyAndPlatform = {};
    const metricsByDisabilty = {};
    Object.keys(businessDataPar.metrics).forEach((metricID: string) => {
      const metric = businessDataPar.metrics[parseInt(metricID, 10)];
      const lowestCategoryID = getOrderFirstCategoryID(metric.disability_types, newBusinessData);
      const secondDisaType = lowestCategoryID;
      addMetricToMetricsByDisability(metricsByDisabilty, metric.disability_types, metric);
      metric.platform_types.forEach((platformTypeID) => {
        // if (metric.disability_types.length > 1) {
        //   // eslint-disable-next-line prefer-destructuring
        //   secondDisaType = metric.disability_types[1];
        // }
        addMetricToSortedDisabiltyObject(uniqueMetricsByDisabiltyAndPlatform,
          platformTypeID, secondDisaType, metric);
        metric.disability_types.forEach((disabilityID) => {
          addMetricToSortedDisabiltyObject(metricsByPlatform,
            platformTypeID, disabilityID, metric);
        });
      });
    });
    newBusinessData.metricsByPlatform = metricsByPlatform;
    newBusinessData.uniqueMetricsByDisabiltyAndPlatform = uniqueMetricsByDisabiltyAndPlatform;
    newBusinessData.metricsByDisabilty = metricsByDisabilty;
    return newBusinessData;
  }

  function getOrderFirstCategoryID(
    categoryIDs: Array<number>,
    newBusinessData: $PropertyType<MetricContextImplements, 'businessData'>,
  ): number {
    let lowestID = categoryIDs[0];
    let lowestSortScore;
    categoryIDs.forEach((id) => {
      let sortScore = getSortScore(id, newBusinessData);
      if (!sortScore) sortScore = 999;
      if (!lowestSortScore) {
        lowestID = id;
        lowestSortScore = sortScore;
      } else if (sortScore <= lowestSortScore) {
        lowestID = id;
        lowestSortScore = sortScore;
      }
    });
    return lowestID;
  }

  function getSortScore(catetoryID: number, newBusinessData: $PropertyType<MetricContextImplements, 'businessData'>): number | void {
    return newBusinessData?.disability_types[catetoryID].sort_id;
  }

  return (
    <MetricContext.Provider value={businessData}>
      {props.children}
    </MetricContext.Provider>
  );
}
