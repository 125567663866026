// @flow
import React, { useState } from 'react';
import type { Node } from 'react';

export const PlatformTypeContext = React.createContext({});
export const PlatformTypeProvider = PlatformTypeContext.Provider;

type Props = {
    platformTypeID: number,
    children: Node
}

export default function ProviderComponent(props: Props) {
  const [platformTypeID] = useState<number>(props.platformTypeID);

  return (
    <PlatformTypeContext.Provider value={platformTypeID}>
      {props.children}
    </PlatformTypeContext.Provider>
  );
}
