// @flow
import React, { useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import type { MetricChoice, Metric } from 'typeAliases/backendAliases';
import type { ButtonStates, HandleMetricChoiceBtnClick } from 'typeAliases/frontendAliases';
import type { Node } from 'react';
import classes from './AnswerButton.module.css';

type Props = {
  metricChoice: MetricChoice,
  buttonStates: ButtonStates,
  handleButtonClick: HandleMetricChoiceBtnClick,
  metric: Metric,
  buttonLabel?: Node,
  isActiveFilterButton?: boolean,
}

function AnswerButton(props: Props) {
  const updateButtonVariant = useCallback(
    () => {
      if (props.buttonStates[props.metric.id] !== undefined
        && props.buttonStates[props.metric.id].has(props.metricChoice.id)) {
        return 'primary';
      }
      return 'outline-primary';
    },
    [props.buttonStates],
  );

  const handleClick = useCallback(
    (event) => {
      const answerID = event.currentTarget.getAttribute('answerid');
      props.handleButtonClick(props.metric.id, parseInt(answerID, 10), props.isActiveFilterButton);
    },
    [props.buttonStates],
  );

  return (

    <Button
      className={classes.answerButton}
      key={props.metricChoice.id}
      // size="lg"
      variant={updateButtonVariant()}
      answerid={props.metricChoice.id}
      onClick={handleClick}
    >
      {props.buttonLabel ? props.buttonLabel : props.metricChoice.answer_i18n_set.answer_text}
    </Button>

  );
}

AnswerButton.defaultProps = {
  buttonLabel: undefined,
  isActiveFilterButton: undefined,
};

export default AnswerButton;
