// @flow
import React from 'react';
import { NavLink } from 'react-router-dom';
import { GAMES_ROOT } from 'routes/RoutesStrings';
import Badge from 'react-bootstrap/Badge';
import type { InternalGame } from 'typeAliases/backendAliases';
import BigGameCard from 'atoms/bigGameCard/BigGameCard';
import classes from './ClickableBigGameCard.module.css';

type Props = {
    index: number,
    game: InternalGame,
    handleFocus?: any,
    addMargin?: boolean,
}

function ClickableBigGameCard(props: Props) {
  return (
    <div key={props.game.slug} className={props.addMargin ? classes.carouselItem : ''}>
      <Badge bg="dark" className={classes.gameIndexBadge}>
        <a type="link" href={`#image-reference-${props.index + 1}`}>
          [
          {props.index + 1}
          ]
        </a>
      </Badge>
      <NavLink
        tabIndex="0"
        to={`${GAMES_ROOT}/${props.game.slug}`}
        className={classes.recentGameLink}
        onFocus={props.handleFocus}
        aria-label={props.game.name}
      >
        <BigGameCard
          internalGame={props.game}
          showPlatforms={false}
          fullHeight
          showScore
        />
      </NavLink>
    </div>
  );
}

ClickableBigGameCard.defaultProps = {
  handleFocus: undefined,
  addMargin: false,
};

export default ClickableBigGameCard;
