/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
// @flow
import React, { useState, useEffect } from 'react';
import type { Node } from 'react';
import ThemeSelectorContext from './ThemeSelectorContext';
import themes from './Themes';

// Source: https://www.freecodecamp.org/news/themes-using-css-variables-and-react-context/

type Props = {
  children: Node
}

function ThemeComponent(props: Props) {
  const [themeName, setThemeName] = useState('light');
  const [theme, setTheme] = useState(themes[themeName]);

  const setCSSVariables = (themeArg) => {
    for (const value in themeArg) {
      // $FlowIgnore
      document.documentElement.style.setProperty(`--${value}`, themeArg[value]);
    }
  };

  useEffect(() => {
    setCSSVariables(theme);
  });

  const toggleTheme = () => {
    if (theme === themes.dark) {
      setTheme(themes.light);
      setThemeName('light');
    } else {
      setTheme(themes.dark);
      setThemeName('dark');
    }
  };

  return (
    <ThemeSelectorContext.Provider value={{ toggleTheme, themeName }}>
      {props.children}
    </ThemeSelectorContext.Provider>
  );
}

export default ThemeComponent;
