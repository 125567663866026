// @flow
import React from 'react';
import type { Node } from 'react';
import { useTranslation } from 'react-i18next';
import Figure from 'react-bootstrap/Figure';
import classes from './ImageReference.module.css';

type Props = {
    gameName: string,
    publisher: string,
    developer: string,
    image_type: string,
    source_url: string,
    font_size?: string,
}

const SCREENSHOT = 'screenshot';
const PRESSMATERIAL = 'pressmaterial';

function ImageReference(props: Props) {
  const { t } = useTranslation();

  function getImageType(): Node {
    if (props.image_type === SCREENSHOT) {
      return (
        <>
          {t('image.screenshot')}
          {' '}
          <a
            href={props.source_url}
            target="_blank"
            rel="noreferrer"
          >
            {props.source_url}
          </a>
        </>
      );
    }
    if (props.image_type === PRESSMATERIAL) {
      return (
        <>
          {t('image.pressmaterial')}
          {props.source_url
          && (
          <>
            {' '}
            {t('image.from')}
            {' '}
            <a
              href={props.source_url}
              target="_blank"
              rel="noreferrer"
            >
              {props.source_url}
            </a>
          </>
          )}
        </>
      );
    }
    return '';
  }

  function getCreators(developer: string, publisher: string): string {
    if (developer === publisher) {
      return developer;
    }
    return `${publisher} & ${developer}`;
  }

  return (
    <Figure.Caption
      className={`${classes.figureCaption}`}
    >
      <div
        className={classes.figureCaptionDiv}
        style={{ fontSize: props.font_size }}
      >
        {props.gameName}
        {' / '}
        {getCreators(props.developer, props.publisher)}
        {' / '}
        {getImageType()}
      </div>
    </Figure.Caption>
  );
}

ImageReference.defaultProps = {
  font_size: '1.2em',
};

export default ImageReference;
