// @flow
import React, {
  useEffect, useState, useContext,
} from 'react';
import { MetricContext } from 'contexts/MetricContext';
import AxiosWrapper from 'helper/axios/AxiosInstance';
import type { InternalGameReview } from 'typeAliases/backendAliases';
import GameHeader from 'components/gameHeader/GameHeader';
import GameOverview from 'components/gameOverview/GameOverview';
import globalClasses from 'globalCSS/global.module.css';
import GameReview from 'components/gameReview/GameReview';
import Loader from 'atoms/loader/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { NOT_FOUND } from 'routes/RoutesStrings';
import IconExplainer from 'atoms/iconExplainer/IconExplainer';
import SocialShareSiteButtonBar from 'components/socialShareSiteButtonsBar/SocialShareSiteButtonBar';
import { useTranslation } from 'react-i18next';
import { setDescription, setTitle } from 'helper/metaInfo';
import classes from './Game.module.css';

export default function Game() {
  const { gameName } = useParams();
  const [internalGame, setInternalGame] = useState<InternalGameReview | void>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const metricContext = useContext(MetricContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (internalGame?.name) {
      setTitle(t('metaPageContent.shortTitle') + internalGame?.name);
      setDescription(`${t('metaPageContent.reviewDescription')}${internalGame?.name}`);
    }
  }, [internalGame]);

  useEffect(() => {
    AxiosWrapper.getAxiosInstance()
      .get(
        // $FlowIgnore
        `${process.env.REACT_APP_URL_GAMES}${gameName}`,
      )
      .then((response) => {
        const tempInternalGame = response.data;
        setInternalGame(tempInternalGame);
      })
      .catch((error) => {
        // console.log('TODO: redirect to search page');
        // console.log(error.response);
        // redirect to search page!

        if (error.response.status === 404) {
          navigate(NOT_FOUND, {
            state: {
              gameNotFound: true,
            },
          });
        }
      });
  }, [gameName, t('current_locale')]);

  useEffect(() => {
    if (metricContext && internalGame) {
      setIsLoading(false);
    }
  }, [metricContext, internalGame]);

  return (
    <div className={globalClasses.xxlBottomMargin}>
      {isLoading
      && (
        <div className={classes.loaderDiv}>
          <Loader loading={isLoading} />
        </div>
      )}
      {!isLoading && internalGame !== undefined
        && (
        <>
          <GameHeader
            title={internalGame.name}
            imageData={internalGame.image_set ? internalGame.image_set[0] : undefined}
            totalScore={internalGame.average_score}
            release={internalGame.release_date}
          />
          <GameOverview
            game={internalGame}
          />
          <GameReview
            game={internalGame}
          />
          <div className={classes.IconExplainerDiv}>
            <IconExplainer />
          </div>
          <SocialShareSiteButtonBar
            gameName={internalGame?.name}
            gameSlug={gameName}
          />
        </>
        )}
    </div>
  );
}
