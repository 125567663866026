// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ReviewData } from 'typeAliases/backendAliases';
import classes from './ReviewInfo.module.css';

type Props = {
  reviewData: ReviewData,
}

export default function ReviewInfo(props: Props) {
  const { t } = useTranslation();

  return (
    <>
      <p className={classes.paragraphShortMargin}>
        {t('create-review.questionnaire.answerMetrics.reviewIDLabel')}
        :
        {' '}
        <b>{props.reviewData.revision_id}</b>
      </p>
      <p className={classes.paragraphShortMargin}>
        {t('create-review.questionnaire.answerMetrics.passwordLabel')}
        :
        {' '}
        <b>{props.reviewData.access_token}</b>
      </p>
      <p>
        <b>
          {' '}
          {t(
            'create-review.questionnaire.answerMetrics.rememberCredentials',
          )}
          {' '}
        </b>
      </p>
      <p className={classes.paragraphLargeMargin}>
        {t('create-review.questionnaire.answerMetrics.gameLabel')}
        :
        {' '}
        {props.reviewData.reviewed_game.name}
        {' '}
        (
        {props.reviewData.reviewed_platform.name}
        )
      </p>
    </>
  );
}
