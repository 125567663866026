// @flow
import React from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  FaAngleLeft, FaAngleRight,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import classes from './PageButtons.module.css';

type Props = {
    nrOfPages: void | number,
    curPage: number,
    setCurPage(newPage: number): void,
}

const MAX_NR_OF_BUTTONS = 5; // must be odd
const BACK_BUTTON_LBL = 'backButton';
const FORWARD_BUTTON_LBL = 'forwardButton';

export default function PageButtons(props: Props) {
  const { t } = useTranslation();

  function createButtons(nrOfPages: void | number, curPage: number) {
    if (!nrOfPages) return null;
    const startAtPage = calcStartAtPage(curPage);
    const buttons = [];
    buttons.push(createBackButtonIfNeeded(props.curPage));
    addPageNumberButtons(buttons, startAtPage, nrOfPages);
    addLastPageButtonIfNeeded(buttons, startAtPage, nrOfPages);
    buttons.push(createForwardButtonIfNeeded(props.curPage, props.nrOfPages));
    return buttons;
  }

  function addPageNumberButtons(
    buttonArray: Array<any>, startAtPage: number, nrOfPages: number,
  ) {
    const nrOfBtnsToDisplay = Math.min(nrOfPages, MAX_NR_OF_BUTTONS);
    for (let i = startAtPage; i < startAtPage + nrOfBtnsToDisplay; i += 1) {
      if (i > nrOfPages) break;
      let variant = 'outline-primary';
      if (i === props.curPage) {
        variant = 'primary';
      }
      buttonArray.push(
        createButton(variant, i, i),
      );
    }
  }

  function addLastPageButtonIfNeeded(
    buttonArray: Array<any>, startAtPage: number, nrOfPages: number,
  ) {
    const endPage = startAtPage + MAX_NR_OF_BUTTONS;
    if (endPage <= nrOfPages) {
      buttonArray.push(
        <p key="buttonDivider" className={classes.buttonDevision}>...</p>,
      );
      buttonArray.push(
        createButton('outline-primary', nrOfPages, nrOfPages),
      );
    }
  }

  function createBackButtonIfNeeded(curPage: number) {
    if (curPage > 1) {
      return createButton('outline-primary', BACK_BUTTON_LBL, <FaAngleLeft />);
    }
    return undefined;
  }

  function createForwardButtonIfNeeded(curPage: number, nrOfPages: void | number) {
    if (nrOfPages && curPage < nrOfPages) {
      return createButton('outline-primary', FORWARD_BUTTON_LBL, <FaAngleRight />);
    }
    return undefined;
  }

  function createButton(variant: string, pageNr: number | string, label: any) {
    const buttonAriaLbl = `${t('search.pageButtonLbl')} ${pageNr}`;
    return (
      <Button
        className={classes.button}
        variant={variant}
        size="lg"
        key={pageNr}
        pagenr={pageNr}
        onClick={clickHandler}
        aria-label={buttonAriaLbl}
      >
        {label}
      </Button>
    );
  }

  function clickHandler(event) {
    const pageNr = event.currentTarget.getAttribute('pagenr');
    if (pageNr === BACK_BUTTON_LBL) props.setCurPage(props.curPage - 1);
    if (pageNr === FORWARD_BUTTON_LBL) props.setCurPage(props.curPage + 1);
    if (Number.isInteger(parseInt(pageNr, 10))) props.setCurPage(parseInt(pageNr, 10));
  }

  function calcStartAtPage(curPage: number) {
    const nrOfBtnsBeforeCurPage = ((MAX_NR_OF_BUTTONS - 1) / 2);
    let startAtPage = curPage - nrOfBtnsBeforeCurPage;
    if (startAtPage < 1) startAtPage = 1;
    return startAtPage;
  }
  return (
    <>
      <Row>
        <Col className={classes.buttonCol}>
          {createButtons(props.nrOfPages, props.curPage)}
        </Col>
      </Row>
    </>
  );
}
