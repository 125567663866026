// @flow
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Heading1 from 'atoms/heading1/Heading1';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import globalClasses from 'globalCSS/global.module.css';
import Alert from 'react-bootstrap/Alert';
import type { InternalGame } from 'typeAliases/backendAliases';
import { GameForm } from './components/gameForm/GameForm';
import PlatformForm from './components/platformForm/PlatformForm';
import classes from './GameChooserPresn.module.css';
import type { GameChooserImplements } from './GameChooser';

const TRANS_GAME_PLATFORM_SEARCH_ARIA_LABEL = 'create-review.questionnaire.chooseGame.selectPlatformLabel';

type Props = {
  handleGameSearchSubmit: $PropertyType<GameChooserImplements, 'handleGameSearchSubmit'>,
  handleChoseGameFromList: $PropertyType<GameChooserImplements, 'handleChoseGameFromList'>,
  setGameName: $PropertyType<GameChooserImplements, 'setGameName'>,
  loadingGames: $PropertyType<GameChooserImplements, 'loadingGames'>,
  gameCardInfos: $PropertyType<GameChooserImplements, 'gameCardInfos'>,
  gamesFound: $PropertyType<GameChooserImplements, 'gamesFound'>,
  chosenGameListId: $PropertyType<GameChooserImplements, 'chosenGameListId'>,
  setChosenGameListId: $PropertyType<GameChooserImplements, 'setChosenGameListId'>,
  backendAlert: $PropertyType<GameChooserImplements, 'backendAlert'>,
  manyGamesFound: $PropertyType<GameChooserImplements, 'manyGamesFound'>,
  setChosenPlatformID: $PropertyType<GameChooserImplements, 'setChosenPlatformID'>,
  submitBtnDisabled: $PropertyType<GameChooserImplements, 'submitBtnDisabled'>,
  handleStartReviewSubmit: $PropertyType<GameChooserImplements, 'handleStartReviewSubmit'>,
  loadingStartReview: $PropertyType<GameChooserImplements, 'loadingStartReview'>,
  backendCreateReviewAlert: $PropertyType<GameChooserImplements, 'backendCreateReviewAlert'>,
  createReviewAltertContent: $PropertyType<GameChooserImplements, 'createReviewAltertContent'>,
  preselectedGame: InternalGame | void,
  gameName: $PropertyType<GameChooserImplements, 'gameName'>,
}

export default function GameChooserPresn(props: Props) {
  const { t } = useTranslation();
  const platformSelectRef = useRef(null);

  // Activate this to enable auto scroll down
  // const scrollToPlatformSelect = () => {
  //   platformSelectRef.current?.scrollIntoView({ behavior: "smooth" })
  // }
  // useEffect(() => {
  //   if(props.chosenGameListId !== "") {
  //     scrollToPlatformSelect()
  //   }
  // })

  return (
    <div>
      <Heading1
        heading={t('create-review.questionnaire.heading1')}
        subheading={t('create-review.questionnaire.chooseGame.heading2')}
      />

      <GameForm
        handleGameSearchSubmit={props.handleGameSearchSubmit}
        handleChoseGameFromList={props.handleChoseGameFromList}
        setGameName={props.setGameName}
        loadingGames={props.loadingGames}
        gamesFound={props.gamesFound}
        gameCardInfos={props.gameCardInfos}
        chosenGameListId={props.chosenGameListId}
        setChosenGameListId={props.setChosenGameListId}
        backendAlert={props.backendAlert}
        manyGamesFound={props.manyGamesFound}
        preselectedGame={props.preselectedGame}
        gameName={props.gameName}
      />

      {/* Selected Game Paragraph */}
      <p ref={platformSelectRef} className={classes.selectedGameLabel}>
        {t('create-review.questionnaire.chooseGame.selectedGame')}
        :
        <b>
          {parseInt(props.chosenGameListId, 10) >= 0
            && props.gameCardInfos !== undefined
            // $FlowIgnore
            && props.gameCardInfos.length > props.chosenGameListId
            // $FlowIgnore
            ? ` ${props.gameCardInfos[props.chosenGameListId].name}`
            : null}
        </b>
      </p>

      <Form
        className={globalClasses.xxlBottomMargin}
        aria-label={t(TRANS_GAME_PLATFORM_SEARCH_ARIA_LABEL)}
        onSubmit={props.handleStartReviewSubmit}
      >
        <PlatformForm
          gameCardInfos={props.gameCardInfos}
          chosenGameListId={props.chosenGameListId}
          setChosenPlatformID={props.setChosenPlatformID}
        />

        {/* Alert */}
        <Alert show={props.backendCreateReviewAlert} variant="warning">
          <Alert.Heading>{props.createReviewAltertContent[0]}</Alert.Heading>
          <p>{props.createReviewAltertContent[1]}</p>
        </Alert>

        <Button
          type="submit"
          id="startReviewBtn"
          size="lg"
          disabled={props.submitBtnDisabled}
          variant="primary"
        >
          {props.loadingStartReview
            ? t('create-review.questionnaire.chooseGame.submitBtnLoading')
            : t('create-review.questionnaire.chooseGame.submitBtn')}
        </Button>
      </Form>
    </div>
  );
}

GameChooserPresn.propTypes = {
  loadingGames: PropTypes.bool.isRequired,
};
