// @flow
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AxiosWrapper, { BASE_URL } from 'helper/axios/AxiosInstance';
import Description from 'atoms/description/Description';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import HeroHeading from 'atoms/heroHeading/HeroHeading';
import BluredBackground from 'atoms/bluredBackground/BluredBackground';
import Carousel from 'components/carousel/Carousel';
import ImageReferencesSection from 'components/imageReferencesSection/ImageReferencesSection';
import type { InternalGame } from 'typeAliases/backendAliases';
import { Link } from 'react-router-dom';
import { FEEDBACK_ROUTE, QUESTIONNAIRE } from 'routes/RoutesStrings';
import { setDescription, setTitle } from 'helper/metaInfo';
import classes from './Home.module.css';

const HERO_IMAGE = 'playstation_5_controller.svg';
const COLLABORATE_IMAGE = 'collaborate.svg';
const CREATE_REVIEW_IAMGE = 'create_review.svg';

function Home() {
  const { t } = useTranslation();
  const [recentGames, setRecentGames] = useState<Array<InternalGame> | void>();

  useEffect(() => {
    setTitle(t('metaPageContent.longTitle'));
    setDescription(`${t('metaPageContent.defaultContent')}`);
  }, []);

  useEffect(() => {
    AxiosWrapper.getAxiosInstance()
    // $FlowIgnore
      .get(`${process.env.REACT_APP_URL_GAMES}?${process.env.REACT_APP_ORDER_PARAM}-created_at&${process.env.REACT_APP_PAGE_PARAM}1`)
      .then((response) => {
        setRecentGames(response.data.results);
      })
      .catch(() => {
      });
  }, []);

  function getBackgroundStyle() {
    // $FlowIgnore
    const firstColor = getComputedStyle(document.documentElement)
      .getPropertyValue('--bs-primary');
      // $FlowIgnore
    const secondColor = getComputedStyle(document.documentElement)
      .getPropertyValue('--bs-accent');
    return `linear-gradient(75deg, ${firstColor}, ${secondColor})`;
  }

  return (
    <div role="main">

      <BluredBackground
        backgroundStyle={getBackgroundStyle()}
        height={18}
      />

      {/* Hero Row */}
      <Row className={classes.presentationRow}>
        <Col xs="12" md="5" xl="6">
          <div className={classes.presentationHeadingDiv}>
            <HeroHeading
              heading={t('home.heading1')}
              mainHeading
            />
          </div>
          <div className={classes.presentationDescriptionDiv}>
            <Description
              i18key="home.presentation.description"
            />
          </div>
        </Col>
        <Col xs="12" md="7" xl="6">
          <img
            // $FlowIgnore
            src={BASE_URL + process.env.REACT_APP_URL_STATIC_IMAGES + HERO_IMAGE}
            alt={t('home.heroImageAlt')}
            className={classes.heroImage}
          />
        </Col>
      </Row>

      {/* New Reviews Row */}
      <Row className={classes.recentReviewRow}>
        <div>
          <HeroHeading
            heading={t('home.recentReviewsHeading')}
            marginBottom="0.1em"
          />
        </div>
        <Carousel
          games={recentGames}
        />
      </Row>

      {/* Contribute Row */}
      <Row className={classes.collaborationRow}>
        <Col xs="12" md="7" xl="6">
          <img
            // $FlowIgnore
            src={BASE_URL + process.env.REACT_APP_URL_STATIC_IMAGES + CREATE_REVIEW_IAMGE}
            alt={t('home.contribute.imageAlt')}
            className={classes.contributeImage}
          />
        </Col>
        <Col xs="12" md="5" xl="6">
          <div className={classes.contributeHeadingDiv}>
            <HeroHeading
              heading={t('home.contribute.heading')}
            />
          </div>
          <Description
            i18key="home.contribute.description"
          />
          <Button
            className={classes.contributeButton}
            as={Link}
            to={QUESTIONNAIRE}
            size="lg"
          >
            {t('home.contribute.buttonLabl')}
          </Button>
        </Col>
      </Row>

      {/* Feedback Row */}
      <Row className={classes.feedbackRow}>
        <Col xs="12" md="5" xl="6">
          <HeroHeading
            heading={t('home.feedback.heading')}
          />
          <Description
            i18key="home.feedback.description"
          />
          <Button
            className={classes.feedbackButton}
            as={Link}
            to={FEEDBACK_ROUTE}
            size="lg"
          >
            {t('home.feedback.buttonLabl')}
          </Button>
        </Col>
        <Col xs="12" md="7" xl="6">
          <img
            // $FlowIgnore
            src={BASE_URL + process.env.REACT_APP_URL_STATIC_IMAGES + COLLABORATE_IMAGE}
            alt={t('home.feedback.imageAlt')}
            className={classes.feedbackImage}
          />
        </Col>
      </Row>

      {/* Image Reference Row */}
      <Row>
        <ImageReferencesSection imageSets={recentGames
          ? recentGames.map((game) => game.image_set[0])
          : undefined}
        />
      </Row>

    </div>
  );
}

export default Home;
