/* eslint-disable no-use-before-define */
// @flow
import React, {
  useState, useEffect, useContext, useCallback,
} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Heading1 from 'atoms/heading1/Heading1';
import Alert from 'react-bootstrap/Alert';
import { useTranslation } from 'react-i18next';
import SearchResultList from 'components/searchResultList/SearchResultList';
import type { InternalGame } from 'typeAliases/backendAliases';
import AxiosWrapper from 'helper/axios/AxiosInstance';
import { useSearchParams } from 'react-router-dom';
import { SEARCH } from 'routes/RoutesStrings';
import type { MetricContextImplements } from 'contexts/MetricContext';
import { MetricContext } from 'contexts/MetricContext';
import ImageReferencesSection from 'components/imageReferencesSection/ImageReferencesSection';
import type { ButtonStates } from 'typeAliases/frontendAliases';
import { ExtraFilterURLParamList } from 'components/filterTabs/ExtraFilters';
import classes from './Search.module.css';
import SearchPhraseForm from './components/searchPhraseForm/SearchPhraseForm';

export type SearchImplements = {
  extraFilterStates: {filterName: Set<number> | string | void},
  updateExtraFilterState(filterNames: Array<string>,
    values: Array<string | Set<number>>, triggerUpdate?: boolean): void,
}

export default function Search() {
  const DEFAULT_ORDER_FIELD = 'created_at';
  const SEARCH_PARAM_ORDER = 'ordering';
  const SEARCH_PARAM_SEARCH_PHRASE = 'search';
  const SEARCH_PARAM_PAGE = 'page';
  const SEARCH_PARAM_METRIC = 'metric';

  const { t } = useTranslation();
  const [orderFields, setOrderFields] = useState<void | Array<string>>();
  const [searchParams] = useSearchParams();
  const [curPage, setCurPage] = useState<number>(getPage(searchParams.get(SEARCH_PARAM_PAGE)));
  const [results, setResults] = useState<void | Array<InternalGame>>();
  const [totalNrOfGames, setTotalNrOfGames] = useState<void | number>();
  const [nrOfPages, setNrOfPages] = useState<void | number>();
  const [selectedOrderField, setSelectedOrderField] = useState<void | string>();
  const [orderDecending, setOrderDecending] = useState<void | boolean>();
  const [errorMsg, setErrorMsg] = useState<void | string>();
  const metricContext = useContext<$PropertyType<MetricContextImplements, 'businessData'>>(MetricContext);
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<ButtonStates>({});
  const [updateGameNeeded, setUpdateGameNeeded] = useState<boolean>(false);
  const [extraFilterStates, setExtraFilterStates] = useState<$PropertyType<SearchImplements, 'extraFilterStates'>>({});

  useEffect(() => {
    if (metricContext) {
      setOrderFields(metricContext.ordering_fields);
    }
  }, [metricContext]);

  const buildStateFromParams = useCallback(
    () => {
      const paramVal = searchParams.get(SEARCH_PARAM_ORDER);
      setInitialOrder(paramVal);
      const searchPhraseParam = searchParams.get(SEARCH_PARAM_SEARCH_PHRASE);
      if (searchPhraseParam) setSearchPhrase(searchPhraseParam);
      buildMetricFilterStates(searchParams);
    },
    [appliedFilters, extraFilterStates, orderDecending, selectedOrderField, orderFields],
  );

  const buildMetricFilterStates = useCallback(
    (locSearchParams) => {
      const newFilterState = {};
      const extraFilterState = {};
      locSearchParams.forEach((paramVal, paramKey) => {
        if (paramKey.startsWith(SEARCH_PARAM_METRIC)) {
          const selectedFilters = JSON.parse(`[${paramVal.replace(/,\s*,/, ',')}]`);
          const metricID = paramKey.split('_')[1];
          newFilterState[metricID] = new Set();
          selectedFilters.forEach((filterID) => {
            newFilterState[metricID].add(filterID);
          });
        } else if (ExtraFilterURLParamList.includes(paramKey)) {
          if (paramVal.split(',').length > 1
          || (!Number.isNaN(paramVal) && paramVal.split('-').length === 1)) {
            const state = new Set();
            paramVal.split(',').forEach((value) => {
              if (Number.isNaN(value)) state.add(value);
              else state.add(parseInt(value, 10));
            });
            extraFilterState[paramKey] = state;
          } else {
            extraFilterState[paramKey] = paramVal;
          }
        }
      });
      setAppliedFilters(newFilterState);
      setExtraFilterStates(extraFilterState);
    },
    [],
  );

  useEffect(() => {
    if (orderFields) {
      buildStateFromParams();
    }
  }, [orderFields]);

  useEffect(() => {
    getGames(searchPhrase, curPage);
  }, [curPage, selectedOrderField, orderDecending]);

  useEffect(() => {
    if (updateGameNeeded) updateGameSearch();
    setUpdateGameNeeded(false);
  }, [updateGameNeeded]);

  function updateUserURL(
    locSearchPhrase: string, locCurPage: number, locSelectedORderField, locOrderDecending,
  ) {
    const baseUrl = SEARCH;
    const fullURL = buildURL(locSearchPhrase,
      locCurPage, locSelectedORderField, locOrderDecending, baseUrl);
    window.history.replaceState(null, '', fullURL);
  }

  function getPage(page): number {
    const pageNr = parseInt(page, 10);
    if (Number.isInteger(pageNr)) {
      return pageNr;
    }
    return 1;
  }

  const setInitialOrder = useCallback((urlOrderParam: string): void => {
    let initialOrder = DEFAULT_ORDER_FIELD;
    if (isOrderParamValidOrderField(urlOrderParam)) {
      if (hasReverseOrderSign(urlOrderParam)) {
        setOrderDecending(false);
        initialOrder = urlOrderParam.substring(1);
      } else {
        setOrderDecending(true);
        initialOrder = urlOrderParam;
      }
    } else {
      setOrderDecending(false);
    }
    setSelectedOrderField(initialOrder);
  }, [orderDecending, selectedOrderField, orderFields]);

  function hasReverseOrderSign(urlOrderParam) {
    if (/^-/.test(urlOrderParam)) {
      return true;
    }
    return false;
  }

  const isOrderParamValidOrderField = useCallback((orderParam): boolean => {
    if (orderParam && orderFields
      && (orderFields.includes(orderParam) || orderFields.includes(orderParam.substring(1)))) {
      return true;
    }
    return false;
  }, [orderFields]);

  const getGames = useCallback(
    (locSearchPhrase: string, pageNr: number): void => {
      if (!selectedOrderField || orderDecending === undefined || pageNr === undefined) return;
      setLoading(true);
      const baseURL = process.env.REACT_APP_URL_GAMES;
      updateUserURL(locSearchPhrase, pageNr, selectedOrderField, orderDecending);
      const url = buildURL(locSearchPhrase, pageNr, selectedOrderField, orderDecending, baseURL);
      AxiosWrapper.getAxiosInstance()
      // $FlowIgnore
        .get(url)
        .then(async (response) => {
          setLoading(false);
          setTotalNrOfGames(response.data.count);
          setNrOfPages(response.data.nrOfPages);
          setResults(response.data.results);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response === undefined) {
            setErrorMsg(t('search.error.unknownBackendError'));
          } else {
            setErrorMsg(error.response.data.detail);
          }
        });
    },
    [selectedOrderField, orderDecending, appliedFilters, extraFilterStates],
  );

  const buildURL = useCallback(
    (
      locSearchPhrase: string | void,
      pageNr: number,
      orderField: string | void,
      newOrderDecending: boolean | void,
      baseURL: void | string,
    ) => {
      let url = baseURL;
      const searchParameter = process.env.REACT_APP_SEARCH_OPTION;
      const pageParameter = process.env.REACT_APP_PAGE_PARAM;
      const orderingParameter = process.env.REACT_APP_ORDER_PARAM;
      if (!url || !searchParameter || !pageParameter || !orderingParameter) {
        setErrorMsg(t('search.errors.cannotStartSearch'));
        return null;
      }
      url += '?';
      if (locSearchPhrase) {
        url += `${searchParameter + locSearchPhrase}&`;
      }
      if (orderField) {
        const urlOrderField = getInvertedOrderFieldIfNeeded(orderField, newOrderDecending);
        url += `${orderingParameter}${urlOrderField}&`;
      }
      url += getURLMetricParams();
      url += getURLExtraFilterParams();
      url += `${pageParameter}${pageNr}`;
      return url;
    },
    [appliedFilters, extraFilterStates],
  );

  const getURLExtraFilterParams = useCallback(
    () => Object.keys(extraFilterStates).map((extraFilterKey) => {
      if (extraFilterStates[extraFilterKey] === undefined) return '';
      // if(typeof extraFilterStates[extraFilterKey] === 'object')
      let paramKey = `${extraFilterKey}=`;
      if (typeof extraFilterStates[extraFilterKey] === 'object') {
        const stateList = Array.from(extraFilterStates[extraFilterKey]);
        if (stateList.length === 0) return '';
        paramKey += stateList.join(',');
      } else {
        paramKey += extraFilterStates[extraFilterKey.toString()];
      }
      return `${paramKey}&`;
    }).join(''),
    [extraFilterStates],
  );

  function getURLMetricParams(): string {
    let params = Object.keys(appliedFilters).map((metricID) => {
      const selectedAnswers = appliedFilters[metricID];
      if (selectedAnswers.size === 0) return '';
      return `metric_${metricID}=${Array.from(selectedAnswers).join(',')}`;
    }).join('&');
    if (params) params += '&';
    return params;
  }

  function getInvertedOrderFieldIfNeeded(orderField, newOrderDecending) {
    if (!newOrderDecending && orderField !== 'name') return `-${orderField}`;
    if (newOrderDecending && orderField === 'name') return `-${orderField}`; // invert name
    return orderField;
  }

  const ifAllMetricFilterSetDeselectAll = useCallback(
    (newAppliedFilter: ButtonStates, changedMetricID: number) => {
      if (!metricContext || !newAppliedFilter[changedMetricID]) return;
      const answerTypeID = metricContext.metricsByID[changedMetricID].answer_type;
      const nrOfAnswers = metricContext.answer_sets[answerTypeID].answers.length;
      if (newAppliedFilter[changedMetricID].size === nrOfAnswers) {
        newAppliedFilter[changedMetricID].clear();
      }
    },
    [metricContext],
  );

  const changeFilterValue = useCallback(
    (metricID: number, clickedAnswerID: number, deleteFilterButton?: boolean): void => {
      let updateGameResults = false;
      const newAppliedFilter = { ...appliedFilters };
      if (metricID in newAppliedFilter) {
        if (newAppliedFilter[metricID].has(clickedAnswerID)) {
          newAppliedFilter[metricID].delete(clickedAnswerID);
          if (newAppliedFilter[metricID].size === 0) {
            delete newAppliedFilter[metricID];
          }
          if (deleteFilterButton) updateGameResults = true;
        } else {
          newAppliedFilter[metricID].add(clickedAnswerID);
        }
      } else {
        newAppliedFilter[metricID] = new Set();
        newAppliedFilter[metricID].add(clickedAnswerID);
      }
      ifAllMetricFilterSetDeselectAll(newAppliedFilter, metricID);
      setAppliedFilters(newAppliedFilter);
      setCurPage(1);
      if (updateGameResults) updateGameSearch();
    },
    [appliedFilters],
  );

  const removeAllFilters = useCallback(
    () => {
      setAppliedFilters({});
      setSearchPhrase('');
      setUpdateGameNeeded(true);
      setExtraFilterStates({});
      setCurPage(1);
    },
    [selectedOrderField, orderDecending, appliedFilters, extraFilterStates],
  );

  const updateGameSearch = useCallback(
    () => {
      getGames(searchPhrase, curPage);
    },
    [selectedOrderField, orderDecending, appliedFilters, searchPhrase, extraFilterStates],
  );

  const resetSearchPhrase = useCallback(
    () => {
      setSearchPhrase('');
      getGames('', curPage);
      setCurPage(1);
    },
    [searchPhrase],
  );

  const updateExtraFilterState = useCallback(
    (filterNames: Array<string>, values: Array<Set<number> | string>, triggerReload?: boolean) => {
      const newFilterState = { ...extraFilterStates };
      filterNames.forEach((filterName, index) => {
        let value = values[index];
        if (value === '') value = undefined;
        newFilterState[filterName] = value;
      });
      setCurPage(1);
      setExtraFilterStates(newFilterState);
      if (triggerReload) setUpdateGameNeeded(true);
    },
    [extraFilterStates],
  );

  return (
    <>
      <Heading1 heading={t('search.heading1')} />
      <SearchPhraseForm
        curPage={curPage}
        getGames={getGames}
        searchPhrase={searchPhrase}
        setSearchPhrase={setSearchPhrase}
        updateGameSearch={updateGameSearch}
        setCurPage={setCurPage}
      />

      <Row>
        <Col>
          {errorMsg
            && (
            <Alert variant="danger">
              {`${t('search.errors.errorRespondedWith')} ${errorMsg}`}
            </Alert>
            )}
        </Col>
      </Row>

      <Row className={classes.searchResultsRow}>
        <Col>
          <SearchResultList
            loading={loading}
            results={results}
            totalNrOfGames={totalNrOfGames}
            nrOfPages={nrOfPages}
            curPage={curPage}
            setCurPage={setCurPage}
            selectedOrderField={selectedOrderField}
            setSelectedOrderField={setSelectedOrderField}
            orderDecending={orderDecending}
            setOrderDecending={setOrderDecending}
            appliedFilters={appliedFilters}
            changeFilterValue={changeFilterValue}
            removeAllFilters={removeAllFilters}
            updateGameSearch={updateGameSearch}
            resetSearchPhrase={resetSearchPhrase}
            searchPhrase={searchPhrase}
            updateExtraFilterState={updateExtraFilterState}
            extraFilterStates={extraFilterStates}
          />
        </Col>
      </Row>

      {/* Image Reference Row */}
      <Row>
        <ImageReferencesSection imageSets={results
          ? results.map((game) => game.image_set[0])
          : undefined}
        />
      </Row>
    </>
  );
}
