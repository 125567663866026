// @flow
import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaHourglassHalf, FaCheck, FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import type { Node } from 'react';
import classes from './StatusBanner.module.css';

const PROP_STATUS = 'status';

const STATUS_TRANS_KEY = 'revision_status_i18n_set';
const STATUS_KEY = 'status';
const REASON_KEY = 'reason';
const INTERNAL_NAME_KEY = 'internal_name';

const STATUS_PENDING = 'submitted';
const STATUS_APPROVED = 'approved';
const STATUS_DENIED_BAD_ANSWERS = 'denied_bad_answers';

export default function StatusBanner(props) {
  const { t } = useTranslation();
  const REASON = props[PROP_STATUS][STATUS_TRANS_KEY][REASON_KEY];
  const STATUS = props[PROP_STATUS][STATUS_TRANS_KEY][STATUS_KEY];
  const INTERNAL_STATUS_NAME = props[PROP_STATUS][INTERNAL_NAME_KEY];

  function getBodyText(): string | null {
    if (REASON) {
      return `${t('bannerReasonLabel')}: ${REASON}`;
    }
    if (INTERNAL_STATUS_NAME === STATUS_PENDING) {
      return t('reviewStatus.bannerSubmittedBody');
    } if (INTERNAL_STATUS_NAME === STATUS_APPROVED) {
      return t('reviewStatus.bannerApprovedBody');
    }
    return null;
  }

  function getBSStyle(): string {
    if (INTERNAL_STATUS_NAME === STATUS_PENDING) {
      return 'primary';
    } if (INTERNAL_STATUS_NAME === STATUS_APPROVED) {
      return 'success';
    } if (INTERNAL_STATUS_NAME === STATUS_DENIED_BAD_ANSWERS) {
      return 'danger';
    }
    return '';
  }

  function getIcon(): Node {
    if (INTERNAL_STATUS_NAME === STATUS_PENDING) {
      return <FaHourglassHalf className={`${classes.statusIcon} text-${getBSStyle()}`} />;
    } if (INTERNAL_STATUS_NAME === STATUS_APPROVED) {
      return <FaCheck className={`${classes.statusIcon} text-${getBSStyle()}`} />;
    } if (INTERNAL_STATUS_NAME === STATUS_DENIED_BAD_ANSWERS) {
      return <FaTimes className={`${classes.statusIcon} text-${getBSStyle()}`} />;
    }
    return <></>;
  }

  return (
    <div>
      <Card border={getBSStyle()}>
        <Card.Body>
          <Row>
            <Col xs="2" lg="1">
              {getIcon()}
              {/* <FaHourglassHalf className={`${classes.statusIcon} text-${getBSStyle()}`} /> */}
            </Col>
            <Col>
              <Card.Title>{STATUS}</Card.Title>
              {getBodyText()}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}
