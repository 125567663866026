// @flow
import ExtraFilter from 'atoms/filter/ExtraFilter';
import React, { useCallback } from 'react';
import type { SearchImplements } from 'routes/search/Search';
import type { MetricContextImplements } from 'contexts/MetricContext';

type Props = {
    metricContext: $PropertyType<MetricContextImplements, 'businessData'>,
    extraFilterStates: $PropertyType<SearchImplements, 'extraFilterStates'>,
    updateExtraFilterStates: $PropertyType<SearchImplements, 'updateExtraFilterState'>,
}

export const ExtraFilterNameList = ['genres', 'platforms', 'release_date', 'accessibility_rating', 'critic_rating'];
export const PossibleAnswerKeys = { genres: 'used_genres', platforms: 'used_platforms' };
export const ExtraFilterURLParamList = [ExtraFilterNameList[0], ExtraFilterNameList[1],
  'release_date_lte', 'release_date_gte',
  'accessibility_rating_lte', 'accessibility_rating_gte',
  'critic_rating_lte', 'critic_rating_gte'];

export default function ExtraFilters(props: Props) {
  const EXTRA_FILTERS = [
    { name: ExtraFilterNameList[0], type: 'button', answers: props.metricContext[PossibleAnswerKeys[ExtraFilterNameList[0]]] },
    { name: ExtraFilterNameList[1], type: 'button', answers: props.metricContext[PossibleAnswerKeys[ExtraFilterNameList[1]]] },
    {
      name: ExtraFilterNameList[2],
      type: 'slider',
      lower_bound: parseInt(props.metricContext.release_date_span.oldest_date.split('-')[0], 10),
      upper_bound: parseInt(props.metricContext.release_date_span.newest_date.split('-')[0], 10),
      isDate: true,
    },
    {
      name: ExtraFilterNameList[3],
      type: 'slider',
      lower_bound: 0,
      upper_bound: 100,
    },
    {
      name: ExtraFilterNameList[4],
      type: 'slider',
      lower_bound: 0,
      upper_bound: 100,
    },
  ];

  const getExtraFilters = useCallback(
    () => EXTRA_FILTERS.map((filter) => {
      let possibleAnswers = [];
      if (filter.answers) possibleAnswers = filter.answers;
      if (filter.upper_bound !== undefined && filter.lower_bound !== undefined) {
        possibleAnswers = [filter.lower_bound, filter.upper_bound];
      }

      return (
        <ExtraFilter
          key={filter.name}
          filterName={filter.name}
          extraFilterStates={props.extraFilterStates}
          // $FlowIgnore
          possibleAnswers={possibleAnswers}
          updateExtraFilterStates={props.updateExtraFilterStates}
          isDate={filter.isDate ? filter.isDate : false}
        />
      );
    }),
    [props.extraFilterStates],
  );
  return (
    <>
      {getExtraFilters()}
    </>
  );
}
