// @flow
import React, { useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import type { SearchImplements } from 'routes/search/Search';
import classes from './AnswerButton.module.css';

type Props = {
 extraFilterStates: $PropertyType<SearchImplements, 'extraFilterStates'>,
 updateExtraFilterStates: $PropertyType<SearchImplements, 'updateExtraFilterState'>,
 answerName: string,
 filterName: string,
 answerID: number,
}

export default function ExtraFilterButton(props: Props) {
  const updateButtonVariant = useCallback(
    () => {
      if (props.extraFilterStates[props.filterName] !== undefined
        && props.extraFilterStates[props.filterName].has(props.answerID)) {
        return 'primary';
      }
      return 'outline-primary';
    },
    [props.extraFilterStates],
  );

  const handleClick = useCallback(
    () => {
      let newFilterValues = props.extraFilterStates[props.filterName];
      if (newFilterValues === undefined) {
        newFilterValues = new Set();
        newFilterValues.add(props.answerID);
      } else if (newFilterValues.has(props.answerID)) {
        newFilterValues.delete(props.answerID);
      } else {
        newFilterValues.add(props.answerID);
      }
      props.updateExtraFilterStates([props.filterName], [newFilterValues]);
    },
    [props.extraFilterStates],
  );

  return (
    <>
      <Button
        className={classes.answerButton}
        key={props.answerName}
        variant={updateButtonVariant()}
        onClick={handleClick}
      >
        {props.answerName}
      </Button>
    </>

  );
}
