// @flow
import React from 'react';
import { Trans } from 'react-i18next';
import classes from './Description.module.css';

type Props = {
  text?: string,
  i18key?: string,
};

function Description(props: Props) {
  return (
    <p className={classes.par}>
      {props.i18key
        ? (
          <Trans i18nKey={props.i18key}>
            {' '}
            <strong />
            <strong />
          </Trans>
        )
        : props.text}

    </p>
  );
}

Description.defaultProps = {
  text: '',
  i18key: undefined,
};

export default Description;
