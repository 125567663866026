// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import Alert from 'react-bootstrap/Alert';
import type { ReviewData } from 'typeAliases/backendAliases';
import { usePrompt } from 'hooks/leavePageEffect';
import ReviewTopComponent from './components/reviewTopComponent/ReviewTopComponent';
import AnswerMetricStates from './consts/States';
import MetricForm from './components/metricForm/MetricForm';
import ReviewAnswersPage from './components/reviewAnswersPage/ReviewAnswersPage';
import type { AnswerMetricImplements } from './AnswerMetrics';

type Props = {
  reviewData: ReviewData,
  saveReview: $PropertyType<AnswerMetricImplements, 'saveReview'>,
  savingState: $PropertyType<AnswerMetricImplements, 'savingState'>,
  lastSaved: $PropertyType<AnswerMetricImplements, 'lastSaved'>,
  savingError: $PropertyType<AnswerMetricImplements, 'savingError'>,
  setSavingError: $PropertyType<AnswerMetricImplements, 'setSavingError'>,
  backendErrorMsg: $PropertyType<AnswerMetricImplements, 'backendErrorMsg'>,
  changeMetricTab: $PropertyType<AnswerMetricImplements, 'changeMetricTab'>,
  requestChangeMetricTab: $PropertyType<AnswerMetricImplements, 'requestChangeMetricTab'>,
  consumeChangeMetricTab: $PropertyType<AnswerMetricImplements, 'consumeChangeMetricTab'>,
  saveMetricAnswer: $PropertyType<AnswerMetricImplements, 'saveMetricAnswer'>,
  metricAnswers: $PropertyType<AnswerMetricImplements, 'metricAnswers'>,
  reviewAccrdionItemEventKey: $PropertyType<AnswerMetricImplements, 'reviewAccrdionItemEventKey'>,
  notApplicableMetricIDs: $PropertyType<AnswerMetricImplements, 'notApplicableMetricIDs'>,
  optionalMetrics: $PropertyType<AnswerMetricImplements, 'optionalMetrics'>,
  progress: $PropertyType<AnswerMetricImplements, 'progress'>,
  currState: $PropertyType<AnswerMetricImplements, 'currState'>,
  handleReviewBtnClick: $PropertyType<AnswerMetricImplements, 'handleReviewBtnClick'>,
  handleBackToQuestionBtnClick: $PropertyType<AnswerMetricImplements, 'handleBackToQuestionBtnClick'>,
  handleSubmitBtnClick: $PropertyType<AnswerMetricImplements, 'handleSubmitBtnClick'>,
  metricsByCategory: $PropertyType<AnswerMetricImplements, 'metricsByCategory'>,
  submitBtnLoading: $PropertyType<AnswerMetricImplements, 'submitBtnLoading'>,
  potentialNotApplicableMetrics: $PropertyType<AnswerMetricImplements, 'potentialNotApplicableMetrics'>,
  reviewedPlatform: number,
  didUserEnterData: $PropertyType<AnswerMetricImplements, 'didUserEnterData'>,
  updateFreeAnswerText: $PropertyType<AnswerMetricImplements, 'updateFreeAnswerText'>,
  freeTextAnswers: $PropertyType<AnswerMetricImplements, 'freeTextAnswers'>,
}

const INITIAL_REVISION = 'initial_revision';

export default function AnswerMetricsPresn(props: Props) {
  const { t } = useTranslation();
  usePrompt(props.didUserEnterData(), `${t('create-review.questionnaire.answerMetrics.leaveQuestionnairePromt')}! Review ID: ${props.reviewData.revision_id}, Access Token: ${props.reviewData.access_token}`);

  const initialRevision = props.reviewData[INITIAL_REVISION];

  return (
    <>
      <ReviewTopComponent
        savingError={props.savingError}
        setSavingError={props.setSavingError}
        reviewData={props.reviewData}
        lastSaved={props.lastSaved}
        savingState={props.savingState}
        saveReview={props.saveReview}
        showSaveButton={
          props.currState === AnswerMetricStates.ANSWER_METRICS
        }
      />

      <Alert
        show={props.savingError}
        variant="warning"
        onClose={() => props.setSavingError(false)}
        dismissible
      >
        <Alert.Heading>
          {t(
            'create-review.questionnaire.answerMetrics.couldNotSaveErrorHeading',
          )}
        </Alert.Heading>
        <p>{props.backendErrorMsg}</p>
      </Alert>

      {props.currState === AnswerMetricStates.ANSWER_METRICS ? (
        <MetricForm
          initialRevision={initialRevision}
          reviewData={props.reviewData}
          changeMetricTab={props.changeMetricTab}
          requestChangeMetricTab={props.requestChangeMetricTab}
          consumeChangeMetricTab={props.consumeChangeMetricTab}
          saveMetricAnswer={props.saveMetricAnswer}
          metricAnswers={props.metricAnswers}
          reviewAccrdionItemEventKey={props.reviewAccrdionItemEventKey}
          notApplicableMetricIDs={props.notApplicableMetricIDs}
          optionalMetrics={props.optionalMetrics}
          progress={props.progress}
          handleReviewBtnClick={props.handleReviewBtnClick}
          metricsByCategory={props.metricsByCategory}
          potentialNotApplicableMetrics={props.potentialNotApplicableMetrics}
          updateFreeAnswerText={props.updateFreeAnswerText}
          freeTextAnswers={props.freeTextAnswers}
        />
      ) : (
        <ReviewAnswersPage
          handleBackToQuestionBtnClick={
            props.handleBackToQuestionBtnClick
          }
          handleSubmitBtnClick={props.handleSubmitBtnClick}
          isInitialReview={initialRevision}
          newAnswers={props.metricAnswers}
          oldAnswers={props.reviewData.previous_revision_answers}
          submitBtnLoading={props.submitBtnLoading}
          notApplicableMetricIDs={props.notApplicableMetricIDs}
          reviewedPlatform={props.reviewedPlatform}
          gameName={props.reviewData.reviewed_game.name}
          oldFreeAnswers={props.reviewData.previous_revision_free_answers}
          newFreeAnswers={props.freeTextAnswers}
        />
      )}
    </>
  );
}
