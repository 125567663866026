// @flow
import Heading2 from 'atoms/heading2/Heading2';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import type { InternalGameReview, Genre, Metric } from 'typeAliases/backendAliases';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import type { Node } from 'react';
import { getPlatformsString } from 'helper/platform_names';
import type { MetricContextImplements } from 'contexts/MetricContext';
import { MetricContext } from 'contexts/MetricContext';
import { getAnswerStrings } from 'components/metricDisplay/MetricDisplay';
import FreeTextAnswerModal from 'atoms/freeTextAnswer/FreeTextAnswerModal';
import classes from './GameOverview.module.css';

type Props = {
    game: InternalGameReview
}

const TYPE_GENERAL_ID = parseInt(process.env.REACT_APP_TYPE_GENEREAL_ID, 10);
const UNVALUED_DIFF_ID = parseInt(process.env.REACT_APP_DIFFICULTY_UNVALUED_ID, 10);
const COMPUTER_PLATFORM_ID = parseInt(process.env.REACT_APP_COMPUTER_PLATFORM_ID, 10);

export default function GameOverview(props: Props) {
  const { t } = useTranslation();
  const businessData = useContext<$PropertyType<MetricContextImplements, 'businessData'>>(MetricContext);

  function getGenreNames(genres: Array<Genre>): string {
    const genreString = genres.map((genre) => genre.name).join(', ');
    return genreString;
  }

  function createInfo(label: string, answer: string | number | null,
    freeTextString: string | void): Node {
    return (
      <Row key={label} className={classes.infoRowMargin}>
        <Col xs="7" sm="5" lg="4">
          {label}
          :
        </Col>
        <Col xs="5" sm="5" lg="5">
          <div className={classes.answerDiv}>
            {answer}
            {freeTextString
            && (
              <div className={classes.answerTextDiv}>
                <FreeTextAnswerModal
                  changed={false}
                  freeText={freeTextString}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    );
  }

  function createUnvaluedGeneralInfos() {
    const originalMetrics = businessData.metricsByPlatform[COMPUTER_PLATFORM_ID];
    const metricData = JSON.parse(JSON.stringify(originalMetrics));
    const unvaluedMetrics = getUnvaluedGeneralMetrics(metricData);
    unvaluedMetrics.sort((a, b) => a.sort_id - b.sort_id);

    const unvaluedMetricNodes: Array<Node> = [];
    unvaluedMetrics.forEach((metric: Metric) => {
      if (props.game.game_metric_answer_set && props.game.game_metric_answer_set.length > 0) {
        if (props.game.game_metric_answer_set
          && props.game.game_metric_answer_set[0]
          && props.game.game_metric_answer_set[0].answers[metric.id.toString()]) {
          const answers = props.game.game_metric_answer_set[0].answers[metric.id.toString()];
          unvaluedMetricNodes.push(createInfo(
            metric.metric_i18n_set.name,
            getAnswerStrings(metric, answers, businessData, t),
            // $FlowIgnore
            props.game.game_metric_answer_set[0].free_text_answers[metric.id.toString()],
          ));
        }
      }
    });
    return unvaluedMetricNodes;
  }

  function getUnvaluedGeneralMetrics(allMetrics): Array<Metric> {
    const generalMetrics = allMetrics[TYPE_GENERAL_ID];
    return generalMetrics.filter(
      (metric) => metric.metric_difficulty === UNVALUED_DIFF_ID,
    );
  }

  return (
    <>
      <div className={classes.heading}>
        <Heading2 heading={t('gamePage.overviewHeading')} />
      </div>
      <Row>
        <Col md="12" lg="6">
          {createInfo(t('gamePage.criticRatingLabel'), props.game.critic_rating)}
          {createInfo(t('gamePage.genresLabel'), getGenreNames(props.game.genres))}
          {createInfo(t('gamePage.platformNamesLabel'), getPlatformsString(props.game.platforms))}
        </Col>
        <Col md="12" lg="6">
          {/* remove this line to not show unvalued general metrics */}
          {createUnvaluedGeneralInfos()}
        </Col>
      </Row>

    </>
  );
}
