// @flow
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { InternalGameReview } from 'typeAliases/backendAliases';
import {
  CREATE_REVIEW, GAME, PLATFORM, QUESTIONNAIRE,
} from 'routes/RoutesStrings';
import classes from './GameButtonBar.module.css';

type Props = {
    internalGame: InternalGameReview,
    platformSlug: string,
}

export default function GameButtonBar(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function handleImproveReviewBtnClick() {
    navigate(`${CREATE_REVIEW}/${GAME}/${props.internalGame.slug}/${PLATFORM}/${props.platformSlug}`);
  }

  function handleCreatePlatformReviewBtnClick() {
    navigate(QUESTIONNAIRE, {
      state: {
        preselectedGame: props.internalGame,
      },
    });
  }

  return (
    <Container>
      <Row className={classes.buttonBarRow}>
        <Col>
          {/* ToDo: Add History Button here later */}
        </Col>
        <Col className="text-end">
          <Row>
            <Col className="mb-2">
              <Button variant="primary" className={`${classes.button}`} onClick={() => { handleImproveReviewBtnClick(); }}>{t('gamePage.gameButtonBar.improveReviewBtn')}</Button>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Button variant="outline-primary" className={`${classes.button}`} onClick={() => { handleCreatePlatformReviewBtnClick(); }}>{t('gamePage.gameButtonBar.createNewReviewBtn')}</Button>
            </Col>
          </Row>
        </Col>

      </Row>
    </Container>
  );
}
