/* eslint-disable react/jsx-props-no-spreading */
// @flow
import React, { } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PRIVACY_POLICY } from 'routes/RoutesStrings';
import Email from 'atoms/email/Email';
import type { FeedbackFormImplements } from './FeedbackForm';

type Props = {
  handleSubmit: $PropertyType<FeedbackFormImplements, 'handleSubmit'>,
  setEmail: $PropertyType<FeedbackFormImplements, 'setEmail'>,
  setSubject: $PropertyType<FeedbackFormImplements, 'setSubject'>,
  setFistName: $PropertyType<FeedbackFormImplements, 'setFirstName'>,
  setLastName: $PropertyType<FeedbackFormImplements, 'setLastName'>,
  setText: $PropertyType<FeedbackFormImplements, 'setText'>,
  showInternalError: $PropertyType<FeedbackFormImplements, 'showInternalError'>,
  serverKeyFeedback: $PropertyType<FeedbackFormImplements, 'serverKeyFeedback'>,
  validated: $PropertyType<FeedbackFormImplements, 'validated'>,
}

export default function FeedbackFormPresn(props: Props) {
  const { t } = useTranslation();

  return (
    <Form noValidate validated={props.validated} onSubmit={props.handleSubmit}>
      <Alert show={props.showInternalError} variant="warning">
        {t('feedback.form.errorMsg')}
      </Alert>

      <Form.Group
        className="mb-3"
        controlId={t('feedback.form.firstName.controlID')}
      >
        <Form.Label>{t('feedback.form.firstName.label')}</Form.Label>
        <Form.Control
          size="lg"
          type="text"
          maxLength="30"
          onChange={(e) => props.setFistName(e.target.value)}
          {...(props.serverKeyFeedback.first_name !== undefined
            ? { isInvalid: true }
            : {})}
          placeholder={t('feedback.form.firstName.placeholder')}
          aria-describedby="feedbackFirstName"
        />
        <Form.Control.Feedback id="feedbackFirstName" type="invalid">
          {props.serverKeyFeedback.first_name}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group
        className="mb-3"
        controlId={t('feedback.form.lastName.controlID')}
      >
        <Form.Label>{t('feedback.form.lastName.label')}</Form.Label>
        <Form.Control
          size="lg"
          type="text"
          maxLength="30"
          onChange={(e) => props.setLastName(e.target.value)}
          {...(props.serverKeyFeedback.last_name !== undefined
            ? { isInvalid: true }
            : {})}
          placeholder={t('feedback.form.lastName.placeholder')}
          aria-describedby="feedbackLastName"
        />
        <Form.Control.Feedback id="feedbackLastName" type="invalid">
          {props.serverKeyFeedback.last_name}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group
        className="mb-3"
        controlId={t('feedback.form.email.controlID')}
      >
        <Form.Label>{t('feedback.form.email.label')}</Form.Label>
        <Form.Control
          size="lg"
          pattern="^[a-zA-Z0-9.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]{2,}$"
          type="email"
          maxLength="100"
          onChange={(e) => props.setEmail(e.target.value)}
          {...(props.serverKeyFeedback.email !== undefined
            ? { isInvalid: true }
            : {})}
          placeholder={t('feedback.form.email.placeholder')}
          aria-describedby="feedbackEmail"
        />
        <Form.Control.Feedback id="feedbackEmail" type="invalid">
          {props.serverKeyFeedback.email === undefined
            ? t('feedback.form.email.invalidDescription')
            : props.serverKeyFeedback.email}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group
        className="mb-3"
        controlId={t('feedback.form.subject.controlID')}
      >
        <Form.Label>{t('feedback.form.subject.label')}</Form.Label>
        <Form.Control
          size="lg"
          type="text"
          maxLength="100"
          onChange={(e) => props.setSubject(e.target.value)}
          {...(props.serverKeyFeedback.subject !== undefined
            ? { isInvalid: true }
            : {})}
          placeholder={t('feedback.form.subject.placeholder')}
          aria-describedby="feedbackSubject"
        />
        <Form.Control.Feedback id="feedbackSubject" type="invalid">
          {props.serverKeyFeedback.subject === undefined
            ? t('feedback.form.subject.invalidDescription')
            : props.serverKeyFeedback.subject}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group
        className="mb-3"
        controlId={t('feedback.form.text.controlID')}
      >
        <Form.Label>{t('feedback.form.text.label')}</Form.Label>
        <Form.Control
          size="lg"
          required
          as="textarea"
          rows={5}
          maxLength="1500"
          onChange={(e) => props.setText(e.target.value)}
          {...(props.serverKeyFeedback.text !== undefined
            ? { isInvalid: true }
            : {})}
          placeholder={t('feedback.form.text.placeholder')}
          aria-describedby="feedbackText"
        />
        <Form.Control.Feedback id="feedbackText" type="invalid">
          {props.serverKeyFeedback.text === undefined
            ? t('feedback.form.text.invalidDescription')
            : props.serverKeyFeedback.text}
        </Form.Control.Feedback>
      </Form.Group>

      <div className="mb-3">
        <Form.Check>
          <Form.Check.Input required type="checkbox" id="consentCheckbox" />
          <Form.Check.Label htmlFor="consentCheckbox">
            <Trans i18nKey="feedback.form.consentCheckbox">
              <Email />
              <Link
                to={PRIVACY_POLICY}
                href="/"
              >
                {t('feedback.privacyPolicyLabel')}
              </Link>
            </Trans>
          </Form.Check.Label>
          <Form.Control.Feedback type="invalid">{t('feedback.form.consentCehckboxInvalidFeedback')}</Form.Control.Feedback>
        </Form.Check>
      </div>

      <Button variant="primary" type="submit" size="lg">
        {t('feedback.form.text.submitBtn')}
      </Button>
    </Form>
  );
}
