import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { setDescription, setTitle } from 'helper/metaInfo';
import ImpressumDE from './language/Impressum_de';
import ImpressumEN from './language/Impressum_en';

const DEFAULT_LANG = process.env.REACT_APP_DEFAULT_LANG;
const GERMAN_LANG = process.env.REACT_APP_GERMAN_LANG;

export default function Impressum() {
  // needed!! This ensures, that translations get switched when languages gets chagned
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();

  useEffect(() => {
    setTitle(`${t('metaPageContent.shortTitle')}Impressum`);
    setDescription(`${t('metaPageContent.defaultContent')}`);
  }, []);

  function getLanguage() {
    return i18n.language
        || (typeof window !== 'undefined' && window.localStorage.i18nextLng)
        || DEFAULT_LANG;
  }

  function renderImpressumLang() {
    if (getLanguage() === GERMAN_LANG) {
      return <ImpressumDE />;
    }
    return <ImpressumEN />;
  }

  return (
    <>
      {renderImpressumLang()}
    </>
  );
}
