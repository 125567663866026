// @flow
import React from 'react';
import type { Node } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './ScoreDisplay.module.css';

type Props = {
    totalScore: number | null,
    scale: number,
    showTitle: boolean,
    marginTop?: string,
}

function ScoreDisplay(props: Props) {
  const STYLE = window.getComputedStyle(document.documentElement);
  const SUCCESS_COLOR = STYLE.getPropertyValue('--bs-success');
  const WARNING_COLOR = STYLE.getPropertyValue('--bs-warning');
  const DANGER_COLOR = STYLE.getPropertyValue('--bs-danger');
  const CIRCLE_RADIUS = 25 * props.scale;
  const CIRCUMFERENCE = CIRCLE_RADIUS * 2 * Math.PI;
  const STROKE_WITH = 5 * props.scale;
  const { t } = useTranslation();

  function createBadge(score: number | null): Node | null {
    let color = DANGER_COLOR;
    let textColor = 'white';

    if (score === null) return null;
    if (score >= 65) {
      color = SUCCESS_COLOR;
      textColor = 'white';
    } else if (score >= 35) {
      color = WARNING_COLOR;
      textColor = 'white';
    } else {
      color = DANGER_COLOR;
      textColor = 'white';
    }

    return (
      <>
        {props.totalScore !== null
      && (
        <div
          className={classes.scoreDisplayContainer}
          style={{ marginTop: props.marginTop }}
        >

          <div className={classes.svgDiv}>
            <svg
              height={2 * CIRCLE_RADIUS + STROKE_WITH}
              width={2 * CIRCLE_RADIUS + STROKE_WITH}
              role="img"
            >
              {/* $FlowIgnore */}
              <title>{t('scoreDisplay.altText') + props.totalScore.toString()}</title>
              <circle
                cx={CIRCLE_RADIUS + STROKE_WITH / 2}
                cy={CIRCLE_RADIUS + STROKE_WITH / 2}
                r={CIRCLE_RADIUS - 1.2 - 4 * props.scale}
                fill={color}
              />

              <circle
                cx={CIRCLE_RADIUS + STROKE_WITH / 2}
                cy={CIRCLE_RADIUS + STROKE_WITH / 2}
                r={CIRCLE_RADIUS}
                strokeWidth={STROKE_WITH}
                stroke="#e7e7e7"
                fill="transparent"
              />

              <circle
                cx={CIRCLE_RADIUS + STROKE_WITH / 2}
                cy={CIRCLE_RADIUS + STROKE_WITH / 2}
                r={CIRCLE_RADIUS}
          /* $FlowIgnore */
                strokeDasharray={`${(CIRCUMFERENCE * 0.01 + CIRCUMFERENCE * (props.totalScore / 100))} ${CIRCUMFERENCE * (1 / ((CIRCUMFERENCE * 0.01 + props.totalScore) / 100))}`}
                strokeWidth={STROKE_WITH}
                stroke={color}
                fill="transparent"
                strokeLinecap="round"
                transform={`rotate(270, ${CIRCLE_RADIUS + STROKE_WITH / 2},${CIRCLE_RADIUS + STROKE_WITH / 2})`}
              />

              <text
                x="50%"
                y="50%"
                fill={textColor}
                fontSize={`${20 * props.scale}px`}
                fontWeight="700"
                textAnchor="middle"
                dominantBaseline="middle"
              >
                {props.totalScore}
              </text>
            </svg>
          </div>
          {props.showTitle
          && (
          <div
            className={`${classes.heading} `}
            // style={{ borderColor: color }}
          >
            {t('scoreDisplay.scoreHeading')}
          </div>
          )}
        </div>
      )}
      </>
    );
  }

  return (
    <>
      {createBadge(props.totalScore)}
    </>
  );
}

ScoreDisplay.defaultProps = {
  marginTop: '1.4em',
};

export default ScoreDisplay;
