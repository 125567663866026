// @flow
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import {
  FaRegCommentDots, FaCommentDots,
} from 'react-icons/fa';
import classes from './FreeTextAnswerModal.module.css';

type Props = {
  freeText: string,
  changed: boolean | void,
}
function FreeTextAnswerModal(props: Props) {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="link" className={classes.modalButton} onClick={handleShow}>
        {props.changed
          && <FaCommentDots className={`${classes.modalButtonIcon} ${classes.chagedTextIcon}`} />}
        {!props.changed
          && <FaRegCommentDots className={`${classes.modalButtonIcon}`} />}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('freeTextModal.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.freeText}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

FreeTextAnswerModal.defaultProps = {
  changed: false,
};

export default FreeTextAnswerModal;
