// @flow
import React from 'react';
import classes from './BluredBackground.module.css';

type Props = {
    backgroundImageURL?: string,
    backgroundStyle?: any,
    height: number,
}

function BluredBackground(props: Props) {
  return (
    <div
      className={classes.backgroundBoxParent}
      style={{ height: `${parseInt(props.height, 10) + 20}em` }}
    >
      {props.backgroundImageURL
        ? (
          <div
            style={{ backgroundImage: `url(${props.backgroundImageURL})`, height: `${props.height}em` }}
            className={classes.backgroundBox}
          />
        )
        : null}
      {props.backgroundStyle
        ? (
          <div
            style={{ background: props.backgroundStyle, height: `${props.height}em` }}
            className={classes.backgroundBox}
          />
        )
        : null}
    </div>
  );
}

BluredBackground.defaultProps = {
  backgroundImageURL: undefined,
  backgroundStyle: undefined,
};

export default BluredBackground;
