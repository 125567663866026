// @flow
import React, { useState, useCallback } from 'react';
import AxiosWrapper from 'helper/axios/AxiosInstance';
import type { FeedbackImplements } from 'routes/feedback/Feedback';
import FeedbackFormPresn from './FeedbackFormPresn';

const RESTKEY_EMAIL = 'email';
const RESTKEY_SUBJECT = 'subject';
const RESTKEY_FIRST_NAME = 'first_name';
const RESTKEY_LAST_NAME = 'last_name';
const RESTKEY_TEXT = 'text';
const ERRORDELAYTIME = 500;

export type FeedbackFormImplements = {
  handleSubmit(any): void,
  setEmail(string): void,
  setSubject(string): void,
  setFirstName(string): void,
  setLastName(string): void,
  setText(string): void,
  showInternalError: boolean,
  serverKeyFeedback: { [key: string]: string },
  validated: boolean
}

type Props = {
  email: $PropertyType<FeedbackImplements, 'email'>,
  setEmail: $PropertyType<FeedbackImplements, 'setEmail'>,
  setFormSuccess: $PropertyType<FeedbackImplements, 'setFormSuccess'>,
}

export default function FeedbackForm(props: Props) {
  const [subject, setSubject] = useState<string | void>();
  const [text, setText] = useState<string | void>();
  const [firstName, setFirstName] = useState<string | void>();
  const [lastName, setLastName] = useState<string | void>();
  const [showInternalError, setInternalShowError] = useState<boolean>(false);
  const [serverKeyFeedback, setServerKeyFeedback] = useState<{ [key: string]: string }>({});
  const [validated, setValidated] = useState<boolean>(false);

  const handleSubmit = useCallback((event: any) => {
    event.preventDefault();

    if (event.currentTarget.checkValidity() === false) {
      setValidated(true);
      return;
    }
    setValidated(false);

    setInternalShowError(false);

    const feedbackJSON = {};
    feedbackJSON[RESTKEY_EMAIL] = props.email;
    feedbackJSON[RESTKEY_SUBJECT] = subject;
    feedbackJSON[RESTKEY_TEXT] = text;
    feedbackJSON[RESTKEY_FIRST_NAME] = firstName;
    feedbackJSON[RESTKEY_LAST_NAME] = lastName;

    AxiosWrapper.getAxiosInstance()
      .post(process.env.REACT_APP_EXTERNAL_FEEDBACK, feedbackJSON)
      .then(() => {
        props.setFormSuccess(true);
      })
      .catch((error) => {
        if (error.response === undefined) {
          showInternalErrorMsg();
        } else if (error.response.status === 400) {
          showServerInputMsgs(error.response.data);
        } else {
          showInternalErrorMsg();
        }
      });
  }, [subject, text, props.email]);

  async function showInternalErrorMsg() {
    await new Promise((resolve) => setTimeout(resolve, ERRORDELAYTIME));
    setInternalShowError(true);
  }

  function showServerInputMsgs(data) {
    const feedback = {};
    Object.entries(data).map((entry) => {
      const key = entry[0];
      const value = entry[1];
      feedback[key] = value;
      return undefined;
    });
    setServerKeyFeedback(feedback);
  }

  return (
    <FeedbackFormPresn
      handleSubmit={handleSubmit}
      setEmail={props.setEmail}
      setFistName={setFirstName}
      setLastName={setLastName}
      setSubject={setSubject}
      setText={setText}
      showInternalError={showInternalError}
      serverKeyFeedback={serverKeyFeedback}
      validated={validated}
    />
  );
}
