// @flow
import React, { useEffect, useCallback, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import LanguagePicker from 'components/siteNavbar/components/languagePicker/LanguagePicker';
import { useTranslation } from 'react-i18next';
import {
  FEEDBACK_ROUTE, QUESTIONNAIRE, SEARCH, YOUR_REVIEW,
} from 'routes/RoutesStrings';
import { FaBars } from 'react-icons/fa';
import classes from './SiteNavbar.module.css';

import NavbarForm from './components/navbarForm/NavbarForm';

type Props = {
  childRendered(): void,
  collapsedNavbar: boolean | void,
  reference: {|current: any, |}
}

function SiteNavbarPresn(props: Props) {
  const { t } = useTranslation();
  const [navbarExpanded, setNavbarExpanded] = useState(false);

  useEffect(() => {
    props.childRendered();
  });

  const handleToggle = useCallback(
    () => {
      setNavbarExpanded(!navbarExpanded);
    },
    [navbarExpanded],
  );

  function getNavLinkClassNames() {
    return `${classes.custNavLink} ${props.collapsedNavbar ? '' : classes.linkItemCollapsed}`;
  }

  return (
    <header>
      <Navbar
        className={classes.navbar}
        onToggle={handleToggle}
        expanded={navbarExpanded}
        collapseOnSelect
        bg="dark"
        variant="dark"
        expand="xl"
      >
        <Container>
          <Navbar.Brand as={Link} to="/" className={classes.brandNameDiv}>
            {process.env.REACT_APP_WEBSITE_NAME}
          </Navbar.Brand>
          {props.collapsedNavbar ? ''
            : (
              <div className={props.collapsedNavbar
                ? ''
                : `${classes.LanguagePickerContainer} ${classes.alignRightFlex}`}
              >
                <LanguagePicker />
              </div>
            )}
          <Navbar.Toggle
            ref={props.reference}
            className={classes.toggleButton}
            aria-controls="responsive-navbar-nav"
          >
            <FaBars />
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <div className={`${props.collapsedNavbar ? '' : classes.navbarFormContainer} ${classes.searchBarDiv} `}>
                <NavbarForm collapsedNavbar={props.collapsedNavbar} />
              </div>
              <Nav.Link
                className={
                  getNavLinkClassNames()
                }
                as={Link}
                to={SEARCH}
                href="/"
              >
                {`${t('search.heading1')}`}
              </Nav.Link>
              <Nav.Link
                className={
                  getNavLinkClassNames()
                }
                as={Link}
                to={FEEDBACK_ROUTE}
                href="/"
              >
                {t('feedback.heading1')}
              </Nav.Link>
              <Nav.Link
                className={
                  getNavLinkClassNames()
                }
                as={Link}
                to={YOUR_REVIEW}
                href="/"
              >
                {t('yourReview.heading1')}
              </Nav.Link>
              <Nav.Link
                className={
                  getNavLinkClassNames()
                }
                as={Link}
                to={QUESTIONNAIRE}
                href="/"
              >
                {`+ ${t('create-review.questionnaire.heading1')}`}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          {props.collapsedNavbar
            ? (
              <div className={props.collapsedNavbar
                ? classes.alignRightFlex
                : ''}
              >
                <LanguagePicker />
              </div>
            ) : ''}
        </Container>
      </Navbar>
    </header>
  );
}

export default SiteNavbarPresn;
