// @flow
import Heading2 from 'atoms/heading2/Heading2';
import React, { useCallback, useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import MetricModal from 'atoms/metricModal/MetricModal';
import globalClasses from 'globalCSS/global.module.css';
import type { AnswerMetricImplements } from 'components/AnswerMetrics/AnswerMetrics';
import type { Metric, ReviewData } from 'typeAliases/backendAliases';
import { MetricContext } from 'contexts/MetricContext';
import type { MetricContextImplements } from 'contexts/MetricContext';
import FreeTextAnswerInput from 'atoms/freeTextAnswer/FreeTextAnswerInput';
import AnswerButtonGroup from '../answerButtonGroup/AnswerButtonGroup';
import classes from './MetricTab.module.css';

type Props = {
  requestChangeMetricTab: $PropertyType<AnswerMetricImplements, 'requestChangeMetricTab'>,
  metric: Metric,
  saveMetricAnswer: $PropertyType<AnswerMetricImplements, 'saveMetricAnswer'>,
  notApplicable: boolean,
  metricAnswers: Array<number>,
  prevRevAnswers: Array<number>,
  gameName: string,
  updateFreeAnswerText: $PropertyType<AnswerMetricImplements, 'updateFreeAnswerText'>,
  freeTextAnswers: $PropertyType<AnswerMetricImplements, 'freeTextAnswers'>,
  reviewData: ReviewData,
}

export default function MetricTabPresn(props: Props) {
  const metricContext = useContext<$PropertyType<MetricContextImplements, 'businessData'>>(MetricContext);
  const { t } = useTranslation();

  function _getTitle(): string {
    const optionalString = !props.metric.mandatory
      && props.metric.depending_metric_answer_set.length === 0
      ? `(${
        t('create-review.questionnaire.answerMetrics.optionalMetricTitle')
      })`
      : '';
    return `${props.metric.metric_i18n_set.name} ${optionalString}`;
  }

  const updateFreeText = useCallback((text: string) => {
    props.updateFreeAnswerText(props.metric.id.toString(), text);
  }, [props.freeTextAnswers]);

  const getFreeAnswer = useCallback(() => {
    const curAnswer = props.freeTextAnswers[props.metric.id.toString()];
    if (curAnswer !== undefined) return curAnswer;
    const prevAnswers = props.reviewData.previous_revision_free_answers;
    if (prevAnswers !== undefined) return prevAnswers[props.metric.id.toString()];
    return '';
  }, [props.freeTextAnswers]);

  return (
    <>
      <Heading2
        heading={_getTitle()}
        subheading={
          props.metric.disability_types
          && props.metric.disability_types.map((disabilityID) => metricContext.disability_types[disabilityID].disability_type_i18n_set.name).join(', ')
        }
      />
      <Row>
        {props.notApplicable ? (
          <Alert variant="info">
            {t('create-review.questionnaire.answerMetrics.skipMetricAlert')}
          </Alert>
        ) : null}
        <Col sm={12} md={8} className={classes.textAlignMiddle}>
          <MetricModal
            greyedOut={props.notApplicable}
            paragraphText={props.metric.metric_i18n_set.description}
            metricDescription={props.metric.metric_i18n_set.description}
            explanationList={
              props.metric.metric_elaboration_set
            }
            gameName={props.gameName}
            marginInEM={1.5}
          />
        </Col>
        <Col sm={12} md={4}>
          <AnswerButtonGroup
            multipleChoice={props.metric.multiple_choice}
            choices={metricContext.answer_sets[props.metric.answer_type].answers}
            saveMetricAnswer={props.saveMetricAnswer}
            metricID={props.metric.id}
            disabled={props.notApplicable}
            metricAnswers={props.metricAnswers}
            prevRevAnswers={props.prevRevAnswers}
            mandatory={props.metric.mandatory}
            mandatoryConditions={props.metric.depending_metric_answer_set.length > 0}
          />
        </Col>
        <Col xs={12}>
          <FreeTextAnswerInput
            freeText={getFreeAnswer()}
            setFreeText={updateFreeText}
          />
        </Col>
      </Row>
      <Button
        variant="secondary"
        className={`${classes.bigMargin} ${globalClasses.buttonMarginRight}`}
        size="lg"
        onClick={() => props.requestChangeMetricTab(false)}
      >
        {t('create-review.questionnaire.answerMetrics.prevBtnLabel')}
      </Button>
      <Button
        className={classes.bigMargin}
        size="lg"
        onClick={() => props.requestChangeMetricTab(true)}
      >
        {t('create-review.questionnaire.answerMetrics.nextBtnLabel')}
      </Button>
    </>
  );
}
