import React from 'react';
import classes from './Address.module.css';

export default function Address() {
  return (
    <>
      <p>
        <span>Man</span>
        <span className={classes.d0}>test</span>
        <span>uel</span>
        <span className={classes.d0}>content</span>
        <span> Baisc</span>
        <span className={classes.d0}>alpha</span>
        <span>h</span>
        <br />
        <span className={classes.d0}>dog</span>
        <span>Fiz</span>
        <span className={classes.d0}>games</span>
        <span>ionst</span>
        <span className={classes.d0}>easy</span>
        <span>ra</span>
        <span className={classes.d0}>nice</span>
        <span>&szlig;e </span>
        <span className={classes.d0}>nice</span>
        <span>4</span>
        <span className={classes.d0}>nice</span>
        <span>1</span>
        <br />
        <span className={classes.d0}>nice</span>
        <span>72</span>
        <span className={classes.d0}>nice</span>
        <span>76</span>
        <span className={classes.d0}>nice</span>
        <span>2</span>
        <span className={classes.d0}>nice</span>
        <span> Reu</span>
        <span className={classes.d0}>nice</span>
        <span>tli</span>
        <span className={classes.d0}>nice</span>
        <span>ng</span>
        <span className={classes.d0}>nice</span>
        <span>en</span>
        <span className={classes.d0}>nice</span>
      </p>
    </>

  );
}
