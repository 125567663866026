import Axios from 'axios';
import i18next from 'i18n/i18n';

export const BASE_URL = `${process.env.REACT_APP_PROTOCOL}://${window.location.host.split(':')[0]}${process.env.REACT_APP_BASE_URL_PORT}`;

class AxiosWrapper {
  static getAxiosInstance(authenticationHeader, language?) {
    let useLanguage;
    if (language) {
      useLanguage = language;
    } else {
      useLanguage = i18next.language;
    }
    const axios = Axios.create({
      baseURL: BASE_URL,
      headers: {
        common: {
          'Accept-Language': useLanguage,
          'X-CSRFToken': getCSRFToken(),
        },
      },
    });
    if (authenticationHeader) {
      axios.defaults.headers.common.Authorization = authenticationHeader;
    }
    return axios;
  }
}

function getCSRFToken() {
  try {
    return document.cookie
      .split('; ')
      .find((row) => row.startsWith('csrftoken='))
      .split('=')[1];
  } catch {
    return 'csrftoken not found';
  }
}

export default AxiosWrapper;
