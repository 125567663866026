// @flow
import React, { useState, useEffect } from 'react';
import type { Node } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import classes from './CustomTabs.module.css';

type Props = {
    tabHeaders: void | Array<string>,
    initialTab: void | string,
    tabContent: void | Array<any>,
}

export default function CustomTabs(props: Props) {
  const [selectedTab, setSelectedTab] = useState<void | string>();

  useEffect(() => {
    setSelectedTab(props.initialTab);
  }, [props.initialTab]);

  function createTabs(locTabHeaders: void | Array<string>): Node | null {
    if (locTabHeaders === undefined) return null;
    return locTabHeaders.map((header, index) => (
      <Tab
        key={header}
        className={classes.tabItem}
        eventKey={header}
        title={header}
      >
        {props.tabContent && props.tabContent[index]}
      </Tab>
    ));
  }

  return (
    <>
      <Tabs
        // variant="pills"
        id="controlled-tab-example"
        activeKey={selectedTab}
        onSelect={(k) => setSelectedTab(k)}
        className={`${'mb-3'} ${classes.scollableNavBar}`}
      >
        {createTabs(props.tabHeaders)}
      </Tabs>
    </>
  );
}
