// @flow
import Button from 'react-bootstrap/Button';
import React, {
  useEffect, useRef, useCallback,
} from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import globalClasses from 'globalCSS/global.module.css';
import type { MetricChoice, Metric } from 'typeAliases/backendAliases';
import type { AnswerMetricImplements } from 'components/AnswerMetrics/AnswerMetrics';
import classes from './AnswerButtonGroup.module.css';

type Props = {
  multipleChoice: $PropertyType<Metric, 'multiple_choice'>,
  saveMetricAnswer: $PropertyType<AnswerMetricImplements, 'saveMetricAnswer'>,
  metricID: $PropertyType<Metric, 'id'>,
  disabled: boolean,
  choices: Array<MetricChoice>,
  metricAnswers: Array<number>,
  prevRevAnswers: Array<number> | void,
};

export default function AnswerButtonGroup(props: Props) {
  const buttonGroupRef = useRef<HTMLInputElement | null>(null);

  function createButtons() {
    return props.choices.map((choice, index) => {
      const variant = 'outline-secondary';
      return (
        <Button
          key={choice.answer_i18n_set.answer_text}
          variant={variant}
          value={index}
          active="true"
          onClick={toggleOptionByEvent}
          disabled={props.disabled}
        >
          {choice.answer_i18n_set.answer_text}
        </Button>
      );
    });
  }

  const getListIndexFromMetricId = useCallback(
    (metricId): number | null => {
      let index = null;
      props.choices.forEach((answerOption, listIndex) => {
        if (answerOption.id === metricId) {
          index = listIndex;
        }
      });
      return index;
    },
    [props.choices],
  );

  const selectButton = useCallback(
    (buttonListIndex: number) => {
      const choice = props.choices[buttonListIndex];
      let variant = 'btn-outline-secondary';
      if (choice.score_fraction === 1 && props.choices.length === 2) {
        variant = 'btn-success';
      } else if (choice.score_fraction === 0 && props.choices.length === 2) {
        variant = 'btn-danger';
      } else if (props.multipleChoice && choice.exclusive_answer && choice.score_fraction > 0) {
        variant = 'btn-success';
      } else if (props.multipleChoice && choice.exclusive_answer && choice.score_fraction === 0) {
        variant = 'btn-danger';
      } else {
        variant = 'btn-primary';
      }
      if (buttonGroupRef.current !== null) {
        buttonGroupRef.current.children[buttonListIndex].setAttribute(
          'class',
          `btn ${variant}`,
        );
      }
    },
    [props.choices],
  );

  useEffect(() => {
    function updateButtonOptics() {
      deselectAllButtons();
      if (props.metricAnswers !== undefined) {
        props.metricAnswers.forEach((answerID: number) => {
          const listIndex = getListIndexFromMetricId(answerID);
          if (listIndex !== null) {
            selectButton(listIndex);
          }
        });
      }
      // if no answer select the answers from prev revision
      if (props.metricAnswers.length === 0
          && props.prevRevAnswers
          && props.prevRevAnswers.length > 0) {
        props.prevRevAnswers.forEach((answerID: number) => {
          const listIndex = getListIndexFromMetricId(answerID);
          if (listIndex !== null) {
            selectButton(listIndex);
          }
        });
      }
    }

    updateButtonOptics();
  }, [props.metricAnswers, props.prevRevAnswers, getListIndexFromMetricId, selectButton]);

  function getMetricIDFromListIndex(listIndex: number) {
    return props.choices[listIndex].id;
  }

  function deselectAllButtons() {
    if (buttonGroupRef.current === null) return;
    Array.from(buttonGroupRef.current.children).forEach((button) => {
      button.setAttribute('class', 'btn btn-outline-secondary');
    });
  }

  function toggleOptionByEvent(e) {
    const btnIndex = e.target.value;
    const metricID = getMetricIDFromListIndex(btnIndex);
    toggleOptionByMetricID(metricID, btnIndex);
  }

  function toggleOptionByMetricID(metricID, btnIndex) {
    const currentAnswers = props.metricAnswers || [];
    const updatedAnswers = updateMetricAnswers(currentAnswers, metricID, btnIndex);
    props.saveMetricAnswer(props.metricID, updatedAnswers);
  }

  function updateMetricAnswers(currentAnswers, metricID, btnIndex) {
    let updatedAnswers = currentAnswers;
    if (currentAnswers.indexOf(metricID) === -1) {
      if (!props.multipleChoice) {
        updatedAnswers = [metricID];
      } else if (props.choices[btnIndex].exclusive_answer) {
        // multiple choice and exlucive answer
        updatedAnswers = [metricID];
      } else {
        // multiple choice
        updatedAnswers = deselectMultipleChoiceAnswers(updatedAnswers);
        updatedAnswers.push(metricID);
      }
    } else {
      updatedAnswers = currentAnswers.filter(
        (answerID) => answerID !== metricID,
      );
    }
    return updatedAnswers;
  }

  function deselectMultipleChoiceAnswers(answers) {
    let updatedAnswers = answers;
    props.choices.forEach((choice) => {
      if (choice.exclusive_answer && answers.indexOf(choice.id) !== -1) {
        updatedAnswers = updatedAnswers.filter((answerID) => answerID !== choice.id);
      }
    });
    return updatedAnswers;
  }

  return (
    <ButtonGroup
      key="1"
      size="lg"
      className={`${globalClasses.floatRight} ${classes.bigMargin}`}
      vertical={props.choices.length > 2}
      ref={buttonGroupRef}
    >
      {createButtons()}
    </ButtonGroup>
  );
}
