import React from 'react';
import {
  FaCheck, FaTimes, FaMinus, FaBan,
} from 'react-icons/fa';
import globalClasses from 'globalCSS/global.module.css';
import { useTranslation } from 'react-i18next';
import Heading4 from 'atoms/heading4/Heading4';
import classes from './IconExplainer.module.css';

export default function IconExplainer() {
  const { t } = useTranslation();
  return (
    <>
      <Heading4 heading={t('iconExplainer.iconExplainerHeading')} />
      <ul className={`${globalClasses.noListStyle} ${classes.listMargin}`}>
        <li className={classes.listItemMargin}>
          <FaCheck className={`${globalClasses.greenIcon} ${classes.iconMargin}`} />
          {t('iconExplainer.allPoints')}
        </li>
        <li className={classes.listItemMargin}>
          <FaMinus className={`${globalClasses.yellowIcon} ${classes.iconMargin}`} />
          {t('iconExplainer.mediumPoints')}
        </li>
        <li className={classes.listItemMargin}>
          <FaTimes className={`${globalClasses.redIcon} ${classes.iconMargin}`} />
          {t('iconExplainer.noPoints')}
        </li>
        <li className={classes.listItemMargin}>
          <FaBan className={`${globalClasses.greyIcon} ${classes.iconMargin}`} />
          {t('iconExplainer.notApplicable')}
        </li>
      </ul>
    </>
  );
}
