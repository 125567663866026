// @flow
import React from 'react';
import type { InternalGame } from 'typeAliases/backendAliases';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { getPlatformsString } from 'helper/platform_names';
import Heading4 from 'atoms/heading4/Heading4';
import ScoreDisplay from 'atoms/scoreDisplay/ScoreDisplay';
import classes from './BigGameCard.module.css';

type Props = {
    internalGame: InternalGame,
    showPlatforms?: boolean,
    fullHeight?: boolean,
    showScore?: boolean,
}

function BigGameCard(props: Props) {
  const { t } = useTranslation();

  return (
    <Card
      className={`text-center ${props.fullHeight ? classes.fullHeight : ''}`}
    >
      <Card.Img
        variant="top"
        className={classes.imageHeight}
        src={props.internalGame.image_set ? props.internalGame.image_set[0].image_url : undefined}
        alt={props.internalGame.image_set ? props.internalGame.image_set[0].game_name : undefined}
      />
      <Card.Body className={classes.cardBody}>
        <Row>
          <Col xs="12">
            <p className={classes.releaseParagraph}>
              {`${t('gameCards.bigGameCards.releaseLabel')}: ${props.internalGame.release_date}`}
            </p>
            <div className={classes.headingContainer}>
              <Heading4
                heading={props.internalGame.name}
              />
            </div>
            {props.showScore
            && (
            <div className={classes.scoreDisplayDiv}>
              <ScoreDisplay
                totalScore={props.internalGame.average_score}
                scale={0.8}
                showTitle={false}
                marginTop="0em"
              />
            </div>
            )}
            {props.showPlatforms
              ? `${t('gameCards.bigGameCards.platformsLabel')}: ${getPlatformsString(props.internalGame.platforms)}`
              : null}
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <small className="text-muted" />
      </Card.Footer>
    </Card>
  );
}

BigGameCard.defaultProps = {
  showPlatforms: true,
  fullHeight: false,
  showScore: false,
};

export default BigGameCard;
