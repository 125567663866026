// @flow
import Heading4 from 'atoms/heading4/Heading4';
import React, {
  useContext, useState, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import type { InternalGame } from 'typeAliases/backendAliases';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import PageButtons from 'atoms/pageButtons/PageButtons';
import type { MetricContextImplements } from 'contexts/MetricContext';
import { MetricContext } from 'contexts/MetricContext';
import {
  FaArrowUp, FaArrowDown,
} from 'react-icons/fa';
import Loader from 'atoms/loader/Loader';
import ClickableBigGameCard from 'atoms/clickableBigGameCard/ClickableBigGameCard';
import FilterTabs from 'components/filterTabs/FilterTabs';
import type { ButtonStates, HandleMetricChoiceBtnClick } from 'typeAliases/frontendAliases';
import ActivefilterButtons from 'atoms/activeFilterButons/ActivefilterButtons';
import type { SearchImplements } from 'routes/search/Search';
import classes from './SearchResultList.module.css';

type Props = {
    loading: boolean,
    results: void | Array<InternalGame>,
    totalNrOfGames: void | number,
    nrOfPages: void | number,
    curPage: number,
    setCurPage(newPage: number): void,
    selectedOrderField: void | string,
    setSelectedOrderField(orderField: string): void,
    orderDecending: void | boolean,
    setOrderDecending(decending: boolean): void,
    appliedFilters: ButtonStates,
    changeFilterValue: HandleMetricChoiceBtnClick,
    removeAllFilters(): void,
    updateGameSearch(): void,
    resetSearchPhrase(): void,
    searchPhrase: string,
    updateExtraFilterState: $PropertyType<SearchImplements, 'updateExtraFilterState'>,
    extraFilterStates: $PropertyType<SearchImplements, 'extraFilterStates'>,
}

const DEFAULT_ORDERING_DECENDING = false;

export default function SearchResultList(props: Props) {
  const { t } = useTranslation();
  const [orderButtonElements, setOrderButtonElements] = useState();
  const metricContext = useContext<$PropertyType<MetricContextImplements, 'businessData'>>(MetricContext);

  useEffect(() => {
    if (metricContext) {
      const orderingFields = metricContext.ordering_fields;
      createOrderingButtons(orderingFields);
    }
  }, [metricContext, props.selectedOrderField, props.orderDecending]);

  function createOrderingButtons(orderingFields: Array<string>) {
    const orderingButtons = orderingFields.map((orderField) => {
      let variant = 'outline-primary';
      let orderIcon = null;
      let buttonLbl = t(`search.orderField.${orderField}`);
      if (/\./.test(buttonLbl)) buttonLbl = orderField;
      let ariaLabel = DEFAULT_ORDERING_DECENDING
        ? `${t('search.orderField.ariaLabel.sort')}${buttonLbl}${t('search.orderField.ariaLabel.acsending')}`
        : `${t('search.orderField.ariaLabel.sort')}${buttonLbl}${t('search.orderField.ariaLabel.descending')}`;
      if (props.selectedOrderField === orderField) {
        variant = 'primary';
        if (props.orderDecending === true) {
          orderIcon = <FaArrowUp />;
          ariaLabel = `${t('search.orderField.ariaLabel.sort')}${buttonLbl}${t('search.orderField.ariaLabel.descending')}`;
        } else if (props.orderDecending === false && props.orderDecending !== undefined) {
          orderIcon = <FaArrowDown />;
          ariaLabel = `${t('search.orderField.ariaLabel.sort')}${buttonLbl}${t('search.orderField.ariaLabel.acsending')}`;
        }
      }
      return (
        <Button
          key={orderField}
          variant={variant}
          className={classes.orderingButton}
          orderfield={orderField}
          onClick={clickHandlerOrderBtn}
          aria-label={ariaLabel}
        >
          {buttonLbl}
          {' '}
          {orderIcon}
        </Button>
      );
    });
    setOrderButtonElements(orderingButtons);
  }

  function clickHandlerOrderBtn(event) {
    const button = event.currentTarget;
    const orderField = button.getAttribute('orderfield');
    if (props.selectedOrderField === orderField) {
      props.setOrderDecending(!props.orderDecending);
      button.setAttribute('aria-label', 'test');
    } else {
      props.setSelectedOrderField(orderField);
      props.setOrderDecending(DEFAULT_ORDERING_DECENDING);
      button.setAttribute('aria-label', 'test2');
    }
  }

  function createGameInfoCards(games: void | Array<InternalGame>) {
    if (!games) return null;
    return games.map((game, index) => (
      <Col key={game.slug}>
        <ClickableBigGameCard game={game} index={index} />
      </Col>
    ));
  }

  function buildNrOfGamesString() {
    if (props.totalNrOfGames === undefined) return null;
    return (
      <p>
        {`${props.totalNrOfGames} ${t('search.nrOfGamesFoundLbl')}`}
      </p>
    );
  }

  return (
    <>
      <div className={classes.headingDiv}>
        <Heading4 heading={t('search.resultsHeding')} />
      </div>

      <Row>
        <Col xs="4" sm="2" md="2" lg="2" xl="1" className={classes.filterButtonCol}>
          {metricContext
          && (
          <FilterTabs
            metricContext={metricContext}
            appliedFilters={props.appliedFilters}
            changeFilterValue={props.changeFilterValue}
            updateGameSearch={props.updateGameSearch}
            updateExtraFilterState={props.updateExtraFilterState}
            extraFilterStates={props.extraFilterStates}
          />
          )}
        </Col>
        <Col xs="8" sm="2" md="2" lg="2" xl="2" className={classes.nrGamesFoudCol}>
          {buildNrOfGamesString()}
        </Col>
        <Col xs="12" sm="8" md="8" lg="8" xl="9" className={classes.orderButtonCol}>
          {orderButtonElements}
        </Col>
      </Row>

      <hr className={classes.aboveResultsLine} />

      <Row className={classes.activeFilterBtnRow}>
        <ActivefilterButtons
          metricContext={metricContext}
          appliedFilters={props.appliedFilters}
          changeFilterValue={props.changeFilterValue}
          removeAllFilters={props.removeAllFilters}
          resetSearchPhrase={props.resetSearchPhrase}
          searchPhrase={props.searchPhrase}
          extraFilterStates={props.extraFilterStates}
          updateExtraFilterState={props.updateExtraFilterState}
        />
      </Row>

      <Row xs={1} md={2} lg={3} xl={3} xxl={4} className={`g-4 ${classes.gameCardsRow}`}>
        {createGameInfoCards(props.results)}
      </Row>

      <hr />

      <PageButtons
        nrOfPages={props.nrOfPages}
        curPage={props.curPage}
        setCurPage={props.setCurPage}
      />

      <Loader loading={props.loading} />
    </>
  );
}
