// @flow
import React, { useRef } from 'react';
import { useLoading, Oval } from '@agney/react-loading';
import classes from './Loader.module.css';

// https://github.com/agneym/react-loading

type Props = {
  loading: boolean
}

export default function Loader(props: Props) {
  const sectionRef = useRef();
  const size = 70;

  const { containerProps, indicatorEl } = useLoading({
    loading: props.loading,
    indicator: <Oval width="70" />,
    loaderProps: {
      xmlns: 'http://www.w3.org/2000/svg',
      version: '1.1',
      style: { position: 'absolute' }, // this makes it able to show in safari
    },
  });

  return (
    <div style={props.loading ? { height: size * 1.5 } : null}>
      <section className={classes.center} aria-busy={containerProps['aria-busy']} aria-live={containerProps['aria-live']} ref={sectionRef}>
        {indicatorEl}
        {' '}
        {/* renders only while loading */}
      </section>
    </div>
  );
}
