// @flow
import React from 'react';
import classes from './HeroHeading.module.css';

type Props = {
  heading: string,
  marginBottom?: string,
  mainHeading?: boolean,
};

function HeroHeading(props: Props) {
  return (
    <>
      {props.mainHeading
        ? (
          <h1 className={classes.h1} style={{ marginBottom: props.marginBottom }}>
            {props.heading}
          </h1>
        )
        : (
          <h2 className={classes.h1} style={{ marginBottom: props.marginBottom }}>
            {props.heading}
          </h2>
        )}
    </>
  );
}

HeroHeading.defaultProps = {
  marginBottom: '0.6em',
  mainHeading: false,
};

export default HeroHeading;
