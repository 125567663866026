// @flow
import React, { useEffect, useState, useCallback } from 'react';
import type {
  Platform, Genre,
} from 'typeAliases/backendAliases';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import type { SearchImplements } from 'routes/search/Search';
import ExtraFilterButton from 'atoms/answerButton/ExtraFilterButton';
import type { Node } from 'react';
import RangeSlider from 'atoms/rangeSlider/RangeSlider';
import { useTranslation } from 'react-i18next';
import classes from './Filter.module.css';

type Props = {
    filterName: string,
    possibleAnswers: Array<number> | Array<Platform> | Array<Genre>,
    isDate: boolean,
    extraFilterStates: $PropertyType<SearchImplements, 'extraFilterStates'>,
    updateExtraFilterStates: $PropertyType<SearchImplements, 'updateExtraFilterState'>,
}

const MAX_NAME = '_lte';
const MIN_NAME = '_gte';

export default function ExtraFilter(props: Props) {
  const { t } = useTranslation();
  const [filterElements, setFilterElements] = useState<Array<Node>>([]);

  const getSlider = useCallback(
    (possibleAnswers: Array<number>) => {
      let minStartValue = props.extraFilterStates[props.filterName + MIN_NAME];
      let maxStartValue = props.extraFilterStates[props.filterName + MAX_NAME];
      if (typeof minStartValue === 'object') [minStartValue] = minStartValue.values();
      if (typeof maxStartValue === 'object') [maxStartValue] = maxStartValue.values();

      if (maxStartValue && props.isDate) {
        [maxStartValue] = props.extraFilterStates[props.filterName + MAX_NAME].split('-');
      }
      if (minStartValue && props.isDate) {
        [minStartValue] = props.extraFilterStates[props.filterName + MIN_NAME].split('-');
      }
      return [
        <RangeSlider
          key={props.filterName}
          min={possibleAnswers[0]}
          max={possibleAnswers[1]}
          minValue={minStartValue}
          maxValue={maxStartValue}
          name={props.filterName}
          minName={props.filterName + MIN_NAME}
          maxName={props.filterName + MAX_NAME}
          extraFilterStates={props.extraFilterStates}
          updateExtraFilterStates={props.updateExtraFilterStates}
          isDate={props.isDate}
        />,
      ];
    },
    [props.extraFilterStates],
  );

  const getAnswerButtons = useCallback(
    (possibleAnswers: Array<Platform> | Array<Genre>) => possibleAnswers
      .map((answer: Platform | Genre) => (
        <ExtraFilterButton
          key={answer.id}
          extraFilterStates={props.extraFilterStates}
          updateExtraFilterStates={props.updateExtraFilterStates}
          answerName={answer.name}
          filterName={props.filterName}
          answerID={answer.id}
        />
      )),
    [props.extraFilterStates],
  );

  useEffect(() => {
    const isSliderFilter = props.possibleAnswers && !(typeof props.possibleAnswers[0] === 'object');
    let newFilterElements = [];
    if (isSliderFilter) {
      // $FlowIgnore
      newFilterElements = getSlider(props.possibleAnswers);
    } else {
      // $FlowIgnore
      newFilterElements = getAnswerButtons(props.possibleAnswers);
    }
    setFilterElements(newFilterElements);
  }, [props.extraFilterStates]);

  return (
    <Col xs="10" className={classes.column}>
      <div>
        <Form.Label className={classes.label}>
          {t(`search.filterNames.${props.filterName}`)}
        </Form.Label>
      </div>
      {filterElements}
    </Col>
  );
}
