import React from 'react';
import classes from './Email.module.css';

export default function Email() {
  return (
    <>
      <span>co</span>
      <span className={classes.d0}>test</span>
      <span>ntact@</span>
      <span className={classes.d0}>content</span>
      <span>uncond</span>
      <span className={classes.d0}>alpha</span>
      <span>itio</span>
      <span className={classes.d0}>dog</span>
      <span>nal-</span>
      <span className={classes.d0}>games</span>
      <span>gam</span>
      <span className={classes.d0}>easy</span>
      <span>ing.com</span>
      <span className={classes.d0}>nice</span>
    </>
  );
}
